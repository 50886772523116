import React, { useState, useEffect } from "react";
import { BidirectionalBar } from "@ant-design/plots";
import axios from "axios";
import { GET_ACTIVE_CLIENTS_LIST } from "../../helpers/adminApiStrings";
const moment = require("moment");

function getFormattedStatValue(value) {
  let val = null;

  if (value < 1000) {
    val = value
  } else if (value >= 1000 && value < 1000000) {
    val = Math.round(value / 1000).toLocaleString() + "K";
  } else if (value >= 1000000) {
    val = Math.round(value / 1000000).toLocaleString() + "M";

  }
  return val;
}

const BidirectionalChartComponent = (props) => {
  let { data, xField, yField1, yField2, series } = props;
  const config = {
    data,
    xField: xField,
    xAxis: {
      position: "bottom",
    },
    interactions: [
      {
        type: "active-region",
      },
    ],
    label: {
      formatter: (data) => {
        if (data["series-field-key"] == "withdrawals")
          data["withdrawals"] =
           getFormattedStatValue(data["withdrawals"]);
        if (data["series-field-key"] == "deposits")
          data["deposits"] =
            getFormattedStatValue(data["deposits"])

        return [data.deposits, data.withdrawals];
      },
      position: ["top", "bottom"],
      style: { opacity: 1, fill: "black" },
    },
    yField: [yField1, yField2],
    tooltip: {
      shared: true,
      showMarkers: false,
    },
    color: ["black", "gold"],
    yAxis: {
      label: {
        formatter: (label) => {
          return (label = moment(label).format("MMMM-YYYY"));
        },
      },
    },
    xAxis: {
      label: {
        formatter: (label) => {
          return (label = moment(label).format("MMMM-YYYY"));
        },
      },
    },
  };

  return <BidirectionalBar {...config} />;
};

export default BidirectionalChartComponent;
