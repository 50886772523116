import React, { useState, useRef, useEffect, useContext } from "react";
import { Space, Tooltip } from "antd";

import shortid from "shortid";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export default function DigitsColumn(props) {
  const { digits, symbol, sr_no, setErrors } = props;
  const [isError, setIsError] = useState(false);
  //const [elementId, setElementId] = useState(shortid.generate());
  const [elementId, setElementId] = useState(digits + "" + sr_no + "_digits");

  useEffect(() => {
    try {
      if (!typeof parseInt(digits) || parseInt(digits) === 0) {
        //    setErrors(old => [...old, elementId]);
        setIsError(true);
      } else if (!digits) {
        //    setErrors(old => [...old, elementId]);
        setIsError(true);
      }
    } catch (e) {
      // setErrors(old => [...old, elementId]);
      setIsError(true);
    }
  }, []);

  return (
    <>
      {isError && (
        <Tooltip title={"Digits can't be empty or 0"}>
          <div id={elementId} style={{ paddingLeft: 4 }}>
            <Space>
              <span style={{ color: "red" }}>{digits}</span>
              <ReportProblemIcon fontSize="small" style={{ color: "gold" }} />
            </Space>
          </div>
        </Tooltip>
      )}
      {!isError && (
        <div id={elementId} style={{ paddingLeft: 4 }}>
          <span style={{ color: "black" }}>{digits}</span>
        </div>
      )}
    </>
  );
}
