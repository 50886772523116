import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, CircularProgress, Grid, IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import moment from "moment";
// import Item from 'antd/es/list/Item';
import axios from "axios";
import {
  BASE_URL,
  GET_EXECUTION_HISTORY_LOGS,
  MANUAL_SCRIPT_EXECUTOR,
} from "../../../helpers/adminApiStrings";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "black",
  display: "flex",
}));
const TransactionExecutionLogs = function (props) {
  const { platformType, setTxnLogLoaded } = props;
  const [executionHistoryLog, setExecutionHistoryLog] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isReRunBtnDisplayed, setReRunBtnDisplayed] = useState(true);
  const readLogsfile = () =>
    axios
      .post(BASE_URL + "/" + platformType + GET_EXECUTION_HISTORY_LOGS, {
        platformType: platformType,
      })
      .then((res) => {
        let myObj = SortDateArray(res.data);
        setExecutionHistoryLog(myObj);
        setTxnLogLoaded(false)
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  useEffect(() => {
    setIsLoading(true);
    setTxnLogLoaded(true);
    readLogsfile();
  }, [platformType]);

  function SortDateArray(actualData) {
    let unSortedArrayWithAt = Object.keys(actualData);
    let myObj = {};
    let myArray = unSortedArrayWithAt.sort(function (a, b) {
      let x = a;
      let y = b;
      if (a.includes("@")) {
        x = a.split("@")[0]
      }
      if (b.includes("@")) {
        y = b.split("@")[0]
      }
      var one = x.split('-');
      var two = y.split('-');
      return new Date(+one[2], one[1] - 1, +one[0]) - new Date(+two[2], two[1] - 1, +two[0]);
    });
    for (let i = 0; i < myArray.length; ++i) {
      myObj[myArray[i]] = actualData[myArray[i]]
    }
    return myObj;
  }
  const errorsParagraphStyle = {
    margin: "0px",
    fontWeight: "normal",
  };
  const pythonScriptRunner = (excecutionDate) => {
    setReRunBtnDisplayed(false);
    axios
      .post(
        BASE_URL +
        "/" +
        platformType +
        MANUAL_SCRIPT_EXECUTOR +
        "/" +
        excecutionDate,
        { platformType: platformType }
      )
      .then((res) => {
        if (res.data["scriptStatus"][0] == "Failed") {
          setIsLoading(true);
          readLogsfile();
          setIsLoading(false);
          alert("Script Executed with Failed Status");
        } else {
          window.location.reload();
        }
        setReRunBtnDisplayed(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      {isLoading && (
        <Box sx={{ mt: 2, height: "78vh", width: "50%" }}>
          <Item
            style={{
              backgroundColor: "white",
              fontWeight: "bold",
              display: "flex",
              flexFlow: "column",
              height: "78vh",
              padding: "1% 2%",
              width: "95.5%",
            }}
          >
            <h2>
              <CircularProgress disableShrink size={50} />
              <br></br>Loading...
            </h2>
          </Item>
        </Box>
      )}

      {!isLoading && Object.keys(executionHistoryLog).length != 0 && (
        <Box
          sx={{
            flexGrow: 1,
            mt: 2,
            height: "78vh",
            display: "flex",
            width: "50%",
          }}
        >
          <Item
            style={{
              backgroundColor: "white",
              fontWeight: "bold",
              display: "flex",
              flexFlow: "column",
              height: "78vh",
              padding: "1% 2%",
              width: "95.5%",
              marginLeft: "1%",
            }}
          >
            <h4
              style={{
                margin: "auto",
                padding: "20px",
                display: "block",
                fontSize: "20px",
                width: "100%",
              }}
            >
              Execution Logs History -{" "}
              {platformType == "TU" ? "Tradeultra" : "Century"}
            </h4>
            <Divider
              sx={{ width: "70%", margin: "auto", backgroundColor: "#ffd700" }}
            />
            <Box
              sx={{
                width: "100%",
                height: "65vh",
                overflowY: "auto",
                alignItems: "center",
                justifyContent: "center",
                "&::-webkit-scrollbar": {
                  width: "0.7em",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#ffffff",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "black",
                  borderRadius: 2,
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#ffd700",
                },
                padding: "10px",
              }}
            >
              {Object.keys(executionHistoryLog).reverse().map((key, index) => {
                let statusColor =
                  executionHistoryLog[key]["status"] == "Success"
                    ? "green"
                    : "red";
                return (
                  <div key={index}>
                    <Accordion sx={{ width: "100%", mb: 1 }}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            align="left"
                            style={{
                              margin: "0px",
                              fontWeight: "normal",
                              border: "1px solid #fdd700",
                              padding: "10px",
                            }}
                          >
                            <b>Execution Date:</b>{" "}
                            {key.includes("@") ? key.split("@")[0] : key}
                            <span>
                              {!key.includes("@") &&
                                executionHistoryLog[key]["successExists"] ==
                                "false" && (
                                  <>
                                    {isReRunBtnDisplayed == true ? (
                                      <Button
                                        color="inherit"
                                        onClick={() => pythonScriptRunner(key)}
                                        variant="contained"
                                        sx={{
                                          ml: 2,
                                          backgroundColor: "#ffd700",
                                          color: "black",
                                          width: "150px",
                                        }}
                                      >
                                        Re-run Script
                                      </Button>
                                    ) : (
                                      <CircularProgress
                                        disableShrink
                                        size={20}
                                        sx={{ margin: "5px 0px 0px 10px" }}
                                      />
                                    )}
                                  </>
                                )}
                            </span>
                          </p>
                          <p
                            align="left"
                            style={{
                              margin: "0px",
                              fontWeight: "normal",
                              padding: "10px",
                              width: "18%",
                            }}
                          >
                            <b>Status: </b>{" "}
                            <span style={{ color: statusColor }}>
                              {executionHistoryLog[key]["status"]}
                            </span>
                          </p>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p align="left" style={errorsParagraphStyle}>
                          <b>File Error: </b>{" "}
                          {executionHistoryLog[key]["errors"]["fileError"] ==
                            "1"
                            ? "Profile Master File is Missing"
                            : executionHistoryLog[key]["errors"]["fileError"] ==
                              "2"
                              ? "Commission Group Master File is Missing"
                              : executionHistoryLog[key]["errors"]["fileError"] ==
                                "3"
                                ? "Profile Master & Commission Group Master Files are Missing"
                                : "N/A"}
                        </p>
                        <br />
                        <p align="left" style={errorsParagraphStyle}>
                          <b>Profile master symbols missing in Meta: </b>
                          {executionHistoryLog[key]["errors"]["symbolErrorMeta"]
                            .length != 0
                            ? executionHistoryLog[key]["errors"][
                              "symbolErrorMeta"
                            ]?.map(function (metaSymbolError, index) {
                              return (
                                <span>
                                  {metaSymbolError}
                                  {index <
                                    executionHistoryLog[key]["errors"][
                                      "symbolErrorMeta"
                                    ].length -
                                    1
                                    ? ","
                                    : ""}{" "}
                                </span>
                              );
                            })
                            : "N/A"}
                        </p>
                        <br />
                        <p align="left" style={errorsParagraphStyle}>
                          <b>Symbols missing in profile master: </b>
                          {executionHistoryLog[key]["errors"][
                            "symbolErrorProfile"
                          ].length != 0
                            ? executionHistoryLog[key]["errors"][
                              "symbolErrorProfile"
                            ]?.map(function (profileSymbolError, index) {
                              return (
                                <span>
                                  {profileSymbolError}
                                  {index <
                                    executionHistoryLog[key]["errors"][
                                      "symbolErrorProfile"
                                    ].length -
                                    1
                                    ? ","
                                    : ""}{" "}
                                </span>
                              );
                            })
                            : "N/A"}
                        </p>
                        <br />
                        <p align="left" style={errorsParagraphStyle}>
                          <b>Symbol Configuration missing on meta: </b>
                          {executionHistoryLog[key]["errors"][
                            "symbolConfigErrorMeta"
                          ].length != 0
                            ? executionHistoryLog[key]["errors"][
                              "symbolConfigErrorMeta"
                            ]?.map(function (metaSymbolConfigError, index) {
                              return (
                                <span>
                                  {metaSymbolConfigError}
                                  {index <
                                    executionHistoryLog[key]["errors"][
                                      "symbolConfigErrorMeta"
                                    ].length -
                                    1
                                    ? ","
                                    : ""}{" "}
                                </span>
                              );
                            })
                            : "N/A"}
                        </p>
                        <br />
                        <p align="left" style={errorsParagraphStyle}>
                          <b>Fee Group missing in Commission Group Master: </b>
                          {executionHistoryLog[key]["errors"]["feeGroupError"]
                            .length != 0
                            ? executionHistoryLog[key]["errors"][
                              "feeGroupError"
                            ]?.map(function (feeGroupError, index) {
                              return (
                                <span>
                                  {feeGroupError}
                                  {index <
                                    executionHistoryLog[key]["errors"][
                                      "feeGroupError"
                                    ].length -
                                    1
                                    ? ","
                                    : ""}{" "}
                                </span>
                              );
                            })
                            : "N/A"}
                        </p>

                        <br />
                        <p align="left" style={errorsParagraphStyle}>
                          <b>Execution Time: </b>{" "}
                          {executionHistoryLog[key]["executionTime"]} minutes
                        </p>
                      </AccordionDetails>
                    </Accordion>
                    <h1 style={{ height: "5px", margin: 0 }}></h1>
                  </div>
                );
              })}
            </Box>
          </Item>
        </Box>
      )}
    </>
  );
};
export default TransactionExecutionLogs;
