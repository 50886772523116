export const BASE_URL = "https://dashboard.century.ae/api";

// export const BASE_URL = "http://10.10.40.39:5000";
// export const BASE_URL = "http://localhost:5000";

//--- Start of Profile Master APIs-----------------------------

export const UPLOAD_PROFILE_MASTER = BASE_URL + "/admin/uploadProfileMaster";

export const GET_UPLOADED_PROFILE_MASTERS =
  BASE_URL + "/admin/getUploadedProfileMasters";

export const FETCH_PROFILE_MASTER = BASE_URL + "/admin/fetchProfileMaster";

export const REMOVE_PROFILE_MASTER = BASE_URL + "/admin/removeProfileMaster";

//--- End of Profile Master APIs-----------------------------

//--- Start of Commission Master APIs-----------------------------

export const GET_TRANSACTION_REPORT = "/admin/getTransactionExecutionHistory";

export const UPLOAD_COMMISSION_MASTER =
  BASE_URL + "/admin/uploadCommissionMaster";

export const GET_UPLOADED_COMMISSION_MASTERS =
  BASE_URL + "/admin/getUploadedCommissionMasters";

export const FETCH_COMMISSION_MASTER =
  BASE_URL + "/admin/fetchCommissionMaster";

export const REMOVE_COMMISSION_MASTER =
  BASE_URL + "/admin/removeCommissionMaster";

//--- End of Commission Master APIs-----------------------------

//--------Execution History Logs---------------------

export const GET_EXECUTION_HISTORY_LOGS = "/admin/getExecutionHistoryLogs";
export const MANUAL_SCRIPT_EXECUTOR = "/admin/manualScriptExecutor";

export const GET_USER_TRANSACTIONS = "/users/getAllTransactions";
export const GET_MGMT_TRANSACTIONS = "/admin/getMgmtAllTransactions";

export const GET_ACTIVE_CLIENTS_LIST = "/users/getActiveClientsList";
export const GET_ACTIVE_CLIENTS_LIST_MGMT = "/admin/getMgmtActiveClientsList";

export const GET_MGMT_USER_CREDIT_MASTER =
  "/admin/getManagementUserCreditMaster";
export const GET_MGMT_USER_DETAILS =
  BASE_URL + "/admin/getManagementUserDetails";
export const GET_MGMT_SCATTER_PLOT = "/admin/getScatterPlotData";

export const GET_MONTH_WISE_TOP_CLIENTS_AND_SYMBOLS =
  "/admin/getMonthWiseTopClientsAndSymbols";

//------------------------------------------------------------------Nerve APIs--------------------------------------
export const GET_NERVE_TOKEN = "/admin/getNerveToken";

// export const NERVE_BASE_URL = "https://app.tradeultra.com:10100/Analytics";

// export const CENTURY_MANAGEMENT_BASE_URL =
//   "https://app.century.ae:10100/Analytics";

// export const GET_LOGIN_USERS = NERVE_BASE_URL + "/UserLogins?";
// export const GET_CURRENT_LOGIN_USERS = NERVE_BASE_URL + "/ActiveUsers?";
// export const GET_TRADE_DETAILS = NERVE_BASE_URL + "/TradeDetails?";
// export const GET_CURRENT_TRADE_DETAILS = NERVE_BASE_URL + "/TradingActivity?";
// export const GET_SEARCHED_INSTRUMENTS = NERVE_BASE_URL + "/UserBehaviour?";
export const GET_DISTINCT_CHART_DATA = "/admin/getDistinctChartData";

// export const GET_LOGIN_USERS = NERVE_BASE_URL + "/UserLogins?";

export const GET_LIVE_APP_METRICS = "/admin/getLiveAppMetrics";
export const GET_RM_APP_METRICS = "/admin/getRMAppMetrics";
export const GET_SPECIFIC_METRICS = "/admin/getSpecificMetrics";
export const GET_RM_SPECIFIC_METRICS = "/admin/getRMSpecificMetrics";
export const GET_ALL_TRANSACTION_STATISTICS =
  "/admin/getAllTransactionStatistics";
export const GET_ALL_TRANSACTION_STAT_MASTER =
  "/admin/getAllTransactionStatMaster";
export const GET_MIS_DATA = "/admin/getMISData";
export const GET_HOLDING_COST_TOP_CR = "/admin/getHoldingCostTopCR";

export const GET_ENCRYPTED_EMAIL = "/admin/getEncryptedEmail";
