import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Scatter } from "@ant-design/plots";

const ScatterPlotComponent = (props) => {
  let { data, xField, yField, colorField, sizeField } = props;
  // console.log(xField, yField, colorField, sizeField);
  var arr = Object.keys(data).map(function (key) {
    return data[key].totalDeposit;
  });
  var depmax = Math.max.apply(null, arr);
  var arr = Object.keys(data).map(function (key) {
    return data[key].countOfTransaction;
  });
  var trademax = Math.max.apply(null, arr);
  //  {
  //    "Client": 1,
  //    "Life": "Red",
  //    "Count of Trades": 10,
  //    "Amount Deposited": 5000,
  // "Revenue":10000
  //  }

  const config = {
    appendPadding: 30,
    data,
    xField: xField,
    yField: yField,
    colorField: colorField,
    sizeField: sizeField,
    size: [5, 50],
    shape: "circle",
    annotations: [
      {
        type: "line",
        start: [0, trademax / 2],
        end: ["max", trademax / 2],
        style: {
          stroke: "white",
        },
      },
      {
        type: "line",
        start: [depmax / 2, 0],
        end: [depmax / 2, "max"],
        style: {
          stroke: "white",
        },
      },
    ],
    quadrant: {
      xBaseline: depmax / 2,
      yBaseline: trademax / 2,
      labels: [
        {
          content: "High Deposit High Trades",
        },
        {
          content: "Low Deposit High Trades",
        },
        {
          content: "Low Deposit Low Trades",
        },
        {
          content: "High Deposit Low Trades",
        },
      ],
    },
    yAxis: {
      nice: true,
      title: {
        style: {
          fill: "white",
        },
        text: "Transaction Count",
      },
      label: {
        style: {
          fill: "white",
        },
      },
      grid: { line: { style: 0 } },
    },
    xAxis: {
      title: {
        style: {
          fill: "white",
        },
        text: "Total Deposits",
      },
      grid: { line: { style: 0 } },
      label: {
        style: {
          fill: "white",
        },
        formatter: (label) => {
          return (label = ((label / 100) * 100).toLocaleString());
        },
      },
      line: {
        style: {
          stroke: "#eee",
        },
      },
    },
    theme: "dark", // 'dark',
    color: ["gray", "gold", "#c4a484", "$ff8b3d"],
    tooltip: {
      customContent: (title, data) => {
        return `<div style="padding:10px">
        <table>
        <tr>
        <th align="left">Client ID</th>
        <td align="left">${data[0]?.data.clientId}</td>
        <tr>
        <tr>
        <th align="left">Life</th>
        <td align="left">${data[0]?.data.lifeString}</td>
        <tr>
        <tr>
        <th align="left">Total Deposits</th>
        <td align="left">USD ${data[0]?.data.totalDeposit.toLocaleString()}</td>
        <tr>
        <tr>
        <th align="left">Count of Transactions</th>
        <td align="left">${data[0]?.data.countOfTransaction.toLocaleString()}</td>
        <tr>
        <tr>
        <th align="left">Revenue Generated</th>
        <td align="left">USD ${data[0]?.data.revenue.toLocaleString()}</td>
        <tr>
        </table>
        
        </div>`;
      },
    },
  };

  return <Scatter {...config} />;
};

export default ScatterPlotComponent;
