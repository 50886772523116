import React, { useState, useRef, useEffect } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import LiveAppMetricsComponent from "../../components/app-metrics-component";
import {
  AUTHENTICATE_CLIENT_OPERATIONS,
  GET_PUBLIC_KEY,
  CHECK_ALLOWED_USERS,
} from "../../helpers/apiStrings";
import { useNavigate } from "react-router-dom";
import { Result } from "antd";
import { config } from "../../helpers/config";

import { JSEncrypt } from "jsencrypt";

import { Buffer } from "buffer";
import { PublicClientApplication } from "@azure/msal-browser";
const encrypt = new JSEncrypt();

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUri,
    authority: config.authority,
    postLogoutRedirectUri: config.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
});

export default function CenturyManagementHome(props) {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");

  function getPublicKey() {
    setIsLoading(true);
    axios
      .get(GET_PUBLIC_KEY)
      .then((res) => {
        setIsLoading(false);
        const d = Buffer.from(res.data, "base64").toString("ascii");
        encrypt.setPublicKey(d);

        let accounts = publicClientApplication.getAllAccounts();

        if (accounts.length == 0) {
          console.log("Not Signed in");
          navigate("/centuryManagement");
        } else {
          checkAuthorization(accounts[0].username, accounts[0].name);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e.message);
      });
  }

  function checkAuthorization(userEmail, name) {
    //CAU = century admin users
    setIsLoading(true);
    const enc = encrypt.encrypt(JSON.stringify({ userType: "CAU", userEmail }));
    axios
      .post(CHECK_ALLOWED_USERS, { payload: enc })
      .then((res) => {
        setName(name);
        setAuthenticated(true);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setName(name);

        setAuthenticated(false);
      });
  }

  useEffect(() => {
    document.title = "Century Management | Dashboard";

    getPublicKey();
  }, []);

  function logout() {
    try {
      localStorage.clear();
      sessionStorage.clear();
      (function () {
        var cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
          var d = window.location.hostname.split(".");
          while (d.length > 0) {
            var cookieBase =
              encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
              "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
              d.join(".") +
              " ;path=";
            var p = window.location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
              document.cookie = cookieBase + p.join("/");
              p.pop();
            }
            d.shift();
          }
        }
      })();
      window.location.reload();
    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        width: "100%",
        height: "100vh",

        backgroundColor: "white",
      }}
    >
      <div>
        <Button
          onClick={logout}
          style={{
            backgroundColor: "gold",
            color: "black",
            float: "right",
            marginRight: "50px",
            marginTop: "20px",
          }}
          variant="contained"
        >
          Logout
        </Button>
        <h3
          style={{
            float: "right",
            marginRight: "20px",
            marginTop: "30px",
          }}
        >
          {name}
        </h3>
      </div>
      <div>
        {isLoading && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              boxSizing: "border-box",
              padding: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress disableShrink />
          </Box>
        )}
        {!isLoading &&
          authenticated(
            <LiveAppMetricsComponent
              server={"century"}
              isCancellable={false}
              metricsHeading={"Century Management Dashboard"}
            />
          )}

        {!isLoading && !authenticated && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              boxSizing: "border-box",
              padding: 5,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Result
              status="403"
              title="401"
              subTitle="Sorry, you are not authorized to access this page."
            />
          </Box>
        )}
      </div>
    </Box>
  );
}
