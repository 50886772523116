import * as React from "react";
import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import { ACCOUNT_LOGIN_API } from "../../helpers/apiStrings";

const defaultTheme = createTheme();

export default function AccountsLogin() {
  // let myEmail = "mumshadjawad3333@gmail.com";
  // let myPass = "Password";
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  const SubmitCreds = (event) => {
    event.preventDefault();
    axios
      .post(ACCOUNT_LOGIN_API, {
        email: email,
        password: password,
      })
      .then((res) => {
        if (res.data.status == "Success") {
          localStorage.setItem("bearerToken", res.data.token); //we cant get token is in one case backend error so catch will executive not then block
          navigate("/account/home");
        } else {
          setLoginErrorMessage(res.data.message);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };
  React.useEffect(() => {
    if (localStorage.getItem("bearerToken")) {
      navigate("/account/home");
    }
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            padding: "4%",
            borderRadius: "5px",
            boxShadow: "0 1px 2px 0 gray",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#ffd700" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Accounts Login
          </Typography>
          <Box
            component="form"
            onSubmit={SubmitCreds}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {loginErrorMessage != "" && (
              <div
                style={{
                  border: "solid 1px red",
                  borderRadius: "5px",
                  width: "100%",
                  color: "red",
                }}
              >
                <h4>{loginErrorMessage}</h4>
              </div>
            )}
            <Button
              type="submit"
              fullWidth
              // variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: "#fdd700",
                color: "black",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                  boxShadow: "none",
                },
              }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
