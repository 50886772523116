import React, { useState, useRef, useEffect, useContext } from "react";
import { Space, Tooltip } from "antd";

import shortid from "shortid";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export default function CommissionColumn(props) {
  const { commision, sr_no, setErrors } = props;
  const [isError, setIsError] = useState(false);
  const [errorTitle, setErrorTitle] = useState();
  const [elementId, setElementId] = useState(
    commision + "" + sr_no + "_commission"
  );

  useEffect(() => {
    try {
      if (typeof commision == "object") {
        setIsError(true);
        setErrorTitle("Commission can't be empty");
      } else if (commision.length === 0) {
        setIsError(true);
        setErrorTitle("Commission can't be empty");
      } else if (!commision.includes("C")) {
        setIsError(true);
        setErrorTitle("Wrong commission value format");
      }
    } catch (e) {
      setIsError(true);
      setErrorTitle("asdfasdf");
    }
  }, []);

  return (
    <>
      {isError && (
        <Tooltip title={errorTitle}>
          <div id={elementId} style={{ paddingLeft: 4 }}>
            <Space>
              <span style={{ color: "red" }}>{commision}</span>
              <ReportProblemIcon fontSize="small" style={{ color: "gold" }} />
            </Space>
          </div>
        </Tooltip>
      )}
      {!isError && (
        <div id={elementId} style={{ paddingLeft: 4 }}>
          <span style={{ color: "black" }}>{commision}</span>
        </div>
      )}
    </>
  );
}
