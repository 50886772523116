import React, { useState, useRef, useEffect, useContext } from "react";
import { Space, Tooltip } from "antd";

import shortid from "shortid";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export default function SwapColumn(props) {
  const { swap, sr_no, setErrors } = props;
  const [isError, setIsError] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  //const [elementId, setElementId] = useState(shortid.generate());
  const [elementId, setElementId] = useState(swap + "" + sr_no + "_swap");

  useEffect(() => {
    try {
      if (typeof swap == "object") {
        setIsError(true);
        setErrorTitle("Swap can't be empty");
      } else if (swap.length === 0) {
        setIsError(true);
        setErrorTitle("Swap can't be empty");
      } else if (!swap.includes("S")) {
        setIsError(true);
        setErrorTitle("Wrong swap value format");
      }
    } catch (e) {
      setIsError(true);
    }
  }, []);

  return (
    <>
      {isError && (
        <Tooltip title={errorTitle}>
          <div id={elementId} style={{ paddingLeft: 4 }}>
            <Space>
              <span style={{ color: "red" }}>{swap}</span>
              <ReportProblemIcon fontSize="small" style={{ color: "gold" }} />
            </Space>
          </div>
        </Tooltip>
      )}
      {!isError && (
        <div id={elementId} style={{ paddingLeft: 4 }}>
          <span style={{ color: "black" }}>{swap}</span>
        </div>
      )}
    </>
  );
}
