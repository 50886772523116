import { config } from "../helpers/config";
import { PublicClientApplication } from '@azure/msal-browser';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Buffer } from 'buffer';


export default function Login(props) {
    const [error, setError] = useState(null)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [user, setUser] = useState({})
    const navigate = useNavigate();
    const publicClientApplication = new PublicClientApplication({
        auth: {
            clientId: config.appId,
            redirectUri: config.redirectUri,
            authority: config.authority,
            postLogoutRedirectUri: config.postLogoutRedirectUri
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: true
        }
    })
  

    const login = async () => {

     
        try {
            const res = await publicClientApplication.loginPopup({redirectUri:config.redirectUri, scopes: config.scopes, prompt: "select_account" });

            setIsAuthenticated(true);
            setUser(res.account)
            //localStorage.setItem("token",JSON.stringify({data:res.account,exp : new Date().getTime() + 3600000}))

            let temp = [];
            for(let x in sessionStorage){

                if(!["length","clear","getItem","key","removeItem","setItem"].includes(x))
                temp.push({key:x,value:sessionStorage.getItem(x)});
            }

            
            window.location.href="http://localhost:3001?payload="+Buffer.from(JSON.stringify(temp)).toString('base64')
        }
        catch (err) {
            console.log("Error from Popup",err);

            setError(err);
            setIsAuthenticated(false);
            setUser({});
        }
    }
    const logout = async () => {
        const currentAccount = publicClientApplication.getAccountByHomeId(user.homeAccountId);
        await publicClientApplication.logoutRedirect({ account: currentAccount, postLogoutRedirectUri: config.postLogoutRedirectUri });
    }
    return (
        <div style={{height:"100vh",width:"100vw"}}>
            <div style={{backgroundColor:"white", borderRadius:"5px",boxShadow:"0 1px 2px gray",width:"20vw", height:"20vh",marginTop:"35vh",marginLeft:"35vw",padding:"2vw"}}>
            <h1>Trading Dashboard</h1>
            
            {!isAuthenticated && (<Button variant="contained" onClick={() => { login() }}>Login</Button>)}
            {error && (<p>ERROR! Contact ahish.a@century.ae.</p>)}
            {/* {isAuthenticated && (<button onClick={() => { logout() }}>Logout</button>)} */}
            </div>
        </div>
    )

}