import React, { useState, useRef, useEffect, useContext } from "react";
import { Space, Tooltip } from "antd";

import shortid from "shortid";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export default function LpColumn(props) {
  const { lp, symbol, sr_no, setErrors } = props;
  const [isError, setIsError] = useState(false);
  //const [elementId, setElementId] = useState(shortid.generate());
  const [elementId, setElementId] = useState(lp + "" + sr_no + "_lp");

  useEffect(() => {
    try {
      if (lp.trim().length === 0) {
        //    setErrors(old => [...old, elementId]);
        setIsError(true);
      }
    } catch (e) {
      // setErrors(old => [...old, elementId]);
      setIsError(true);
    }
  }, []);

  return (
    <>
      {isError && (
        <Tooltip title={"LP can't be empty"}>
          <div id={elementId} style={{ paddingLeft: 4 }}>
            <Space>
              <span style={{ color: "red" }}>{lp}</span>
              <ReportProblemIcon fontSize="small" style={{ color: "gold" }} />
            </Space>
          </div>
        </Tooltip>
      )}
      {!isError && (
        <div id={elementId} style={{ paddingLeft: 4 }}>
          <span style={{ color: "black" }}>{lp}</span>
        </div>
      )}
    </>
  );
}
