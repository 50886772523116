import {
  TextField,
  Button,
  Grid,
  Box,
  Paper,
  FormControl,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { tableCellClasses } from "@mui/material/TableCell";
import { addDays, set } from "date-fns";
import { GET_NERVE_TOKEN, GET_LIVE_APP_METRICS, GET_SPECIFIC_METRICS, GET_MGMT_USER_DETAILS, BASE_URL } from "../helpers/adminApiStrings";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import BubbleChartComponent from "./charts/bubbleChart";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { styled } from "@mui/material/styles";
import { Divider } from "antd";


function GoldButton(props) {

  return <Button
    variant={"contained"}
    fullWidth
    style={{
      backgroundColor: "gold",
      color: "black",

    }}
    onClick={props.onClick}
  >
    {props.children}
  </Button>
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#04832a" : "#04832a",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));





export default function LiveAppMetricsComponent(props) {
  const { isCancellable = true, urls } = props;
  const [platformType, setPlatformType] = useState("TU");

  const [appStatistics, setAppStatistics] = useState({
    totalClients: 0,
    totalCountries: 177,
    totalTrades: 0,
    totalTradeVolume: 0,
  });
  const [updated, setUpdated] = useState(false);
  const [dateRange1, setDateRange1] = useState({
    startDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
    endDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
    key: "selection",
  });
  const [dateRange2, setDateRange2] = useState([null, null]);
  const [dateRange3, setDateRange3] = useState([null, null]);
  const [currentLoggedInUsers, setCurrentLoggedInUsers] = useState([]);
  const [currentTradeHistory, setCurrentTradeHistory] = useState([]);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [tradedProducts, setTradedProducts] = useState();
  const [loading, setLoading] = useState("block");
  const [userList, setUserList] = useState([]);
  const [environmentType, setEnvironmentType] = useState("LIVE");
  const nerveToken = useRef();
  useEffect(() => {
    getAPIData();
  }, [environmentType, platformType]);

  const [updateTime, setUpdateTime] = useState("");
  const [tradeUpdateTime, setTradeUpdateTime] = useState("");
  const [currentUsersUpdateTime, setCurrentUsersUpdateTime] = useState("");
  const [currentDayTradeCount, setCurrentDayTradeCount] = useState(0);
  const [oldEnvironmentValue, setOldEnvironmentValue] = useState("LIVE");
  let autoRefresher = useRef(null);
  const getAPIData = async (
    manualCall = false,
    startDate = "05DEC2020",
    currentTime = moment().format("DDMMMYYYY").toUpperCase()
  ) => {
    if (oldEnvironmentValue != environmentType) {
      setOldEnvironmentValue(environmentType);
      clearTimeout(autoRefresher.current);
    }
    setLoading("block");
    setUpdateTime(moment());
    setTradeUpdateTime(moment());
    setCurrentUsersUpdateTime(moment());
    try {
      let result = await axios.post(BASE_URL + "/" + platformType + GET_NERVE_TOKEN);

      nerveToken.current = result.data;
    } catch (e) {
      console.log(e.message);
    }

    axios.post(BASE_URL + "/" + platformType + GET_LIVE_APP_METRICS, {
      token: nerveToken.current,
      server: platformType,
      environmentType,
      startDate, currentTime
    })
      .then(async (res) => {


        setAppStatistics(res.data.appStatistics);
        setUserList(res.data.userList);
        setCurrentDayTradeCount(res.data.currentDayTradeCount);
        setCurrentLoggedInUsers(res.data.currentLoggedInUsers);
        setCurrentTradeHistory(res.data.currentTradeHistory);
        setTradedProducts(res.data.tradedProducts);
        setSearchedProducts(res.data.searchedProducts);

        setLoading("none");
        setDateRange1({
          startDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
          endDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
          key: "selection",
        });
        setDateRange2({
          startDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
          endDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
          key: "selection",
        });
        setDateRange3({
          startDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
          endDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
          key: "selection",
        });
        setUpdated((oldState) => {
          return !oldState;
        });
      });

    //----------------------------Auto Refresh Call----------------------------------------------------------------
    if (!manualCall) {
      autoRefresher.current = setTimeout(() => {
        getAPIData();
      }, 300000);
    }
    //----------------------------Auto Refresh Call----------------------------------------------------------------
  };

  const togglePlatformType = () => {
    if (platformType == "TU") {
      setPlatformType("CFC");
    }
    else {
      setPlatformType("TU");
    }
  }
  const closeAppMetrics = () => {
    clearTimeout(autoRefresher.current);
    props.ToggleAppMetrics(false);
  };

  const filterAPIData = async (
    startDate = "05DEC2020",
    type = "",
    manualCall = false,
    currentTime = moment().format("DDMMMYYYY").toUpperCase()
  ) => {

    setLoading("block");

    axios.post(BASE_URL + "/" + platformType + GET_SPECIFIC_METRICS, {
      token: nerveToken.current,
      type,
      server: platformType,
      environmentType,
      startDate, currentTime
    }).then(res => {
      setLoading("none");

      switch (type) {
        case "searched": {
          setSearchedProducts(res.data);

        }
          break;
        case "traded": {
          setTradedProducts(res.data);

        }
          break;
        case "userLog": {
          setUserList(res.data);

        }
          break;
        case "currentLogin": {
          setCurrentLoggedInUsers(res.data);

        }
          break;
        case "currentTrade": {
          setCurrentTradeHistory(res.data);

        }
          break;
      }

      setUpdated((oldState) => {
        return !oldState;
      });

    }).catch(e => {
      setLoading("none");

    })

  };



  return (
    <div style={{ margin: "16px", height: "100%" }} onClick={null}>
      <div style={{ position: "relative" }}>
        <Box sx={{ paddingTop: { lg: 0, xl: 0, md: 0, sm: 4, xs: 4 } }}>
          <h1 style={{ textAlign: "center" }}>{props.metricsHeading || "Tradeultra App Metrics"}</h1>
        </Box>
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            float: "right",
            display: "flex",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ marginBottom: "10px" }}>
              <Typography style={{ fontWeight: 700 }}>Century</Typography>
              <AntSwitch
                checked={platformType == "TU" ? true : false}
                onChange={(checked) => {
                  togglePlatformType()
                }}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography style={{ fontWeight: 700 }}>TU</Typography>
            </Stack>
            <Divider />
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography style={{ fontWeight: 700 }}>DEMO</Typography>
              <AntSwitch
                defaultChecked
                onChange={(event) => {
                  return setEnvironmentType(
                    event.target.checked ? "LIVE" : "DEMO"
                  );
                }}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography style={{ fontWeight: 700 }}>LIVE</Typography>
            </Stack>
          </div>
          {
            isCancellable && <CancelPresentationIcon
              style={{ cursor: "pointer" }}
              onClick={closeAppMetrics}
            />
          }
        </div>
      </div>

      <h4 style={{ textAlign: "center" }}>
        Last updated at: {updateTime.toString()} &nbsp;
        <Button
          variant={"contained"}
          onClick={() => {
            getAPIData(true);
          }}
          style={{ backgroundColor: "gold", color: "black" }}
        >
          Refresh
        </Button>
      </h4>

      <hr />
      <div
        style={{
          height: "3000px",
          width: "100%",
          position: "absolute",
          zIndex: "5",
          backgroundColor: "#fffffffa",
          backdropFilter: "blur(5px)",
          display: loading,
        }}
      >
        <h1 style={{ textAlign: "center" }}>LOADING...</h1>
      </div>
      <br />
      <Grid style={{ display: 'flex' }} container alignItems={"stretch"} spacing={2}>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Paper
            style={{
              backgroundColor: "#111",
              color: "gold",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <p>Total Clients</p>
            <h2 style={{ color: "white" }}>
              {appStatistics.totalClients.toLocaleString()}
            </h2>
          </Paper>

        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Paper
            style={{
              backgroundColor: "#111",
              color: "gold",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <p>Total Countries</p>
            <h2 style={{ color: "white" }}>{appStatistics.totalCountries}</h2>
          </Paper>
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <Box
            style={{
              width: '100%',
              height: '100%',
              boxSizing: 'border-box'

            }}
          >
            <Paper
              variant="outlined"
              style={{
                padding: "20px",
                height: '100%',
                boxSizing: 'border-box'


                // backgroundColor: "#fafafa",
              }}
            >
              <h2 style={{ textAlign: "center" }}>Searched Instruments</h2>
              <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <GoldButton onClick={() => {
                    let startDate = moment(addDays(new Date(), -1))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "searched", true);
                    // console.log(startDate);
                  }}>
                    Last 24 hours
                  </GoldButton>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <GoldButton onClick={() => {
                    let startDate = moment(addDays(new Date(), -7))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "searched", true);
                    // console.log(startDate);
                  }}>
                    Last 7 days
                  </GoldButton>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <GoldButton onClick={() => {
                    let startDate = moment(addDays(new Date(), -30))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "searched", true);
                    // console.log(startDate);
                  }}

                  >
                    Last 30 days
                  </GoldButton>
                </Grid>


                <Grid item xl={4} lg={4} md={4} sm={4} xs={12} >
                  <FormControl fullWidth sx={{ minWidth: "100px" }}>
                    <TextField
                      name={"startDate"}
                      label="Start Date"
                      value={moment(dateRange1.startDate).format("YYYY-MM-DD")}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        ".MuiInputBase-input": { fontSize: "0.8rem" },
                      }}
                      type="date"
                      onChange={(e) => {
                        let obj = dateRange1;
                        obj.startDate = moment(e.target.value)
                          .format("DDMMMYYYY")
                          .toUpperCase();
                        setDateRange1(obj);
                        setUpdated((oldState) => {
                          return !oldState;
                        });
                      }}
                    />
                  </FormControl>


                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <FormControl fullWidth sx={{ minWidth: "100px" }}>
                    <TextField
                      name={"endDate"}
                      label="End Date"
                      value={moment(dateRange1.endDate).format("YYYY-MM-DD")}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        ".MuiInputBase-input": { fontSize: "0.8rem" },
                      }}
                      type="date"
                      onChange={(e) => {
                        let obj = dateRange1;
                        obj.endDate = moment(e.target.value)
                          .format("DDMMMYYYY")
                          .toUpperCase();
                        setDateRange1(obj);
                        setUpdated((oldState) => {
                          return !oldState;
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <Button
                    fullWidth
                    variant={"contained"}
                    style={{
                      backgroundColor: "gold",
                      color: "black",

                    }}
                    onClick={() => {
                      if (
                        moment(dateRange1.endDate) < moment(dateRange1.startDate)
                      ) {
                        alert("Please select Start Date");
                      } else {
                        filterAPIData(
                          dateRange1.startDate,
                          "searched",
                          true,
                          dateRange1.endDate
                        );
                      }
                      // console.log(startDate);
                    }}
                  >
                    Submit
                  </Button>
                </Grid>

                <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                  <BubbleChartComponent
                    sx={{ height: "100px" }}
                    data={searchedProducts}
                    xField={"Product"}
                    yField={"Hits"}
                  />
                </Grid>

              </Grid>

              <br></br>

              {/* <DataGrid
                  style={{ height: "300px" }}
                  getRowId={(row) => row.Product}
                  columns={[
                    { field: "Product", flex: 1 },
                    { field: "Hits", flex: 1 },
                  ]}
                  rows={searchedProducts}
                  //   components={{
                  //     Toolbar: GridToolbar,
                  //   }}
                /> */}
              {/* {JSON.stringify(searchedProducts)} */}
            </Paper>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <Box
            style={{
              width: '100%',
              boxSizing: 'border-box',
              height: '100%'

            }}
          >
            <Paper
              variant="outlined"
              style={{
                height: '100%',
                boxSizing: 'border-box',

                padding: "20px",
                // backgroundColor: "#fafafa",
              }}
            >
              <h2 style={{ textAlign: "center" }}>Traded Instruments</h2>
              <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <GoldButton onClick={() => {
                    let startDate = moment(addDays(new Date(), -1))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "traded", true);
                    // console.log(startDate);
                  }}

                  >
                    Last 24 hours
                  </GoldButton>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <GoldButton
                    onClick={() => {
                      let startDate = moment(addDays(new Date(), -7))
                        .format("DDMMMYYYY")
                        .toUpperCase();
                      filterAPIData(startDate, "traded", true);
                      // console.log(startDate);
                    }}

                  >
                    Last 7 days
                  </GoldButton>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <GoldButton onClick={() => {
                    let startDate = moment(addDays(new Date(), -30))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "traded", true);
                    // console.log(startDate);
                  }}
                  >
                    Last 30 days
                  </GoldButton>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <FormControl fullWidth sx={{ minWidth: "100px" }}>
                    <TextField
                      name={"startDate"}
                      label="Start Date"
                      value={moment(dateRange1.startDate).format("YYYY-MM-DD")}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        ".MuiInputBase-input": { fontSize: "0.8rem" },
                      }}
                      type="date"
                      onChange={(e) => {
                        let obj = dateRange1;
                        obj.startDate = moment(e.target.value)
                          .format("DDMMMYYYY")
                          .toUpperCase();
                        setDateRange1(obj);
                        setUpdated((oldState) => {
                          return !oldState;
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <FormControl fullWidth sx={{ minWidth: "100px" }}>
                    <TextField
                      name={"endDate"}
                      label="End Date"
                      value={moment(dateRange1.endDate).format("YYYY-MM-DD")}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        ".MuiInputBase-input": { fontSize: "0.8rem" },
                      }}
                      type="date"
                      onChange={(e) => {
                        let obj = dateRange1;
                        obj.endDate = moment(e.target.value)
                          .format("DDMMMYYYY")
                          .toUpperCase();
                        setDateRange1(obj);
                        setUpdated((oldState) => {
                          return !oldState;
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <GoldButton onClick={() => {
                    if (
                      moment(dateRange1.endDate) < moment(dateRange1.startDate)
                    ) {
                      alert("Please select Start Date");
                    } else {
                      filterAPIData(
                        dateRange1.startDate,
                        "traded",
                        true,
                        dateRange1.endDate
                      );
                    }
                    // console.log(startDate);
                  }}
                  >
                    Submit
                  </GoldButton>
                </Grid>
              </Grid>

              <div style={{ height: "80%", width: "100%" }}>
                {/* <BubbleChartComponent
                    sx={{ height: "100px" }}
                    data={searchedProducts}
                    xField={"Product"}
                    yField={"Hits"}
                  /> */}
                {/* <TreeMapComponent
                    data={tradedProducts?.asset}
                    colorField={"name"}
                  /> */}
                <div
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    overflow: "auto",
                    height: "250px",
                    borderBottom: "solid 1px #adadad",
                  }}
                >
                  <Table
                    stickyHeader
                    style={{
                      tableLayout: "auto",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Asset Class</StyledTableCell>
                        <StyledTableCell># of Trades</StyledTableCell>
                        <StyledTableCell>Traded Volume</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tradedProducts?.asset.map((k) => {
                        return (
                          <StyledTableRow>
                            <StyledTableCell>{k.Name}</StyledTableCell>
                            <StyledTableCell>
                              {k.NumberOfTrades.toLocaleString()}
                            </StyledTableCell>
                            <StyledTableCell>
                              USD{" "}
                              {(
                                Math.round(k.TotalVolumeTraded * 100) / 100
                              ).toLocaleString()}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
                <br></br>
                <Grid container spacing={1}>
                  {tradedProducts?.instrustments.map((k) => {
                    return (
                      <Grid item xl={4} lg={4} md={4} sm={4} xs={6}>
                        <Button
                          fullWidth
                          style={{ backgroundColor: "#111" }}
                          variant={"contained"}
                        >
                          {k}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Paper>
          </Box>
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <Box
            style={{
              width: '100%',
              height: '100%',
              boxSizing: 'border-box',

            }}
          >
            <Paper
              variant="outlined"
              style={{
                height: '100%',
                boxSizing: 'border-box',

                padding: "20px",
                // backgroundColor: "#fafafa",
              }}
            >
              <h2 style={{ textAlign: "center" }}>Active Clients on App</h2>

              <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <GoldButton
                    onClick={() => {
                      let startDate = moment(addDays(new Date(), -1))
                        .format("DDMMMYYYY")
                        .toUpperCase();
                      filterAPIData(startDate, "userLog", true);
                      // console.log(startDate);
                    }}

                  >
                    Last 24 hours
                  </GoldButton>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <GoldButton
                    onClick={() => {
                      let startDate = moment(addDays(new Date(), -7))
                        .format("DDMMMYYYY")
                        .toUpperCase();
                      filterAPIData(startDate, "userLog", true);
                      // console.log(startDate);
                    }}
                  >
                    Last 7 days
                  </GoldButton>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <GoldButton
                    onClick={() => {
                      let startDate = moment(addDays(new Date(), -30))
                        .format("DDMMMYYYY")
                        .toUpperCase();
                      filterAPIData(startDate, "userLog", true);
                      // console.log(startDate);
                    }}
                  >
                    Last 30 days
                  </GoldButton>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <FormControl fullWidth sx={{ minWidth: "100px" }}>
                    <TextField
                      name={"startDate"}
                      label="Start Date"
                      value={moment(dateRange1.startDate).format("YYYY-MM-DD")}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        ".MuiInputBase-input": { fontSize: "0.8rem" },
                      }}
                      type="date"
                      onChange={(e) => {
                        let obj = dateRange1;
                        obj.startDate = moment(e.target.value)
                          .format("DDMMMYYYY")
                          .toUpperCase();
                        setDateRange1(obj);
                        setUpdated((oldState) => {
                          return !oldState;
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <FormControl fullWidth sx={{ minWidth: "100px" }}>
                    <TextField
                      name={"endDate"}
                      label="End Date"
                      value={moment(dateRange1.endDate).format("YYYY-MM-DD")}
                      InputLabelProps={{ shrink: true }}
                      sx={{
                        ".MuiInputBase-input": { fontSize: "0.8rem" },
                      }}
                      type="date"
                      onChange={(e) => {
                        let obj = dateRange1;
                        obj.endDate = moment(e.target.value)
                          .format("DDMMMYYYY")
                          .toUpperCase();
                        setDateRange1(obj);
                        setUpdated((oldState) => {
                          return !oldState;
                        });
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <GoldButton
                    onClick={() => {
                      if (
                        moment(dateRange1.endDate) < moment(dateRange1.startDate)
                      ) {
                        alert("Please select Start Date");
                      } else {
                        filterAPIData(
                          dateRange1.startDate,
                          "userLog",
                          true,
                          dateRange1.endDate
                        );
                      }
                      // console.log(startDate);
                    }}
                  >
                    Submit
                  </GoldButton>
                </Grid>
              </Grid>



              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >



              </div>
              <br></br>
              <div style={{ height: "80%", width: "100%" }}>
                <DataGrid
                  style={{ height: "300px" }}
                  getRowId={(row) => row.Username}
                  columns={[
                    {
                      field: "Username",
                      headerName: "Client ID",
                      align: "center",
                      headerAlign: "center",
                      flex: 1,
                    },
                    {
                      field: "LastLoginTime",
                      headerName: "Last Login",
                      align: "center",
                      headerAlign: "center",
                      flex: 1,
                    },
                    // {
                    //   field: "country",
                    //   headerName: "Country",
                    //   align: "center",
                    //   headerAlign: "center",
                    //   flex: 1,
                    // },
                  ]}
                  rows={userList}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                />
              </div>

              {/* {JSON.stringify(searchedProducts)} */}
            </Paper>
          </Box>
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <Box
            style={{
              width: "100%",
              boxSizing: 'border-box',

              height: '100%',
            }}
          >
            <Paper
              variant="outlined"
              style={{
                height: '100%',
                boxSizing: 'border-box',

                padding: "20px",
                // backgroundColor: "#fafafa",
              }}
            >
              <h2 style={{ textAlign: "center" }}>
                Currently Logged In Users -{" "}
                {currentLoggedInUsers?.list_Users?.length}
              </h2>
              <h4 style={{ textAlign: "center" }}>
                Last updated at: {currentUsersUpdateTime.toString()} &nbsp;
                <Button
                  variant={"contained"}
                  onClick={() => {
                    let startDate = moment(addDays(new Date(), -30))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "currentLogin", true);
                  }}
                  style={{ backgroundColor: "gold", color: "black" }}
                >
                  Refresh
                </Button>
              </h4>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  overflow: "auto",
                  maxHeight: "325px",
                }}
              >
                {currentLoggedInUsers?.list_Users?.map((k) => {
                  return (
                    <p
                      style={{
                        backgroundColor: "#04832a",
                        color: "white",
                        height: "40px",
                        width: "100px",
                        lineHeight: "40px",
                        textAlign: "center",
                        margin: "10px",
                        borderRadius: "5px",
                        boxShadow: "0 1px 2px 0 gray",
                      }}
                    >
                      {k}
                    </p>
                  );
                })}
              </div>
            </Paper>
          </Box>
          {/* {JSON.stringify(currentLoggedInUsers, null, 2)} */}
        </Grid>
        <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
          <Box
            style={{
              width: "100%",
              boxSizing: 'border-box',
              height: '100%'

            }}
          >
            <Paper
              variant="outlined"

              style={{
                height: '100%',
                boxSizing: 'border-box',

                padding: "20px",
                // backgroundColor: "#fafafa",
              }}
            >
              <h2 style={{ textAlign: "center" }}>
                Current Day Trades (Count: {currentDayTradeCount})
              </h2>
              <h4 style={{ textAlign: "center" }}>
                Last updated at: {tradeUpdateTime.toString()} &nbsp;
                <Button
                  variant={"contained"}
                  onClick={() => {
                    let startDate = moment(addDays(new Date(), -30))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "currentTrade", true);
                  }}
                  style={{ backgroundColor: "gold", color: "black" }}
                >
                  Refresh
                </Button>
              </h4>
              <DataGrid
                style={{ height: "375px" }}
                getRowId={(row) => row["Time Band"]}
                columns={[
                  {
                    field: "Time Band",
                    flex: 1,
                    maxWidth: 175,
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    field: "Count of Trades",
                    flex: 1,
                    maxWidth: 150,
                    align: "center",
                    headerAlign: "center",
                  },
                  {
                    field: "Total Volume",
                    flex: 1,
                    maxWidth: 150,
                    align: "center",
                    headerAlign: "center",
                  },
                  { field: "Instrument List", flex: 1 },
                ]}
                rows={currentTradeHistory}
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>


    </div>
  );
}
