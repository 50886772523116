import { Line } from '@ant-design/charts';
const CustomLineChart = (props) => {
    const { data } = props
    const config = {
        data: data,
        xField: 'item',
        yField: 'value',
        seriesField: 'type',
        yAxis: {
            label: {
                formatter: (v) => `${v}%`,
            },
            grid: { line: { style: 0 } },
        },
        // label: {
        //   type: 'inner',
        //   offset: '-30%',
        //   content: ({ percent }) => `${(percent)}%`,
        //   style: {
        //     fontSize: 14,
        //     textAlign: 'center',
        //   },
        // },
        tooltip: {
            formatter: (datum) => {
                return { name: datum.type, value: datum.value + '%' };
            },
            style: {
                fill: 'red',
            }
        },
        label: {
            formatter: ({ value }) => {
                if (!value)
                    return 0;
                return `${value}%`;
            },
            position: "top",
            style: { opacity: 1, fill: "black" },
            offset: 20,
        },
    };

    return <Line {...config} />;
};
export default CustomLineChart;