import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "typeface-roboto";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
// import { createStore, applyMiddleware } from "redux";
// import reduxThunk from "redux-thunk";
// import combinedReducers from "./reducers";
// import { Provider } from "react-redux";

// const store = createStore({}, {}, applyMiddleware(reduxThunk));

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
