import React, { useEffect } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, TableRow, TableCell, TableHead, TableContainer, Table, TableBody, Tabs, Tab, CircularProgress } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import axios from "axios";
import { BASE_URL, GET_MONTH_WISE_TOP_CLIENTS_AND_SYMBOLS } from '../../helpers/adminApiStrings';
import moment from 'moment';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function MonthlyTopClientAndSymbolDialog(props) {

  const { open, onClose, filterValues, uproData, selectedDate, platformType } = props;
  const [selectedTab, setSelectedTab] = useState(0);
  const [clients, setClients] = useState([]);
  const [symbols, setSymbols] = useState([]);
  const [topClients, setTopClients] = useState([]);
  const [topSymbols, setTopSymbols] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  function getData() {
    setIsLoading(true);
    axios.post(BASE_URL + "/" + platformType + GET_MONTH_WISE_TOP_CLIENTS_AND_SYMBOLS, {
      values: filterValues.current,
      uproData
    })
      .then(res => {
        setIsLoading(false);
        setTopClients(res.data[0].topClients);
        setTopSymbols(res.data[0].topSymbols);
      }).catch(e => {
        setIsLoading(true);

        console.log(e.message);
      })
  }

  function getFormattedStatValue(value) {
    let val = null;

    if (value < 1000) {
      val = value
    } else if (value >= 1000 && value < 1000000) {
      val = Math.round(value / 1000).toLocaleString() + "K";
    } else if (value >= 1000000) {
      val = Math.round(value / 1000000).toLocaleString() + "M";

    }
    return val;
  }

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    let index = null;
    if (topClients.length > 0 && topSymbols.length > 0) {
      if (selectedDate) {

        for (let i = 0; i < topClients.length; i++) {
          if (moment(selectedDate, 'MMMM YYYY').format('YYYY-MM') === topClients[i]._id) {
            index = i;
          }
        }

        setClients(topClients[index].array);
        setSymbols(topSymbols[index].array);
      }
    }
  }, [selectedDate])

  return <Dialog open={open} onClose={onClose}>
    <DialogTitle>Montly Top Clients & Symbols ({selectedDate})</DialogTitle>
    <DialogContent>
      <Box>
        <Tabs
          value={selectedTab}
          onChange={(e, value) => {
            setSelectedTab(value);
          }}
        >
          <Tab style={{ width: "50%" }} label="Clients" />
          <Tab style={{ width: "50%" }} label="Symbols" />

        </Tabs>
        <div
          style={{
            overflow: "auto",
            height: "400px",
            display: selectedTab === 0 ? "block" : 'none'

          }}
        >
          <Table
            stickyHeader
            style={{
              tableLayout: "fixed",
            }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Client</StyledTableCell>
                <StyledTableCell>Txn Volume</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients.map(c => {
                return (
                  <StyledTableRow>
                    <StyledTableCell>
                      {c.client}
                    </StyledTableCell>
                    <StyledTableCell>
                      USD{" "}
                      {/* {(
                                      Math.round(
                                        c.txnVolume * 100
                                      ) / 100
                                    ).toLocaleString()} */}
                      {
                        getFormattedStatValue(c.txnVolume)
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>

        <div
          style={{
            overflow: "auto",
            height: "400px",
            display: selectedTab === 1 ? "block" : 'none'
          }}
        >
          <Table
            stickyHeader
            style={{
              tableLayout: "fixed",
            }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell>Symbol</StyledTableCell>
                <StyledTableCell>Txn Volume</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {symbols.map(c => {
                return (
                  <StyledTableRow>
                    <StyledTableCell>
                      {c.symbol}
                    </StyledTableCell>
                    <StyledTableCell>
                      USD{" "}
                      {
                        getFormattedStatValue(c.txnVolume)
                      }
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Box>
      {isLoading && <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress disableShrink />
      </Box>}
    </DialogContent>
  </Dialog>
}