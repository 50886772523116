import React, { useState, useEffect } from "react";
import { Area } from "@ant-design/plots";
import axios from "axios";
import {
  BASE_URL,
  GET_ACTIVE_CLIENTS_LIST,
  GET_ACTIVE_CLIENTS_LIST_MGMT,
} from "../../helpers/adminApiStrings";
const moment = require("moment");

const AreaChartComponentCFC = (props) => {
  let { data, xField, yField, series, isAdmin = false, platformType, userEmail, clientList, values } = props;

  let sortedDataByDate = data.sort((a, b) =>
    new moment(a.date).diff(new moment(b.date))
  );
  const config = {
    data,
    onReady: (plot) => {
      plot.on("plot:click", (...args) => {
        props.onOpenDataGrid();

        axios
          .post(
            isAdmin
              ? BASE_URL + "/CFC" + GET_ACTIVE_CLIENTS_LIST_MGMT
              : BASE_URL + "/CFC" + GET_ACTIVE_CLIENTS_LIST,
            {
              uproDataEncrypted: props.encryptedData,
              userEmail: userEmail,
              clientsList: clientList,
              values: values
            }
          )
          .then((res) => {
            props.setGridDataTitle("Active & Passive Clients");
            props.setDialogDataGrid(
              res.data.clientsList.filter((object) => {
                return object.loginId !== null;
              })
            );
          })
          .catch((err) => {
            console.log(err.message);
          });
      });
    },
    label: {
      formatter: ({ count }) => {
        return Math.round(count).toLocaleString() + "";
      },
      position: ["top", "bottom"],
      style: { opacity: 1, fill: "black" },
    },
    xField: xField,
    yField: yField,
    seriesField: series,
    yAxis: {
      grid: { line: { style: 0 } },
    },
    xAxis: {
      label: {
        formatter: (label) => {
          return (label = moment(label).format("MMM-YY"));
        },
        autoRotate: true,
      },
    },

    legend: {
      position: "right",
    },
    smooth: true,
    color: ["black", "gold"],
    animation: {
      appear: {
        animation: "path-in",
        duration: 2500,
      },
    },
  };

  return <Area {...config} />;
};

export default AreaChartComponentCFC;
