import React, { useState, useRef, useEffect, useContext } from "react";
import { Space, Tooltip } from "antd";

import shortid from "shortid";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export default function GroupNameColumn(props) {
  const { group_name, sr_no, setErrors, duplicateGroupObject } = props;
  const [isError, setIsError] = useState(false);
  //const [elementId, setElementId] = useState(shortid.generate());
  const [elementId, setElementId] = useState(
    group_name + "" + sr_no + "_groupname"
  );
  const [errorTitle, setErrorTitle] = useState();
  useEffect(() => {
    try {
      if (typeof group_name == "object") {
        setIsError(true);
        setErrorTitle("Group Name can't be empty");
      } else if (group_name.toString().trim().length === 0) {
        setIsError(true);
        setErrorTitle("Group Name can't be empty");
      }
      if (duplicateGroupObject[sr_no - 1] == true) {
        setIsError(true);
        setErrorTitle("Duplicate Group Name");
      }
    } catch (e) {
      // setErrors(old => [...old, elementId]);
      setIsError(true);
    }
  }, []);

  return (
    <>
      {isError && (
        <Tooltip title={errorTitle}>
          <div id={elementId} style={{ paddingLeft: 4 }}>
            <Space>
              <span style={{ color: "red" }}>{group_name}</span>
              <ReportProblemIcon fontSize="small" style={{ color: "gold" }} />
            </Space>
          </div>
        </Tooltip>
      )}
      {!isError && (
        <div id={elementId} style={{ paddingLeft: 4 }}>
          <span style={{ color: "black" }}>{group_name}</span>
        </div>
      )}
    </>
  );
}
