import React, { useState, useRef, useEffect, useContext } from "react";
import { Space, Tooltip } from "antd";

import shortid from "shortid";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export default function CurrencyColumn(props) {
  const { currency: currency, sr_no, setErrors } = props;
  const [isError, setIsError] = useState(false);
  const [elementId, setElementId] = useState(
    currency + "" + sr_no + "_currency"
  );

  useEffect(() => {
    try {
      if (currency.trim().length === 0) {
        //    setErrors(old => [...old, elementId]);
        setIsError(true);
      }
    } catch (e) {
      // setErrors(old => [...old, elementId]);
      setIsError(true);
    }
  }, []);

  return (
    <>
      {isError && (
        <Tooltip title={"Currency can't be empty"}>
          <div id={elementId} style={{ paddingLeft: 4 }}>
            <Space>
              <span style={{ color: "red" }}>{currency}</span>
              <ReportProblemIcon fontSize="small" style={{ color: "gold" }} />
            </Space>
          </div>
        </Tooltip>
      )}
      {!isError && (
        <div id={elementId} style={{ paddingLeft: 4 }}>
          <span style={{ color: "black" }}>{currency}</span>
        </div>
      )}
    </>
  );
}
