import { config } from "../../helpers/config";
import { PublicClientApplication } from "@azure/msal-browser";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { JSEncrypt } from "jsencrypt";
import axios from "axios";
import { Buffer } from "buffer";

import { CHECK_ALLOWED_USERS, GET_PUBLIC_KEY } from "../../helpers/apiStrings";
const encrypt = new JSEncrypt();
const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUri,
    authority: config.authority,
    postLogoutRedirectUri: config.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
});

export default function AdminLogin(props) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  function logout() {
    try {
      localStorage.clear();
      sessionStorage.clear();
      (function () {
        var cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
          var d = window.location.hostname.split(".");
          while (d.length > 0) {
            var cookieBase =
              encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
              "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
              d.join(".") +
              " ;path=";
            var p = window.location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
              document.cookie = cookieBase + p.join("/");
              p.pop();
            }
            d.shift();
          }
        }
      })();
      window.location.reload();
    } catch (e) {
      console.log(e.message);
    }
  }
  function getPublicKey() {
    setLoading(true);
    axios
      .get(GET_PUBLIC_KEY)
      .then((res) => {
        setLoading(false);
        const d = Buffer.from(res.data, "base64").toString("ascii");
        encrypt.setPublicKey(d);
        let accounts = publicClientApplication.getAllAccounts();

        if (accounts.length == 0) {
        } else {
          checkAuthorization(accounts[0].username);
        }
      })
      .catch((e) => {
        setLoading(false);
        console.log(e.message);
      });
  }

  function checkAuthorization(userEmail) {
    //CAU = century admin users
    const enc = encrypt.encrypt(JSON.stringify({ userType: "CAU", userEmail }));
    axios
      .post(CHECK_ALLOWED_USERS, { payload: enc })
      .then((res) => {
        navigate("/admin");
      })
      .catch((e) => {
        console.log(e.message);
        logout();
      });
  }
  useEffect(() => {
    document.title = "Trading Dashboard | MGMT Login";

    getPublicKey();
  }, []);

  const login = async () => {
    try {
      const res = await publicClientApplication.loginPopup({
        scopes: config.scopes,
        prompt: "select_account",
      });

      if (res) {
        checkAuthorization(res.account.username);
      }
    } catch (err) {
      console.log(err.message);
    }
  };
  // const login = async () => {
  //   try {
  //     checkAuthorization("mumshad@century.ae");
  //     // navigate("/admin");
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
          boxShadow: "0 1px 2px gray",
          width: "20vw",
          height: "20vh",
          marginTop: "35vh",
          marginLeft: "35vw",
          padding: "2vw",
        }}
      >
        <h1>Trading Dashboard</h1>

        {!loading && (
          <Button
            variant="contained"
            onClick={() => {
              login();
            }}
          >
            Login
          </Button>
        )}

        {error && <p>ERROR! Contact ahish.a@century.ae.</p>}
      </div>
    </div>
  );
}
