import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
const moment = require("moment");
const BarChartComponent = (props) => {
  let { data, xField, yField, seriesField } = props;

  const config = {
    data: data,
    xField: xField,
    yField: yField,
    seriesField: seriesField,
    color: ["gold", "#111"],
    label: {
      formatter: (value) => {
        value.value = Math.round(value.value * 100) / 100;
      },
      formatter: ({ value }) => {
        return Math.round(value / 1000).toLocaleString() + "K";
      },
      position: ["top", "bottom"],
      style: { opacity: 1, fill: "black" },
    },
    yAxis: {
      label: {
        formatter: (label) => {
          return (label = (label / 1000).toLocaleString() + "K");
        },
      },
      count: false,
    },

    xAxis: {
      label: {
        formatter: (label) => {
          return (label = moment(label).format("MMM YY"));
        },
        autoRotate: true,
      },
    },
    geometryOptions: [
      {
        geometry: "column",
        color: "#5B8FF9",
        columnWidthRatio: 0.4,
        label: {
          position: "middle",
        },
        style: { opacity: 1, fill: "black" },
      },
    ],
    interactions: [
      {
        type: "element-highlight",
      },
    ],
    scrollbar: {
      type: "horizontal",
    },
    /*
    tooltip: {
    
      title:(value)=>new moment(value).format("MMMM YYYY"),

customItems: (originalItems) => {
  // process originalItems,
  originalItems[0].name = "Deposits";
  originalItems[0].value = Math.round(originalItems[0].value / 1000).toLocaleString() + "K"
  originalItems[1].name = "Deposit Count";
  return originalItems;
},
     
     
    }
    */
   tooltip:{
    title:(value)=>new moment(value).format("MMMM YYYY"),

   }
  };
  return <Column {...config} />;
};

export default BarChartComponent;
