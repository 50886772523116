import React, { useState, useEffect } from "react";
import { DualAxes } from "@ant-design/plots";
const moment = require("moment");

function getFormattedStatValue(value) {
  let val = null;

  if (value < 1000) {
    val = value
  } else if (value >= 1000 && value < 1000000) {
    val = Math.round(value / 1000).toLocaleString() + "K";
  } else if (value >= 1000000) {
    val = Math.round(value / 1000000).toLocaleString() + "M";

  }
  return val;
}

const BarLineChartComponent = (props) => {
  let { data, xField, yFieldLine, yFieldBar,onPlotClickListener=(plot)=>{
    plot.on("element:click", (...args) => {
      console.log("Element click",args);
    });
    plot.on('plot:click', (evt) => {
     
      const { x, y } = evt;
      const { xField } = plot.options;
      const tooltipData = plot.chart.getTooltipItems({ x, y });
      console.log("Plot click tooltip",tooltipData);
    });
  } } = props;
  // data = [
  //     {
  //         time: '2019-03',
  //         value: 350,
  //         count: 800,
  //     },
  //     {
  //         time: '2019-04',
  //         value: 900,
  //         count: 600,
  //     },
  //     {
  //         time: '2019-05',
  //         value: 300,
  //         count: 400,
  //     },
  //     {
  //         time: '2019-06',
  //         value: 450,
  //         count: 380,
  //     },
  //     {
  //         time: '2019-07',
  //         value: 470,
  //         count: 220,
  //     },
  // ];
  const config = {
    data: [data, data],
    onReady: onPlotClickListener,
    xField: xField,
    yField: [yFieldBar, yFieldLine],
    yAxis: {
      [yFieldBar]: {
        title: {
          text: "Transaction Volume",
        },
        grid: { line: { style: 0 } },
        label: {
          formatter: (label) => {
            //return (label = (label / 1000000).toLocaleString() + " M");
            return getFormattedStatValue(label);
          },
        },
      },
      [yFieldLine]: {
        title: {
          text: "Transaction Count",
        },
      },
    },
    xAxis: {
      title: {
        text: "Months",
      },
      label: {
        formatter: (label) => {
          return moment(label).format("MMM-YY")
        },
        autoRotate: true,
      },
    },
    geometryOptions: [
      {
        geometry: "column",
        // color: '#2d094b',
        color: "#111",
        columnWidthRatio: 0.4,
        label: {
          formatter: (value) => {
            //return Math.round(value.txnVolume / 1000000).toLocaleString() + " M"
            return getFormattedStatValue(value.txnVolume);
          },
         
          position: "top",
          style: { opacity: 1, fill: "black" },
        },
      },
      {
        geometry: "line",
        smooth: true,
        color: "gold",
        label: {
          position: "top",
          style: { opacity: 1, fill: "gold" },
        },
      },
    ],
    interactions: [
      {
        type: "element-highlight",
      },
    ],
    legend: {
      layout: "vertical",
      position: "right",
    },
    scrollbar: {
      type: "horizontal",
    },
    tooltip: {
    
      title:(value)=>new moment(value).format("MMMM YYYY"),
      customItems: (originalItems) => {
        // process originalItems,
        originalItems[0].name = "Txn Volume";
        originalItems[0].value = getFormattedStatValue(originalItems[0].value);
        originalItems[1].name = "Txn Count";
        return originalItems;
      },
   
      showContent:true
    }
  };
  return <DualAxes {...config} />;
};

export default BarLineChartComponent;
