import React, { useState, useRef, useEffect, useContext } from "react";
import { Space, Tooltip } from "antd";

import shortid from "shortid";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export default function PlatformType(props) {
    const { platform_type, sr_no, setErrors } = props;
    const [isError, setIsError] = useState(false);
    const [elementId, setElementId] = useState(
        platform_type + "" + sr_no + "_platformtype"
    );
    const [errorTitle, setErrorTitle] = useState();
    useEffect(() => {
        try {
            if (typeof platform_type == "object") {
                setIsError(true);
                setErrorTitle("Platform Type can't be empty");
            } else if (platform_type.toString().trim().length === 0) {
                setIsError(true);
                setErrorTitle("Platform Type can't be empty");
            }
            if (platform_type.toString() == "CFC" || platform_type.toString() == "TU") { }
            else {
                setIsError(true);
                setErrorTitle("Platform Type must be CFC or TU");
            }
        } catch (e) {
            // setErrors(old => [...old, elementId]);
            setIsError(true);
        }
    }, []);

    return (
        <>
            {isError && (
                <Tooltip title={errorTitle}>
                    <div id={elementId} style={{ paddingLeft: 4 }}>
                        <Space>
                            <span style={{ color: "red" }}>{platform_type}</span>
                            <ReportProblemIcon fontSize="small" style={{ color: "gold" }} />
                        </Space>
                    </div>
                </Tooltip>
            )}
            {!isError && (
                <div id={elementId} style={{ paddingLeft: 4 }}>
                    <span style={{ color: "black" }}>{platform_type}</span>
                </div>
            )}
        </>
    );
}
