
import { CircularProgress } from '@mui/material';
import {Skeleton, Space, Table} from 'antd';

export default function TableSkeleton(props){

  const {scrollHeight,resultType} = props;

    const renderedRows = [...Array(12)].map((e, i) => (
     {}   
    ));


      function DataSkeleton(){
        return <div style={{width:'100%'}}>
        <div className="skeleton"></div>
      </div>
      }


      const columns = [
        { title: "Serial", width: 100, render: (row) => <DataSkeleton/> },
        { title: "Group", width: 150, render: (row) => <DataSkeleton/> },
        { title: "Symbol",  width: 200,render: (row) => <DataSkeleton/> },
        { title: "Description",render: (row) => <DataSkeleton/> },
        { title: "LP", width: 100,render:(row)=><DataSkeleton/> },
        { title: "Digits",  width: 100,render:(row)=><DataSkeleton/> },
        { title: "Benchmark", width: 150, render: (row) => <DataSkeleton/> },
        { title: "Core Spread", width: 150, render:(row)=><DataSkeleton/> },



    ];   
    

    return <Table
    dataSource={renderedRows}
    columns={columns}
    pagination={false}
    scroll={{
        y: scrollHeight
    }}
    title={()=><Space>
      <CircularProgress disableShrink size={16}/>
      <span>{resultType === "imported" ? "Importing file..." : "Loading file from history...."}</span>
      </Space>}/>
}



