import {
  TextField,
  Button,
  Grid,
  Box,
  Paper,
  FormControl,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { tableCellClasses } from "@mui/material/TableCell";
import { addDays, set } from "date-fns";
import {
  BASE_URL,
  GET_CURRENT_LOGIN_USERS,
  GET_CURRENT_TRADE_DETAILS,
  GET_LOGIN_USERS,
  GET_MGMT_USER_DETAILS,
  GET_NERVE_TOKEN,
  GET_RM_APP_METRICS,
  GET_RM_SPECIFIC_METRICS,
  GET_SEARCHED_INSTRUMENTS,
  GET_TRADE_DETAILS,
} from "../helpers/adminApiStrings";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import BubbleChartComponent2 from "../components/charts/bubbleChart2";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import { styled } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#04832a" : "#04832a",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function RMAppMetricsComponent(props) {
  const { platformType } = props;
  const [appStatistics, setAppStatistics] = useState({
    totalClients: 0,
    totalCountries: 177,
    totalTrades: 0,
    totalTradeVolume: 0,
  });
  const [updated, setUpdated] = useState(false);
  const [dateRange1, setDateRange1] = useState({
    startDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
    endDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
    key: "selection",
  });
  const [dateRange2, setDateRange2] = useState([null, null]);
  const [dateRange3, setDateRange3] = useState([null, null]);
  const [currentLoggedInUsers, setCurrentLoggedInUsers] = useState([]);
  const [currentTradeHistory, setCurrentTradeHistory] = useState([]);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [tradedProducts, setTradedProducts] = useState();
  const [loading, setLoading] = useState("block");
  const [userList, setUserList] = useState([]);
  const [userAccounts, setUserAccounts] = useState(null);
  const [environmentType, setEnvironmentType] = useState("LIVE");
  const nerveToken = useRef();

  useEffect(() => {
    getAPIData();
  }, [environmentType]);

  const [updateTime, setUpdateTime] = useState("");
  const [tradeUpdateTime, setTradeUpdateTime] = useState("");
  const [currentUsersUpdateTime, setCurrentUsersUpdateTime] = useState("");
  const [currentDayTradeCount, setCurrentDayTradeCount] = useState(0);
  const [oldEnvironmentValue, setOldEnvironmentValue] = useState("LIVE");
  const [searchParams, setSearchParams] = useSearchParams();
  let autoRefresher = useRef(null);

  const getAPIData = async (
    manualCall = false,
    startDate = "05DEC2020",
    currentTime = moment().format("DDMMMYYYY").toUpperCase()
  ) => {
    if (oldEnvironmentValue != environmentType) {
      setOldEnvironmentValue(environmentType);
      clearTimeout(autoRefresher.current);
    }
    setLoading("block");
    setUpdateTime(moment());
    setTradeUpdateTime(moment());
    setCurrentUsersUpdateTime(moment());
    try {
      let result = await axios.post(
        BASE_URL + "/" + platformType + GET_NERVE_TOKEN
      );

      nerveToken.current = result.data;
    } catch (e) {
      console.log(e.message);
    }
    axios
      .post(BASE_URL + "/" + platformType + GET_RM_APP_METRICS, {
        token: nerveToken.current,
        server: platformType,
        environmentType,
        uProDataEncrypted: searchParams.get("d"),
        startDate,
        currentTime,
      })
      .then(async (res) => {
        //
        setAppStatistics(res.data.appStatistics);
        setUserList(res.data.userList);
        setUserAccounts(res.data.userAccounts);
        setCurrentLoggedInUsers(res.data.currentLoggedInUsers);
        setTradedProducts(res.data.tradedProducts);
        setSearchedProducts(res.data.searchedProducts);

        setLoading("none");

        setDateRange1({
          startDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
          endDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
          key: "selection",
        });
        setDateRange2({
          startDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
          endDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
          key: "selection",
        });
        setDateRange3({
          startDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
          endDate: moment(new Date()).format("DDMMMYYYY").toUpperCase(),
          key: "selection",
        });
        setUpdated((oldState) => {
          return !oldState;
        });
      })
      .catch((e) => {
        console.log("Error fetching API data", e.message);
        setLoading("none");
      });

    //----------------------------Auto Refresh Call----------------------------------------------------------------
    if (!manualCall) {
      autoRefresher.current = setTimeout(() => {
        getAPIData();
      }, 300000);
    }
    //----------------------------Auto Refresh Call----------------------------------------------------------------
  };

  const closeAppMetrics = () => {
    clearTimeout(autoRefresher.current);
    props.ToggleAppMetrics(false);
  };

  const filterAPIData = async (
    startDate = "05DEC2020",
    type = "",
    manualCall = false,
    currentTime = moment().format("DDMMMYYYY").toUpperCase()
  ) => {
    // setUpdateTime(moment());
    try {
      setLoading("block");
      let result = await axios.post(
        BASE_URL + "/" + platformType + GET_NERVE_TOKEN
      );

      nerveToken.current = result.data;
    } catch (e) {
      console.log(e.message);
    }

    axios
      .post(BASE_URL + "/" + platformType + GET_RM_SPECIFIC_METRICS, {
        token: nerveToken.current,
        type,
        uProDataEncrypted: searchParams.get("d"),
        server: platformType,
        environmentType,
        startDate,
        currentTime,
      })
      .then((res) => {
        setLoading("none");

        switch (type) {
          case "searched":
            {
              setSearchedProducts(res.data);
            }
            break;
          case "traded":
            {
              setTradedProducts(res.data);
            }
            break;
          case "userLog":
            {
              setUserList(res.data);
            }
            break;
          case "currentLogin":
            {
              setCurrentLoggedInUsers(res.data);
            }
            break;
        }

        setUpdated((oldState) => {
          return !oldState;
        });
      })
      .catch((e) => {
        setLoading("none");
      });
  };

  return (
    <div style={{ margin: "16px", height: "100%" }} onClick={null}>
      <div style={{ position: "relative" }}>
        <h1 style={{ textAlign: "center" }}>
          {platformType == "TU" ? "Tradeultra" : "Century"} App Metrics{" "}
        </h1>
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            float: "right",
            display: "flex",
          }}
        >
          <div style={{ marginRight: "20px" }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography style={{ fontWeight: 700 }}>DEMO</Typography>
              <AntSwitch
                defaultChecked
                onChange={(event) => {
                  return setEnvironmentType(
                    event.target.checked ? "LIVE" : "DEMO"
                  );
                }}
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography style={{ fontWeight: 700 }}>LIVE</Typography>
            </Stack>
          </div>
          <CancelPresentationIcon
            style={{ cursor: "pointer" }}
            onClick={closeAppMetrics}
          />
        </div>
      </div>

      <h4 style={{ textAlign: "center" }}>
        Last updated at: {updateTime.toString()} &nbsp;
        <Button
          variant={"contained"}
          onClick={() => {
            getAPIData(true);
          }}
          style={{ backgroundColor: "gold", color: "black" }}
        >
          Refresh
        </Button>
      </h4>

      <hr />
      <div
        style={{
          height: "3000px",
          width: "100%",
          position: "absolute",
          zIndex: "5",
          backgroundColor: "#fffffffa",
          backdropFilter: "blur(5px)",
          display: loading,
        }}
      >
        <h1 style={{ textAlign: "center" }}>LOADING...</h1>
      </div>
      <br />
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              width: "100%",
            },
          }}
        >
          <Paper sx={{ height: "auto", backgroundColor: "#d0cece" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4 style={{ margin: "5px", textAlign: "center" }}>
                  Your client metrics
                </h4>
              </Grid>
            </Grid>{" "}
          </Paper>
        </Box>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              // flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: "100%",
                height: 500,
              },
            }}
          >
            <Paper
              style={{
                padding: "20px",
                // backgroundColor: "#fafafa",
              }}
            >
              <h2 style={{ textAlign: "center" }}>
                Currently Logged In Users -{" "}
                {currentLoggedInUsers?.list_Users?.length}
              </h2>
              <h4 style={{ textAlign: "center" }}>
                Last updated at: {currentUsersUpdateTime.toString()} &nbsp;
                <Button
                  variant={"contained"}
                  onClick={() => {
                    let startDate = moment(addDays(new Date(), -30))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "currentLogin", true);
                  }}
                  style={{ backgroundColor: "gold", color: "black" }}
                >
                  Refresh
                </Button>
              </h4>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  overflow: "auto",
                  maxHeight: "325px",
                }}
              >
                {currentLoggedInUsers?.list_Users?.map((k) => {
                  return (
                    <p
                      key={k}
                      style={{
                        backgroundColor: "#04832a",
                        color: "white",
                        height: "40px",
                        width: "100px",
                        lineHeight: "40px",
                        textAlign: "center",
                        margin: "10px",
                        borderRadius: "5px",
                        boxShadow: "0 1px 2px 0 gray",
                      }}
                    >
                      {k}
                    </p>
                  );
                })}
              </div>
            </Paper>
          </Box>
          {/* {JSON.stringify(currentLoggedInUsers, null, 2)} */}
        </Grid>
        {/* <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              // flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: "100%",
                height: 500,
              },
            }}
          >
            <Paper
              style={{
                padding: "20px",
                // backgroundColor: "#fafafa",
              }}
            >
              <h2 style={{ textAlign: "center" }}>Active Clients on App</h2>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  justifyContent: "space-evenly",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  variant={"contained"}
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    marginRight: "10px",
                    width: "30%",
                  }}
                  onClick={() => {
                    let startDate = moment(addDays(new Date(), -1))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "userLog", true);
                    // console.log(startDate);
                  }}
                >
                  Last 24 hours
                </Button>
                <Button
                  variant={"contained"}
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    marginRight: "10px",

                    width: "30%",
                  }}
                  onClick={() => {
                    let startDate = moment(addDays(new Date(), -7))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "userLog", true);
                    // console.log(startDate);
                  }}
                >
                  Last 7 days
                </Button>
                <Button
                  variant={"contained"}
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    marginRight: "10px",

                    width: "30%",
                  }}
                  onClick={() => {
                    let startDate = moment(addDays(new Date(), -30))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "userLog", true);
                    // console.log(startDate);
                  }}
                >
                  Last 30 days
                </Button>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ minWidth: "100px" }}>
                  <TextField
                    name={"startDate"}
                    label="Start Date"
                    value={moment(dateRange1.startDate).format("YYYY-MM-DD")}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      ".MuiInputBase-input": { fontSize: "0.8rem" },
                      marginRight: "20px",
                    }}
                    type="date"
                    onChange={(e) => {
                      let obj = dateRange1;
                      obj.startDate = moment(e.target.value)
                        .format("DDMMMYYYY")
                        .toUpperCase();
                      setDateRange1(obj);
                      setUpdated((oldState) => {
                        return !oldState;
                      });
                    }}
                  />
                </FormControl>
                <FormControl sx={{ minWidth: "100px" }}>
                  <TextField
                    name={"endDate"}
                    label="End Date"
                    value={moment(dateRange1.endDate).format("YYYY-MM-DD")}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      ".MuiInputBase-input": { fontSize: "0.8rem" },
                    }}
                    type="date"
                    onChange={(e) => {
                      let obj = dateRange1;
                      obj.endDate = moment(e.target.value)
                        .format("DDMMMYYYY")
                        .toUpperCase();
                      setDateRange1(obj);
                      setUpdated((oldState) => {
                        return !oldState;
                      });
                    }}
                  />
                </FormControl>
                <Button
                  variant={"contained"}
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    marginRight: "10px",

                    width: "30%",
                  }}
                  onClick={() => {
                    if (
                      moment(dateRange1.endDate) < moment(dateRange1.startDate)
                    ) {
                      alert("Please select Start Date");
                    } else {
                      filterAPIData(
                        dateRange1.startDate,
                        "userLog",
                        true,
                        dateRange1.endDate
                      );
                    }
                    // console.log(startDate);
                  }}
                >
                  Submit
                </Button>
              </div>
              <br></br>
              <div style={{ height: "80%", width: "100%" }}>
                <DataGrid
                  style={{ height: "300px" }}
                  getRowId={(row) => row._id}
                  columns={[
                    {
                      field: "clientId",
                      headerName: "Client ID",
                      align: "center",
                      headerAlign: "center",
                      flex: 1,
                    },
                    {
                      field: "lastlogin",
                      headerName: "Last Login",
                      align: "center",
                      headerAlign: "center",
                      flex: 1,
                    },
                    {
                      field: "country",
                      headerName: "Country",
                      align: "center",
                      headerAlign: "center",
                      flex: 1,
                    },
                  ]}
                  rows={userList}
                  components={{
                    Toolbar: GridToolbar,
                  }}
                />
              </div>

              {/* {JSON.stringify(searchedProducts)} */}
        {/* </Paper>
          </Box>
        </Grid> */}
      </Grid>
      {/* <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              width: "100%",
            },
          }}
        >
          <Paper sx={{ height: "auto", backgroundColor: "#d0cece" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <h4 style={{ margin: "5px", textAlign: "center" }}>
                  All clients metrics
                </h4>
              </Grid>
            </Grid>{" "}
          </Paper>
        </Box>
      </Grid> */}
      {/* <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              // flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: "100%",
                height: 500,
              },
            }}
          >
            <Paper
              style={{
                padding: "20px",
                // backgroundColor: "#fafafa",
              }}
            >
              <h2 style={{ textAlign: "center" }}>Searched Instruments</h2>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  justifyContent: "space-evenly",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  variant={"contained"}
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    marginRight: "10px",
                    width: "30%",
                  }}
                  onClick={() => {
                    let startDate = moment(addDays(new Date(), -1))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "searched", true);
                    // console.log(startDate);
                  }}
                >
                  Last 24 hours
                </Button>
                <Button
                  variant={"contained"}
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    marginRight: "10px",

                    width: "30%",
                  }}
                  onClick={() => {
                    let startDate = moment(addDays(new Date(), -7))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "searched", true);
                    // console.log(startDate);
                  }}
                >
                  Last 7 days
                </Button>
                <Button
                  variant={"contained"}
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    marginRight: "10px",

                    width: "30%",
                  }}
                  onClick={() => {
                    let startDate = moment(addDays(new Date(), -30))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "searched", true);
                    // console.log(startDate);
                  }}
                >
                  Last 30 days
                </Button>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ minWidth: "100px" }}>
                  <TextField
                    name={"startDate"}
                    label="Start Date"
                    value={moment(dateRange1.startDate).format("YYYY-MM-DD")}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      ".MuiInputBase-input": { fontSize: "0.8rem" },
                      marginRight: "20px",
                    }}
                    type="date"
                    onChange={(e) => {
                      let obj = dateRange1;
                      obj.startDate = moment(e.target.value)
                        .format("DDMMMYYYY")
                        .toUpperCase();
                      setDateRange1(obj);
                      setUpdated((oldState) => {
                        return !oldState;
                      });
                    }}
                  />
                </FormControl>
                <FormControl sx={{ minWidth: "100px" }}>
                  <TextField
                    name={"endDate"}
                    label="End Date"
                    value={moment(dateRange1.endDate).format("YYYY-MM-DD")}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      ".MuiInputBase-input": { fontSize: "0.8rem" },
                    }}
                    type="date"
                    onChange={(e) => {
                      let obj = dateRange1;
                      obj.endDate = moment(e.target.value)
                        .format("DDMMMYYYY")
                        .toUpperCase();
                      setDateRange1(obj);
                      setUpdated((oldState) => {
                        return !oldState;
                      });
                    }}
                  />
                </FormControl>
                <Button
                  variant={"contained"}
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    marginRight: "10px",

                    width: "30%",
                  }}
                  onClick={() => {
                    if (
                      moment(dateRange1.endDate) < moment(dateRange1.startDate)
                    ) {
                      alert("Please select Start Date");
                    } else {
                      filterAPIData(
                        dateRange1.startDate,
                        "searched",
                        true,
                        dateRange1.endDate
                      );
                    }
                    // console.log(startDate);
                  }}
                >
                  Submit
                </Button>
              </div>
              <br></br>
              <div style={{ height: "80%", width: "100%" }}>
                <BubbleChartComponent2
                  sx={{ height: "100px" }}
                  data={searchedProducts}
                  xField={"Product"}
                  yField={"Hits"}
                />
              </div>
              {/* <DataGrid
                    style={{ height: "300px" }}
                    getRowId={(row) => row.Product}
                    columns={[
                      { field: "Product", flex: 1 },
                      { field: "Hits", flex: 1 },
                    ]}
                    rows={searchedProducts}
                    //   components={{
                    //     Toolbar: GridToolbar,
                    //   }}
                  /> }
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            sx={{
              display: "flex",
              // flexWrap: "wrap",
              "& > :not(style)": {
                m: 1,
                width: "100%",
                height: 500,
              },
            }}
          >
            <Paper
              style={{
                padding: "20px",
                // backgroundColor: "#fafafa",
              }}
            >
              <h2 style={{ textAlign: "center" }}>Traded Instruments</h2>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  justifyContent: "space-evenly",
                  alignItems: "flex-end",
                }}
              >
                <Button
                  variant={"contained"}
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    marginRight: "10px",
                    width: "30%",
                  }}
                  onClick={() => {
                    let startDate = moment(addDays(new Date(), -1))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "traded", true);
                    // console.log(startDate);
                  }}
                >
                  Last 24 hours
                </Button>
                <Button
                  variant={"contained"}
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    marginRight: "10px",

                    width: "30%",
                  }}
                  onClick={() => {
                    let startDate = moment(addDays(new Date(), -7))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "traded", true);
                    // console.log(startDate);
                  }}
                >
                  Last 7 days
                </Button>
                <Button
                  variant={"contained"}
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    marginRight: "10px",

                    width: "30%",
                  }}
                  onClick={() => {
                    let startDate = moment(addDays(new Date(), -30))
                      .format("DDMMMYYYY")
                      .toUpperCase();
                    filterAPIData(startDate, "traded", true);
                    // console.log(startDate);
                  }}
                >
                  Last 30 days
                </Button>
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ minWidth: "100px" }}>
                  <TextField
                    name={"startDate"}
                    label="Start Date"
                    value={moment(dateRange1.startDate).format("YYYY-MM-DD")}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      ".MuiInputBase-input": { fontSize: "0.8rem" },
                      marginRight: "20px",
                    }}
                    type="date"
                    onChange={(e) => {
                      let obj = dateRange1;
                      obj.startDate = moment(e.target.value)
                        .format("DDMMMYYYY")
                        .toUpperCase();
                      setDateRange1(obj);
                      setUpdated((oldState) => {
                        return !oldState;
                      });
                    }}
                  />
                </FormControl>
                <FormControl sx={{ minWidth: "100px" }}>
                  <TextField
                    name={"endDate"}
                    label="End Date"
                    value={moment(dateRange1.endDate).format("YYYY-MM-DD")}
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      ".MuiInputBase-input": { fontSize: "0.8rem" },
                    }}
                    type="date"
                    onChange={(e) => {
                      let obj = dateRange1;
                      obj.endDate = moment(e.target.value)
                        .format("DDMMMYYYY")
                        .toUpperCase();
                      setDateRange1(obj);
                      setUpdated((oldState) => {
                        return !oldState;
                      });
                    }}
                  />
                </FormControl>
                <Button
                  variant={"contained"}
                  style={{
                    backgroundColor: "gold",
                    color: "black",
                    marginRight: "10px",

                    width: "30%",
                  }}
                  onClick={() => {
                    if (
                      moment(dateRange1.endDate) < moment(dateRange1.startDate)
                    ) {
                      alert("Please select Start Date");
                    } else {
                      filterAPIData(
                        dateRange1.startDate,
                        "traded",
                        true,
                        dateRange1.endDate
                      );
                    }
                    // console.log(startDate);
                  }}
                >
                  Submit
                </Button>
              </div>
              <div style={{ height: "80%", width: "100%", marginTop: "20px" }}>
                {/* <BubbleChartComponent
                      sx={{ height: "100px" }}
                      data={searchedProducts}
                      xField={"Product"}
                      yField={"Hits"}
                    /> */}
      {/* <TreeMapComponent
                      data={tradedProducts?.asset}
                      colorField={"name"}
                    /> }
                <div
                  className="mainTrades"
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <div className="tradeContainer">
                    {tradedProducts?.instrustments.map((k) => {
                      return (
                        <div
                          key={k}
                          style={{
                            backgroundColor: "#111",
                            boxShadow: "0 1px 2px 10 rgba(0,0,0,0.75)",
                            color: "white",
                          }}
                          variant={"contained"}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <p>{k}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Paper>
          </Box>
        </Grid>
      </Grid>  */}
    </div>
  );
}
