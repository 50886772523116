import React from 'react';
import ReactDOM from 'react-dom';
import { RadialBar } from '@ant-design/plots';

const moment = require('moment');
const StackedRadialBar = (props) => {
  let { data, xField, yField, colorField } = props;


  const config = {
    data,
    onReady: (plot) => {
      plot.on('element:click', (...args) => {
        console.log(args[0]);
      })
    },

    xField: xField,
    yField: yField,
    colorField: colorField,
    isStack: true,
    maxAngle: 270,
    innerRadius: 0.2,
    xAxis: {
      label: {
        formatter: (label) => {
          return label = moment(label).format("MMMM-YYYY");
        }
      }
    },
    legend: {

      layout: 'horizontal',
      position: 'bottom',
    },
    color: ["gold", "#000", "#f7ed7e"],


  };



  return <RadialBar {...config} />;
};

export default StackedRadialBar;
