import React, { useState, useRef, useEffect, useContext } from "react";
import { Space, Tooltip } from "antd";

import shortid from "shortid";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export default function CoreSpreadColumn(props) {
  const { core_spread, symbol, benchmark, sr_no, setErrors } = props;
  const [isError, setIsError] = useState(false);
  //const [elementId, setElementId] = useState(shortid.generate());
  const [elementId, setElementId] = useState(
    core_spread + "" + sr_no + "_core_spread"
  );

  useEffect(() => {
    try {
      if (!core_spread) {
        //  setErrors(old => [...old, elementId]);
        setIsError(true);
      } else {
      }
    } catch (e) {
      setIsError(true);
    }
  }, []);

  return (
    <>
      {isError && (
        <Tooltip title={"Core Spread can't be empty"}>
          <div id={elementId} style={{ paddingLeft: 4 }}>
            <Space>
              <span style={{ color: "red" }}>{core_spread}</span>
              <ReportProblemIcon fontSize="small" style={{ color: "gold" }} />
            </Space>
          </div>
        </Tooltip>
      )}
      {!isError && (
        <div id={elementId} style={{ paddingLeft: 4 }}>
          <span style={{ color: "black" }}>{core_spread}</span>
        </div>
      )}
    </>
  );
}
