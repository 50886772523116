import { Bar } from '@ant-design/charts';
const CustomBarChart = (props) => {
    const { data } = props;
    const config = {
        title: {
            visible: true,
            text: 'Basic Bar Chart',
        },
        xAxis: {
            grid: { line: { style: 0 } },
        },
        description: {
            visible: true,
            text: 'Add conversion rate label graphics between the graphics of the basic bar chart\uFF0CUsers want to pay attention to the proportion of data changes from top to bottom',
        },
        // style: { fill: 'red' },
        // color: ['#19CDD7', '#DDB27C'],
        forceFit: true,
        label: {
            formatter: ({ value }) => {
                return value.toLocaleString();
            },
            position: "middle",
            style: { opacity: 1, fill: "black" },
        },
        data: data,
        xField: 'value',
        yField: 'symbolName',
        color: "#ffd700"
        // conversionTag: { visible: true },
    };
    return < Bar {...config} />;
}
export default CustomBarChart;