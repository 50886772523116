import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import { Tooltip } from "antd";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ProfileMasterUpload from "./profile_master_upload";
import CommissionMasterUpload from "./commission_master_upload";
import TransactionExecutionReport from "./execution_history_component/transactionExecutionHistory";
import TransactionExecutionPage from "./execution_history_component/executionHistoryPage";
import { JSEncrypt } from "jsencrypt";
import { Buffer } from "buffer";
import { PublicClientApplication } from "@azure/msal-browser";
import { config } from "../../helpers/config";
import { CHECK_ALLOWED_USERS, GET_PUBLIC_KEY } from "../../helpers/apiStrings";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import { useState, useRef, useContext, useEffect } from "react";
import { message } from "antd";
import { GET_UPLOADED_COMMISSION_MASTERS } from "../../helpers/adminApiStrings";
import axios from "axios";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const steps = ["Profile Master", "Commision Group Master", "Execution History"];
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "white",
  color: "black",
  display: "flex",
}));
export default function DashboardMasterFileUploader() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const [messageApi, contextHolder] = message.useMessage();
  const [isProfileFileUpladed, setProfileFileUploaded] = useState(false);
  const [isCommissionFileUpladed, setCommissionFileUploaded] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [platformType, setPlatformType] = useState("CFC");
  const [displayPlatformToggle, setDisplayPlatformToggle] = useState(false);
  const [txnHistoryLoaded, setTxnHistoryLoaded] = useState(true);
  const [txnLogLoaded, setTxnLogLoaded] = useState(true);
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#04832a" : "#04832a",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  function getPublicKey() {
    setIsLoading(true);
    axios
      .get(GET_PUBLIC_KEY)
      .then((res) => {
        setIsLoading(false);
        const d = Buffer.from(res.data, "base64").toString("ascii");
        encrypt.setPublicKey(d);

        let accounts = publicClientApplication.getAllAccounts();
        if (accounts.length == 0) {
          console.log("Not Signed in");
          navigate("/tu-file-uploader");
        } else {
          checkAuthorization(accounts[0].username, accounts[0].name);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e.message);
      });
  }
  function checkAuthorization(userEmail, name) {
    setIsLoading(true);
    const enc = encrypt.encrypt(JSON.stringify({ userType: "TFU", userEmail }));
    axios
      .post(CHECK_ALLOWED_USERS, { payload: enc })
      .then((res) => {
        setName(name);
        setAuthenticated(true);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setName(name);
        setAuthenticated(false);
      });
  }
  useEffect(() => {
    setDisplayPlatformToggle(false);
    setTxnHistoryLoaded(true);
    setTxnLogLoaded(true);
    // getPublicKey();
  }, []);

  const uploadSuccess = (uploadProfileStatus, uploadCommissionStatus) => {
    if (uploadProfileStatus === "Profile Master File uploaded successfully") {
      setProfileFileUploaded(true);
    } else if (
      uploadCommissionStatus === "Commission Master File uploaded successfully"
    ) {
      setCommissionFileUploaded(true);
    } else {
      setProfileFileUploaded(false);
      setCommissionFileUploaded(false);
    }
  };
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (activeStep == 0) {
      checkTodaysFileAndMissingfiles();
    }
    if (activeStep == 1) {
      setDisplayPlatformToggle(true);
    }
    if (activeStep == steps.length - 1) {
      window.location.reload();
    }
  };

  const togglePlatformType = () => {
    if (platformType == "TU") setPlatformType("CFC");
    else setPlatformType("TU");
  };

  function checkTodaysFileAndMissingfiles() {
    axios
      .get(GET_UPLOADED_COMMISSION_MASTERS)
      .then((res) => {
        if (res.data.length > 0) {
          let missingDate = "";
          var startDate = moment().toDate();
          var endDate = moment().toDate();
          let upladedFilesDateArray = [];
          if (true) {
            for (let i = 0; i < res.data.length; i++) {
              if (!res.data[i].originalName.includes("_")) {
                // alert(`File name format is incorrect`);
              } else {
                let dateStartIndex =
                  res.data[i].originalName.lastIndexOf("_") + 1;
                let fileName = res.data[i].originalName;
                let nameWithoutExtension = fileName.split(".")[0];
                let fileDateString = nameWithoutExtension.substring(
                  dateStartIndex,
                  nameWithoutExtension.length
                );
                var fileDate = moment(fileDateString, "DD-MM-YYYY").toDate();
                upladedFilesDateArray.push(
                  moment(fileDate).format("DD MM YYYY")
                );
                if (i == res.data.length - 1) {
                  startDate = fileDate;
                }
              }
            }
          }
          while (
            moment(startDate).format("DD MM YYYY") <
            moment(endDate).format("DD MM YYYY")
          ) {
            var newDate = startDate.setDate(startDate.getDate() + 1);
            startDate = moment(newDate).toDate();
            if (
              !upladedFilesDateArray.includes(
                startDate.getDate().toString().padStart(2, "0") +
                " " +
                (startDate.getMonth() + 1).toString().padStart(2, "0") +
                " " +
                startDate.getFullYear()
              )
            ) {
              if (startDate.getDay() != 6) {
                missingDate =
                  missingDate +
                  "Commmission group master file is Pending for (" +
                  moment(startDate).format("DD MM YYYY") +
                  ")\n\n";
              }
            }
          }
          if (missingDate != "") {
            // alert(missingDate);
          }
        } else {
          console.log("No data uploaded to commission group master");
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const encrypt = new JSEncrypt();

  const publicClientApplication = new PublicClientApplication({
    auth: {
      clientId: config.appId,
      redirectUri: config.redirectUri,
      authority: config.authority,
      postLogoutRedirectUri: config.postLogoutRedirectUri,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
  });
  function logout() {
    try {
      localStorage.clear();
      sessionStorage.clear();
      (function () {
        var cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
          var d = window.location.hostname.split(".");
          while (d.length > 0) {
            var cookieBase =
              encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
              "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
              d.join(".") +
              " ;path=";
            var p = window.location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
              document.cookie = cookieBase + p.join("/");
              p.pop();
            }
            d.shift();
          }
        }
      })();
      window.location.reload();
    } catch (e) {
      console.log(e.message);
    }
  }
  return (
    <Box sx={{ width: "100%", mt: 1 }}>
      <Item
        sx={{
          display: "flex",
          padding: "1% 2%",
        }}
      >
        <Stepper activeStep={activeStep} sx={{ width: "100%", flex: 1 }}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <React.Fragment sx={{ width: "100%", flex: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="contained"
              sx={{
                mr: 1,
                ml: 5,
                backgroundColor: "#ffd700",
                color: "black",
                width: "75px",
              }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              color="inherit"
              onClick={handleNext}
              variant="contained"
              sx={{
                mr: 5,
                backgroundColor: "#ffd700",
                color: "black",
                width: "75px",
              }}
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
            <Button
              onClick={logout}
              style={{
                backgroundColor: "black",
                color: "white",
                float: "right",
              }}
              variant="contained"
            >
              Logout
            </Button>
            {displayPlatformToggle && (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                pl={"15px"}
              >
                <Typography style={{ fontWeight: 700 }}>CENTURY</Typography>
                <Tooltip title={txnHistoryLoaded && txnHistoryLoaded ? "Data is Loading" : ""}>
                  <span><AntSwitch
                    disabled={txnHistoryLoaded && txnHistoryLoaded}
                    checked={platformType == "TU" ? true : false}
                    onChange={(checked) => {
                      togglePlatformType();
                    }}
                    inputProps={{ "aria-label": "ant design" }}
                  /></span>
                </Tooltip>
                <Typography style={{ fontWeight: 700 }}>TU</Typography>
              </Stack>
            )}
          </Box>
        </React.Fragment>
      </Item>
      <Typography>
        {activeStep + 1 == 1 ? (
          <Item sx={{ mt: 2 }}>
            <ProfileMasterUpload
              sx={{ width: "100" }}
              uploadSuccess={uploadSuccess}
              setDisplayPlatformToggle={setDisplayPlatformToggle}
            />
          </Item>
        ) : activeStep + 1 == 2 ? (
          <Item sx={{ mt: 2 }}>
            <CommissionMasterUpload
              sx={{ width: "100%" }}
              uploadSuccess={uploadSuccess}
              setDisplayPlatformToggle={setDisplayPlatformToggle}
            />
          </Item>
        ) : (
          <TransactionExecutionPage platformType={platformType} setTxnHistoryLoaded={setTxnHistoryLoaded} setTxnLogLoaded={setTxnLogLoaded} />
        )}
      </Typography>
    </Box>
  );
}
