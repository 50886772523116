import "./App.css";
import { BrowserRouter, Route, Routes, Redirect } from "react-router-dom";
import Navbar from "./components/global/footer-component";
import Footer from "./components/global/navbar-component";
import Home from "./pages/user/home-component";
import { useState } from "react";
import Login from "./components/login-component";
import MgmtHome from "./pages/management/home-component";
import NewMgmtHome from "./pages/management_dashboard_revamp/home-component";
import AdminLogin from "./pages/management/login-component";
import AccountLogin from "./pages/accounts/accountsLogin";
import ClientOperationsLogin from "./pages/clientOperations/login";
import ClientOperationsHome from "./pages/clientOperations";
import LeanClientOperations from "./pages/leanClientOperations/leanHomeComponent";
import { getToken } from "./helpers/authentication";
import Trial from "./pages/trial";
import CenturyManagementLogin from "./pages/centuryManagementDashboard/login";
import CenturyManagementHome from "./pages/centuryManagementDashboard";
import FileUploaderADLogin from "./pages/file_uploader/fileUploaderADLogin";
import DashboardMasterFileUploader from "./pages/file_uploader/home-component";
import TrialRMAppMetrics from "./pages/TrialRMAppMetrics";
import AccountADLogin from "./pages/accounts/accountADLogin";
import AdminRevampLogin from "./pages/management_dashboard_revamp/login-component";

function App() {
  const [userId, setUserId] = useState(false);
  return (
    <div className="App">
      <div>
        <BrowserRouter>
          {/* <Navbar userId={[userId]} /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            {/* {!localStorage.getItem("token") && <Redirect to="/login"/>} */}
            <Route path="/login" element={<Login />} />
            <Route path="/admin" element={<MgmtHome />} />
            <Route path="/adminrevamp" element={<NewMgmtHome />} />
            <Route path="/admin/login" element={<AdminLogin />} />
            <Route path="/adminrevamp/login" element={<AdminRevampLogin />} />
            <Route path="/account" element={<AccountADLogin />} />
            <Route
              path="/tu-file-uploader/home"
              element={<DashboardMasterFileUploader />}
            />
            <Route
              path="/account/home"
              element={
                <LeanClientOperations source="accounts" header="false" />
              }
            />
            <Route
              path="/clientOperations"
              element={<ClientOperationsLogin />}
            />
            {/* <Route
              path="/leanHomeComponent"
              element={<LeanClientOperations header="true" topMargin = "null"/>}
            /> */}
            <Route
              path="/clientOperations/home"
              element={<ClientOperationsHome />}
            />
            <Route
              path="/centuryManagement"
              element={<CenturyManagementLogin />}
            />
            <Route
              path="/centuryManagement/home"
              element={<CenturyManagementHome />}
            />
            <Route path="/tu-file-uploader" element={<FileUploaderADLogin />} />
            <Route path="/trialRMAppMetrics" element={<TrialRMAppMetrics />} />
          </Routes>
          {/* <Footer /> */}
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
