import React, { useState, useEffect } from "react";
import { DualAxes } from "@ant-design/plots";
const moment = require("moment");

function getFormattedStatValue(value) {
  let val = null;

  if (value < 1000) {
    val = value
  } else if (value >= 1000 && value < 1000000) {
    val = Math.round(value / 1000).toLocaleString() + "K";
  } else if (value >= 1000000) {
    val = Math.round(value / 1000000).toLocaleString() + "M";

  }
  return val;
}


const BarLineChartComponent2 = (props) => {
  let { data, xField, yFieldLine = "", yFieldBar } = props;
  // data = [
  //     {
  //         time: '2019-03',
  //         value: 350,
  //         count: 800,
  //     },
  //     {
  //         time: '2019-04',
  //         value: 900,
  //         count: 600,
  //     },
  //     {
  //         time: '2019-05',
  //         value: 300,
  //         count: 400,
  //     },
  //     {
  //         time: '2019-06',
  //         value: 450,
  //         count: 380,
  //     },
  //     {
  //         time: '2019-07',
  //         value: 470,
  //         count: 220,
  //     },
  // ];
  const config = {
    data: [data, data],
    onReady: (plot) => {
      plot.on("element:click", (...args) => {
        console.log(args);
      });
    },
    xField: xField,
    yField: [yFieldBar, yFieldLine],
    yAxis: {
      [yFieldBar]: {
        title: {
          text: "Deposit Amount",
        },
        grid: { line: { style: 0 } },
        label: {
          formatter: (label) => {
            return (label = (label / 1000).toLocaleString() + "K");
          },
        },
      },
      [yFieldLine]: {
        title: {
          text: "Count of Deposits",
        },
      },
    },
    xAxis: {
      title: {
        text: "Months",
      },
      label: {
        formatter: (label) => {
          return moment(label).format("MMM-YY");
        },
        autoRotate: true,
      },
    },

    geometryOptions: [
      {
        geometry: "column",
        // color: '#2d094b',
        color: "#111",
        columnWidthRatio: 0.4,
        label: {
          formatter: (value) => {
            value.deposits = Math.round(value.deposits * 100) / 100;
          },
          formatter: ({ deposits }) => {
            return Math.round(deposits / 1000).toLocaleString() + "K";
          },
          position: "top",
          style: { opacity: 1, fill: "black" },
        },
      },
      {
        geometry: "line",
        smooth: true,
        color: "gold",
        label: {
          position: "bottom",
          style: { opacity: 1, fill: "gold" },
        },
      },
    ],
    interactions: [
      {
        type: "element-highlight",
      },
    ],
    scrollbar: {
      type: "horizontal",
    },
    legend: {
      layout: "vertical",
      position: "right",
    },
   
    tooltip: {
    
      title:(value)=>new moment(value).format("MMMM YYYY"),

customItems: (originalItems) => {
  // process originalItems,
  originalItems[0].name = "Deposits";
  originalItems[0].value = getFormattedStatValue(originalItems[0].value);
  originalItems[1].name = "Deposit Count";
  return originalItems;
},
     
     
    }
  };
  return <DualAxes {...config} />;
};

export default BarLineChartComponent2;
