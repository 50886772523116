import React, { useState, useRef, useEffect } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import axios from "axios";
import LiveAppMetricsComponent from "../../components/app-metrics-component";
import {
  AUTHENTICATE_CLIENT_OPERATIONS,
  CHECK_ALLOWED_USERS,
  GET_PUBLIC_KEY,
} from "../../helpers/apiStrings";
import { useNavigate } from "react-router-dom";
import { Result } from "antd";
import { config } from "../../helpers/config";
import { JSEncrypt } from "jsencrypt";
import { Buffer } from "buffer";
import { PublicClientApplication } from "@azure/msal-browser";
///
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import LeanHomeComponent from "../leanClientOperations/leanHomeComponent";

const encrypt = new JSEncrypt();

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUri,
    authority: config.authority,
    postLogoutRedirectUri: config.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
});

///drawer const
const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `0px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function ClientOperationsHome(props) {
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [switchcomponent, setSwitchcomponent] = useState(""); //Trading App Metrics

  function getPublicKey() {
    setIsLoading(true);
    axios
      .get(GET_PUBLIC_KEY)
      .then((res) => {
        setIsLoading(false);
        const d = Buffer.from(res.data, "base64").toString("ascii");
        encrypt.setPublicKey(d);

        let accounts = publicClientApplication.getAllAccounts();
        if (accounts.length == 0) {
          console.log("Not Signed in");
          navigate("/clientOperations");
        } else {
          checkAuthorization(accounts[0].username, accounts[0].name);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e.message);
      });
  }

  function checkAuthorization(userEmail, name) {
    //CAU = century admin users
    setIsLoading(true);
    const enc = encrypt.encrypt(JSON.stringify({ userType: "COU", userEmail }));
    axios
      .post(CHECK_ALLOWED_USERS, { payload: enc })
      .then((res) => {
        setName(name);
        setAuthenticated(true);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setName(name);

        setAuthenticated(false);
      });
  }

  useEffect(() => {
    document.title = "Client Operations | Dashboard";
    getPublicKey();
  }, []);

  function logout() {
    try {
      localStorage.clear();
      sessionStorage.clear();
      (function () {
        var cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
          var d = window.location.hostname.split(".");
          while (d.length > 0) {
            var cookieBase =
              encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
              "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
              d.join(".") +
              " ;path=";
            var p = window.location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
              document.cookie = cookieBase + p.join("/");
              p.pop();
            }
            d.shift();
          }
        }
      })();
      window.location.reload();
    } catch (e) {
      console.log(e.message);
    }
  }
  ///Drawer inner const
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const SwitchPage = (text) => {
    setSwitchcomponent(text);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{ backgroundColor: "#fdd700" }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Toolbar>
            <MenuIcon
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }), color: "black" }}
            />
            <Typography
              variant="h6"
              noWrap
              component="div"
              style={{ color: "black" }}
            >
              Client Operation's Dashboard
            </Typography>
          </Toolbar>

          <div style={{ padding: "10px", display: "flex" }}>
            <Button
              onClick={logout}
              style={{
                backgroundColor: "black",
                color: "white",
                float: "right",
                marginRight: "50px",
              }}
              variant="contained"
            >
              Logout
            </Button>
            <h3
              style={{
                float: "right",
                marginRight: "20px",
              }}
            >
              {name}
            </h3>
          </div>
        </div>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {["Trading App Metrics", "Lean Payment Dashboard"].map(
            (text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton
                  onClick={(event) => {
                    SwitchPage(text);
                  }}
                >
                  {/* <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon> */}
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
        {/* <Divider />
        <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <Main open={open}>
        <DrawerHeader />

        {switchcomponent == "Trading App Metrics" ? (
          <>
            <div>
              {isLoading && (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress disableShrink />
                </Box>
              )}
              {!isLoading && authenticated && (
                <LiveAppMetricsComponent isCancellable={false} />
              )}

              {!isLoading && !authenticated && (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    padding: "5px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Result
                    status="403"
                    title="401"
                    subTitle="Sorry, you are not authorized to access this page."
                  />
                </Box>
              )}
            </div>
          </>
        ) : (
          <>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                padding: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LeanHomeComponent topMargin="20" source="clientOperations" />
            </Box>
          </>
        )}
      </Main>
    </Box>
  );
}
