import React, { useState, useRef, useContext, useEffect } from "react";
import { Box, Grid, CircularProgress } from "@mui/material";

import { List, message, Skeleton, Avatar, Space, Divider, Empty } from "antd";

import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import ImportResult from "./ImportResult/index.js";
import CSVReader from "react-csv-reader";
import TableSkeleton from "./tableSkeleton";
import {
  FETCH_COMMISSION_MASTER,
  UPLOAD_COMMISSION_MASTER,
} from "../../../helpers/adminApiStrings";
import axios from "axios";
import moment from "moment";
import { usePapaParse } from "react-papaparse";
import UploadHistory from "./uploadHistory.js";

export default function CommissionMasterUpload(props) {
  const [messageApi, contextHolder] = message.useMessage();
  const { readRemoteFile } = usePapaParse();

  const [importedRows, setImportedRows] = useState([]);
  const [filename, setFilename] = useState("");
  const [isImporting, setIsImporting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [lastUploadedFile, setLastUploadedFile] = useState(null);
  const [calledFrom, setCalledFrom] = useState("uploadFile");
  const [scrollHeight, setScrollHeight] = useState(400);

  const [errors, setErrors] = useState({
    tempErrors: [],
    duplicateGroupObject: {},
  });
  const [changes, setChanges] = useState({}); //changes made in new while compared to last one
  const errorIndex = useRef(0);
  const [resultType, setResultType] = useState(null); // whether file is imported and opened from History
  const [uploadedFiles, setUploadedFiles] = useState([]);

  function onImport(data, fileInfo) {
    try {
      if (lastUploadedFile == null) {
        setIsImporting(true);
        setErrors(getErrors(data));
        setImportedRows(data);
        setFilename(fileInfo.name);
        setResultType("imported");
        setIsImporting(false);
      } else {
        readRemoteFile(
          FETCH_COMMISSION_MASTER + "/" + lastUploadedFile.uploadedName,
          {
            complete: (results) => {
              let oldData = results.data;
              let temp = {};
              let tempChanges = {};

              // creating JSON map for change comparison

              for (let i = 0; i < oldData.length; i++) {
                temp[oldData[i].group_name] = oldData[i];
              }

              // creating JSON map for change comparison
              for (let i = 0; i < data.length; i++) {
                let key = data[i].group_name;

                if (temp.hasOwnProperty(key)) {
                  tempChanges[key] = { old: {}, current: {} };

                  if (temp[key].platform_type !== data[i].platform_type) {
                    tempChanges[key].old["platform_type"] =
                      temp[key].platform_type;
                    tempChanges[key].current["platform_type"] =
                      data[i].platform_type;
                  }
                  if (temp[key].group_name !== data[i].group_name) {
                    tempChanges[key].old["group_name"] = temp[key].group_name;
                    tempChanges[key].current["group_name"] = data[i].group_name;
                  }

                  if (temp[key].currency !== data[i].currency) {
                    tempChanges[key].old["currency"] = temp[key].currency;
                    tempChanges[key].current["currency"] = data[i].currency;
                  }

                  if (temp[key].profile !== data[i].profile) {
                    tempChanges[key].old["profile"] = temp[key].profile;
                    tempChanges[key].current["profile"] = data[i].profile;
                  }
                  if (temp[key].profile_clone !== data[i].profile_clone) {
                    tempChanges[key].old["profile_clone"] =
                      temp[key].profile_clone;
                    tempChanges[key].current["profile_clone"] =
                      data[i].profile_clone;
                  }
                  if (temp[key].commision !== data[i].commision) {
                    tempChanges[key].old["commision"] = temp[key].commision;
                    tempChanges[key].current["commision"] = data[i].commision;
                  }

                  if (temp[key].rt !== data[i].rt) {
                    tempChanges[key].old["rt"] = temp[key].rt;
                    tempChanges[key].current["rt"] = data[i].rt;
                  }

                  if (temp[key].final !== data[i].final) {
                    tempChanges[key].old["final"] = temp[key].final;
                    tempChanges[key].current["final"] = data[i].final;
                  }

                  if (temp[key].swap !== data[i].swap) {
                    tempChanges[key].old["swap"] = temp[key].swap;
                    tempChanges[key].current["swap"] = data[i].swap;
                  }
                  if (
                    Object.keys(tempChanges[key].old).length == 0 &&
                    Object.keys(tempChanges[key].current).length == 0
                  ) {
                    delete tempChanges[key];
                  }
                } else {
                  tempChanges[key] = {
                    current: data[i],
                    new: true,
                    old: {
                      group_name: "",
                      currency: "",
                      profile: "",
                      commision: "",
                      rt: "",
                      final: "",
                      swap: "",
                    },
                  };
                }
              }
              setCalledFrom("uploadFile");
              setIsImporting(true);
              setErrors(getErrors(data));
              setChanges(tempChanges);
              setImportedRows(data);
              setFilename(fileInfo.name);
              setIsImporting(false);
            },
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: (header) =>
              header.toLowerCase().replace(/\W/g, "_"),
          }
        );
      }
    } catch (e) {
      console.log("Import Failed: ", e.message);
      messageApi.open({
        type: "error",
        content: "Invalid file. Could not import",
      });
    }
  }

  function uploadFile() {
    setIsUploading(true);
    var formData = new FormData();

    formData.append("commissionMaster", fileToUpload);
    formData.append("changes", JSON.stringify(changes));
    formData.append("uploadedBy", localStorage.getItem("username"));
    axios
      .post(UPLOAD_COMMISSION_MASTER, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLastUploadedFile(uploadedFiles.length > 0 ? uploadedFiles[0] : null);
        setUploadedFiles((old) => {
          return [res.data.uploadStatus, ...old];
        });
        setImportedRows([]);
        setChanges({});
        setErrors({ tempErrors: [], duplicateGroupObject: {} });
        errorIndex.current = 0;
        setFileToUpload(null);
        messageApi.open({
          type: "success",
          content: "File uploaded successfully",
        });
        setIsUploading(false);
        props.uploadSuccess("", "Commission Master File uploaded successfully");
      })
      .catch((e) => {
        setIsUploading(false);
        console.log("Upload error :", e.message);
        messageApi.open({
          type: "error",
          content: "Could not upload file. Something went wrong.",
        });
      });
  }

  const height = {
    lg: "85vh",
    xl: "85vh",
    md: "85vh",
    sm: "auto",
    xs: "auto",
  };

  useEffect(() => {
    document.title = "Files Master Upload";
    props.setDisplayPlatformToggle(false);
    document
      .getElementById("importDocument")
      .addEventListener("change", function (e) {
        setIsImporting(true);
        setFileToUpload(e.target.files[0]);
        setResultType("imported");
        setImportedRows([]);
        setChanges({});
        setErrors({ tempErrors: [], duplicateGroupObject: {} });
        errorIndex.current = 0;
      });
  }, []);

  function viewUploadedCommissionGroupMaster(
    uploadedName,
    originalName,
    uploadedChanges = {}
  ) {
    setResultType("uploaded");
    setIsImporting(true);
    setFilename(uploadedName);
    setImportedRows([]);
    setChanges(uploadedChanges);
    setErrors({ tempErrors: [], duplicateGroupObject: {} });
    errorIndex.current = 0;

    readRemoteFile(FETCH_COMMISSION_MASTER + "/" + uploadedName, {
      complete: (results) => {
        setIsImporting(false);
        setCalledFrom("viewFile");
        setImportedRows(results.data);
      },
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
    });
  }

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        overflow: "hidden",
        backgroundColor: "white",
        height: "89vh",
        width: "100%",
      }}
    >
      {contextHolder}

      <Box
        sx={{
          borderBottom: "1px solid",
          borderColor: "divider",
          height: "10vh",
          mt: 2,
          mb: 0,
        }}
      >
        <Button
          size="large"
          variant="contained"
          onClick={(e) => {
            document.getElementById("importDocument").click();
          }}
        >
          <div hidden>
            <CSVReader
              cssClass="react-csv-input"
              onFileLoaded={onImport}
              onError={(error) => {
                console.log("Import Failed: ", error.message);
                messageApi.open({
                  type: "error",
                  content: "Invalid file. Could not import",
                });
              }}
              parserOptions={{
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
                transformHeader: (header) =>
                  header.toLowerCase().replace(/\W/g, "_"),
              }}
              inputId="importDocument"
            />
          </div>
          Import File
        </Button>
      </Box>

      <Box sx={{ flex: 1 }}>
        <Grid container>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
            <Box sx={{ height: height, position: "relative" }}>
              {!isImporting && importedRows.length === 0 && (
                <Box
                  sx={{
                    textAlign: "center",
                    fontSize: {
                      lg: 56,
                      xl: 56,
                      md: 56,
                      sm: 30,
                      xs: 30,
                    },
                    color: "#dbdbdb",
                    fontWeight: "bold",
                    padding: {
                      lg: 16,
                      xl: 16,
                      md: 16,
                      sm: 4,
                      xs: 4,
                    },
                  }}
                >
                  Import a file to see the results here
                </Box>
              )}
              {isImporting && (
                <>
                  <TableSkeleton
                    scrollHeight={scrollHeight}
                    resultType={resultType}
                  />
                </>
              )}
              {importedRows.length != 0 && (
                <ImportResult
                  importedRows={importedRows}
                  isImporting={isImporting}
                  filename={filename}
                  uploadFile={uploadFile}
                  errors={calledFrom == "viewFile" ? [] : errors.tempErrors}
                  setErrors={setErrors}
                  errorIndex={errorIndex}
                  resultType={resultType}
                  changes={changes}
                  duplicateGroupObject={errors.duplicateGroupObject}
                />
              )}

              {isUploading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: "rgba(256,256,256,0.6)",
                    zIndex: 100,
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 4,
                  }}
                >
                  <CircularProgress disableShrink size={150} />
                  <Box
                    sx={{
                      fontSize: {
                        lg: 56,
                        xl: 56,
                        md: 56,
                        sm: 30,
                        xs: 30,
                      },
                      color: "#dbdbdb",
                    }}
                  >
                    Uploading file...
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <UploadHistory
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              selectedFilename={filename}
              onViewFile={viewUploadedCommissionGroupMaster}
              setLastUploadedFile={setLastUploadedFile}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

function getErrors(data) {
  let tempErrors = [];
  let duplicateGroupObject = {};
  let concatenationArray = [];
  for (let i = 0; i < data.length; i++) {
    let sr_no = data[i].sr_no;

    // Platform Type Validation
    try {
      if (data[i].platform_type.toString().trim().length === 0) {
        tempErrors.push(`${data[i].platform_type}${sr_no}_platformtype`);
      }
      if (
        data[i].platform_type.toString() == "CFC" ||
        data[i].platform_type.toString() == "TU"
      ) {
      } else {
        tempErrors.push(`${data[i].platform_type}${sr_no}_platformtype`);
      }
    } catch (e) {
      tempErrors.push(`${data[i].platform_type}${sr_no}_platformtype`);
    }
    // Group Name Validation
    try {
      if (data[i].group_name.toString().trim().length === 0) {
        tempErrors.push(`${data[i].group_name}${sr_no}_groupname`);
      }
    } catch (e) {
      tempErrors.push(`${data[i].group_name}${sr_no}_groupname`);
    }
    // Currency Validation
    try {
      if (data[i].currency.toString().trim().length === 0) {
        tempErrors.push(`${data[i].currency}${sr_no}_currency`);
      }
    } catch (e) {
      tempErrors.push(`${data[i].currency}${sr_no}_currency`);
    }
    // Profile Validation
    try {
      if (data[i].profile.toString().trim().length === 0) {
        tempErrors.push(`${data[i].profile}${sr_no}_profile`);
      } else if (!data[i].profile.toString().includes("P")) {
        tempErrors.push(`${data[i].profile}${sr_no}_profile`);
      }
    } catch (e) {
      tempErrors.push(`${data[i].profile}${sr_no}_profile`);
    }
    // Profile Clone Validation
    try {
      if (data[i].profile_clone.toString().trim().length === 0) {
        tempErrors.push(`${data[i].profile_clone}${sr_no}_profile_clone`);
      } else if (!data[i].profile_clone.toString().includes("P")) {
        tempErrors.push(`${data[i].profile_clone}${sr_no}_profile_clone`);
      }
    } catch (e) {
      tempErrors.push(`${data[i].profile_clone}${sr_no}_profile_clone`);
    }
    // Commission Validation
    try {
      if (data[i].commision.toString().trim().length === 0) {
        tempErrors.push(`${data[i].commision}${sr_no}_commission`);
      } else if (!data[i].commision.toString().includes("C")) {
        tempErrors.push(`${data[i].commision}${sr_no}_commission`);
      }
    } catch (e) {
      tempErrors.push(`${data[i].commision}${sr_no}_commission`);
    }
    // // RT Validation
    // try {
    //   if (data[i].rt.toString().trim().length === 0) {
    //     tempErrors.push(`${data[i].rt}_rt`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].rt}_rt`);
    // }

    // // Final Validation
    // try {
    //   if (data[i].final.toString().trim().length === 0) {
    //     tempErrors.push(`${data[i].final}_final`);
    //   }
    // } catch (e) {
    //   tempErrors.push(`${data[i].final}_final`);
    // }

    // SWAP Validation
    try {
      if (data[i].swap.toString().trim().length === 0) {
        tempErrors.push(`${data[i].swap}${sr_no}_swap`);
      } else if (!data[i].swap.toString().includes("S")) {
        tempErrors.push(`${data[i].swap}${sr_no}_swap`);
      }
    } catch (e) {
      tempErrors.push(`${data[i].swap}${sr_no}_swap`);
    }

    //Check Duplicates after concatinating groupName and currency
    try {
      let gname = data[i].group_name?.toString().trim();
      let curr = data[i].currency?.toString().trim();
      let final = data[i].final?.toString().trim();
      let platformType = data[i].platform_type?.toString().trim();
      let concatenation = platformType + curr + gname + final;
      if (concatenationArray.includes(concatenation)) {
        if (!Object.keys(duplicateGroupObject).includes(i)) {
          duplicateGroupObject[i] = true;
        }
        tempErrors.push(`${data[i].group_name}${sr_no}_groupname`);
      } else {
        duplicateGroupObject[i] = false;
        concatenationArray.push(concatenation);
      }
    } catch (e) {
      console.log(e.message);
      tempErrors.push(`${data[i].group_name}${sr_no}_groupname`);
    }
  }
  return {
    tempErrors: tempErrors,
    duplicateGroupObject: duplicateGroupObject,
  };
}
