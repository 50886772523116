import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Popover,
  TableRow,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  Typography
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { JSEncrypt } from "jsencrypt";
import React, { useEffect, useRef, useState } from "react";
import StatCard from "../../components/global/statCard-component";
import { Buffer } from "buffer";
import { CSVLink, CSVDownload } from "react-csv";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import {
  GET_PUBLIC_KEY, CHECK_ALLOWED_USERS
} from "../../helpers/apiStrings";
import { useNavigate, useSearchParams } from "react-router-dom";
import { config } from "../../helpers/config";
import { PublicClientApplication } from "@azure/msal-browser";
import {
  BASE_URL,
  GET_ALL_TRANSACTION_STATISTICS,
  GET_ALL_TRANSACTION_STAT_MASTER
} from "../../helpers/adminApiStrings";

import { Bar, Line } from '@ant-design/charts';
import CustomBarChart from "./components/customBarChart";
import CustomLineChart from "./components/customLineChart";
import { Divider } from "antd";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "black",
  display: "flex",
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#04832a" : "#04832a",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));


const statCardGridSize = { xl: 3, lg: 3, md: 4, sm: 6, xs: 12 };
const encrypt = new JSEncrypt();

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUri,
    authority: config.authority,
    postLogoutRedirectUri: config.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
});
export default function NewMgmtHome(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [txnData, setTxnData] = useState();
  const [txnDataMaster, setTxnMasterData] = useState();
  const [user, setUser] = useState();
  const [barChartValues, setBarChartValues] = useState([]);
  const [lineChartValues, setLineChartValues] = useState([]);
  const [symbolArray, setSymbolArray] = useState([]);
  const [addedSymbolArray, setAddedSymbolArray] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [applySymbolFilter, setApplySymbolFilter] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [name, setName] = useState("");
  const [defaultValueSymbolFilter, setDefaultValueSymbolFilter] = useState("All");
  const [lineChartValuesForSymbol, setLineChartValuesForSymbol] = useState([]);
  const [selectedMode, setSelectedMode] = useState("By Count");
  const [LineDataByForSymbol, setLineDataByForSymbol] = useState("Weekly");
  const [LineDataBy, setLineDataBy] = useState("Weekly");
  const [barDataBy, setGraphDataBy] = useState("Last Week");
  const [cachedData, setCachedData] = useState({});
  const filterValues = useRef({
    client: "",
    symbol: "",
    startDate: "",
    endDate: "",
  });

  const [showLoader, setShowLoader] = useState("block");
  const [platformType, setPlatformType] = useState("CFC");
  function checkAuthorization(userEmail, name) {
    //CAU = century admin users
    setIsLoading(true);
    const enc = encrypt.encrypt(JSON.stringify({ userType: "CAU", userEmail }));
    axios
      .post(CHECK_ALLOWED_USERS, { payload: enc })
      .then((res) => {
        setName(name);
        setAuthenticated(true);
        setIsLoading(false);
        getTxns();
      })
      .catch((e) => {
        setIsLoading(false);
        setName(name);

        setAuthenticated(false);
      });
  }
  function getPublicKey() {
    setIsLoading(true);
    axios
      .get(GET_PUBLIC_KEY)
      .then((res) => {
        setIsLoading(false);
        const d = Buffer.from(res.data, "base64").toString("ascii");
        encrypt.setPublicKey(d);
        let accounts = publicClientApplication.getAllAccounts();
        if (accounts.length == 0) {
          console.log("Not Signed in");
          navigate("/adminrevamp/login");
        } else {
          checkAuthorization(accounts[0].username, accounts[0].name);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e.message);
      });
  }
  useEffect(() => {
    getTxns()
  }, [platformType, barDataBy, LineDataBy, LineDataByForSymbol, addedSymbolArray, applySymbolFilter]);
  useEffect(() => {
    // getPublicKey();
  }, [platformType]);
  const switchDataDisplay = (stringParam) => {
    if (stringParam == "By Distinct User") {
      setSelectedMode(stringParam)
      setLineChartValues(txnDataMaster.tradeByPlatformByDistinctUser)
      setBarChartValues(txnData.topTradedSymbolByDistinctUser)
      setLineChartValuesForSymbol(txnData.tradeBySymbolByDistinctUser)
    }
    else if (stringParam == "By Volume") {
      setSelectedMode(stringParam)
      setLineChartValues(txnDataMaster.tradeByPlatformByTradeVol)
      setBarChartValues(txnData.topTradedSymbolVol)
      setLineChartValuesForSymbol(txnData.tradeBySymbolByVol)
    }
    else {
      setSelectedMode(stringParam)
      setLineChartValues(txnDataMaster.tradeByPlatformByTradeCount)
      setBarChartValues(txnData.topTradedSymbolCount)
      setLineChartValuesForSymbol(txnData.tradeBySymbolByCount)
    }
  }
  const switchGraphData = (stringParam) => {
    if (stringParam == "Last Week") {
      setGraphDataBy(stringParam)
    }
    else if (stringParam == "Last Month") {
      setGraphDataBy(stringParam)
    }
    else {
      setGraphDataBy(stringParam)
    }
  }
  const switchLineDataForsymbol = (stringParam) => {
    if (stringParam == "Weekly") {
      setLineDataByForSymbol(stringParam)
    }
    else if (stringParam == "Monthly") {
      setLineDataByForSymbol(stringParam)
    }
    else if (stringParam == "Yearly") {
      setLineDataByForSymbol(stringParam)
    }
    else {
      setLineDataByForSymbol(stringParam)
    }
  }
  const switchLineData = (stringParam) => {
    if (stringParam == "Weekly") {
      setLineDataBy(stringParam)
    }
    else if (stringParam == "Monthly") {
      setLineDataBy(stringParam)
    }
    else if (stringParam == "Yearly") {
      setLineDataBy(stringParam)
    }
    else if (stringParam == "Quarterly") {
      setLineDataBy(stringParam)
    }
  }


  const getTxns = () => {
    const cacheKey = JSON.stringify(filterValues.current);
    setShowLoader("block");
    axios
      .post(BASE_URL + "/" + platformType + GET_ALL_TRANSACTION_STATISTICS, {
        barDataBy, LineDataByForSymbol, addedSymbolArray
      })
      .then((res) => {
        axios
          .post(BASE_URL + "/" + platformType + GET_ALL_TRANSACTION_STAT_MASTER, {
            LineDataBy
          })
          .then((resp) => {
            setShowLoader("none")
            setCachedData((oldState) => {
              return { ...oldState, [cacheKey]: res.data };
            });
            setTxnData(res.data[0]);
            setTxnMasterData(resp.data[0]);
            setSymbolArray(res.data[0].totalSymbols)
            if (selectedMode == "By Count") {
              setLineChartValues(resp.data[0].tradeByPlatformByTradeCount)
              setBarChartValues(res.data[0].topTradedSymbolCount)
              setLineChartValuesForSymbol(res.data[0].tradeBySymbolByCount)
            }
            else if (selectedMode == "By Distinct User") {
              setLineChartValues(resp.data[0].tradeByPlatformByDistinctUser)
              setBarChartValues(res.data[0].topTradedSymbolByDistinctUser)
              setLineChartValuesForSymbol(res.data[0].tradeBySymbolByDistinctUser)
            }
            else {
              setLineChartValues(resp.data[0].tradeByPlatformByTradeVol)
              setBarChartValues(res.data[0].topTradedSymbolVol)
              setLineChartValuesForSymbol(res.data[0].tradeBySymbolByVol)
            }
          }).catch((err) => {
            setShowLoader("none");

          });

      })
      .catch((err) => {
        setShowLoader("none");

      });
  };


  const togglePlatformType = () => {
    filterValues.current.startDate = "";
    filterValues.current.endDate = "";
    filterValues.current.client = "";
    filterValues.current.symbol = "";
    if (platformType == "TU") {
      setCachedData({});
      setPlatformType("CFC");
    } else {
      setCachedData({});
      setPlatformType("TU");
    }
  };

  function logout() {
    try {
      localStorage.clear();
      sessionStorage.clear();
      (function () {
        var cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
          var d = window.location.hostname.split(".");
          while (d.length > 0) {
            var cookieBase =
              encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
              "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
              d.join(".") +
              " ;path=";
            var p = window.location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
              document.cookie = cookieBase + p.join("/");
              p.pop();
            }
            d.shift();
          }
        }
      })();
      //window.location = "https://upro.century.ae";
      window.location.reload();
    } catch (e) {
      console.log(e.message);
    }
  }
  let fileNames = {
    "Most Traded Symbols": `Most Traded Symbols_${barDataBy}_${platformType} By ${selectedMode}`,
    "Platform wise trade": `Platform wise trade_${LineDataBy}_${platformType} By ${selectedMode}`,
    "Filtered Traded Symbols": `Filtered Traded_Symbols ${LineDataByForSymbol}_${platformType} By ${selectedMode}`
  }
  return (
    <div className="container">
      <div
        style={{
          height: "120vh",
          width: "100vw",
          position: "fixed",
          zIndex: "5",
          backgroundColor: "#fffffffa",
          backdropFilter: "blur(5px)",
          display: showLoader,
        }}
      >
        <h1>LOADING...</h1>
      </div>
      {txnData ? <>
        <Dialog open={dialogOpen}>
          <DialogTitle>{"Added Symbol for Search"}</DialogTitle>
          <DialogContent>
            {addedSymbolArray.map(name => (
              <li>
                {name}
              </li>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setAddedSymbolArray([]); setDialogOpen(false); setDefaultValueSymbolFilter("All") }}>Clear Selection</Button>
            <Button onClick={() => setDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              m="auto"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                  width: "90%",
                  minHeight: "50px",
                  padding: "0 5%",
                },
              }}
            >
              <Paper sx={{ height: "auto", backgroundColor: "gold" }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <h1 align="left">
                      {platformType == "TU" ? "Tradeultra " : "Century "} -
                      Management Dashboard{" "}
                    </h1>
                  </Grid>
                  <Grid item xs={6}>
                    <div
                      align="right"
                      style={{
                        float: "right",
                        height: "90px",
                        lineHeight: "90px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <h4
                        style={{
                          margin: "0",
                          marginRight: "10px",
                          float: "left",
                        }}
                      >
                        Welcome {user && user.name}
                      </h4>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "black",
                          height: "32px",
                          margin: "auto",
                        }}
                        onClick={() => {
                          logout();
                        }}
                      >
                        Logout
                      </Button>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        pl={"15px"}
                      >
                        <Typography style={{ fontWeight: 700 }}>
                          CENTURY
                        </Typography>
                        <AntSwitch
                          checked={platformType == "TU" ? true : false}
                          onChange={(checked) => {
                            togglePlatformType();
                          }}
                          inputProps={{ "aria-label": "ant design" }}
                        />
                        <Typography style={{ fontWeight: 700 }}>
                          TU
                        </Typography>
                      </Stack>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
          <Grid item {...statCardGridSize}>
            <StatCard
              value={txnData.totalTrades}
              heading={"Total Trades"}
              infoKey={"Total trades"}
            />
          </Grid>
          <Grid item {...statCardGridSize}>
            <StatCard
              value={txnData.totalTradedSymbols}
              heading={"Total traded Symbols"}
              infoKey={"Total traded Symbols"}
            />
          </Grid>
          <Grid item {...statCardGridSize}>
            <StatCard
              value={txnData.totalClients}
              heading={"Total Clients"}
              infoKey={"Total Clients"}
            />
          </Grid>
          <Grid item {...statCardGridSize}>
            <StatCard
              value={txnData.mostTradedSymbol}
              heading={"Top Traded Symbol"}
              infoKey={"Top Traded Symbol"}
            />
          </Grid >
        </Grid >
        <Divider></Divider>
        <div style={{ padding: "5px", textAlign: "left" }}>
          <CustomButton selectedMode={selectedMode} displayString={"By Count"} customFunction={() => { switchDataDisplay("By Count") }} />
          <CustomButton selectedMode={selectedMode} displayString={"By Volume"} customFunction={() => { switchDataDisplay("By Volume") }} />
          <CustomButton selectedMode={selectedMode} displayString={"By Distinct User"} customFunction={() => { switchDataDisplay("By Distinct User") }} />
        </div>
        {selectedMode && <Grid
          container
          style={{ backgroundColor: "transparent", width: "100%" }} spacing={3}>
          <Grid item xs={12}>
            <Paper sx={{ height: "auto", backgroundColor: "transparent", mt: 2 }}>
              <Item
                style={{
                  backgroundColor: "white",
                  fontWeight: "bold",
                  display: "flex",
                  flexFlow: "column",
                  p: 2
                }}
              >
                <div style={{ padding: "20px", textAlign: "right" }}>
                  <CustomButton selectedMode={LineDataBy} displayString={"Weekly"} customFunction={() => { switchLineData("Weekly") }} />
                  <CustomButton selectedMode={LineDataBy} displayString={"Monthly"} customFunction={() => { switchLineData("Monthly") }} />
                  <CustomButton selectedMode={LineDataBy} displayString={"Quarterly"} customFunction={() => { switchLineData("Quarterly") }} />
                  <CustomButton selectedMode={LineDataBy} displayString={"Yearly"} customFunction={() => { switchLineData("Yearly") }} />
                  <span style={{ float: "right", marginTop: "0px" }}>
                    <CSVLink
                      filename={fileNames["Platform wise trade"]}
                      data={lineChartValues || []}
                    >
                      <SystemUpdateAltIcon sx={{ color: "black" }} />
                    </CSVLink>
                  </span>
                </div>
                <Grid item xs={12} p={2}>
                  {txnDataMaster && (<CustomLineChart data={lineChartValues} />)}
                </Grid>
              </Item>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper sx={{ height: "auto", backgroundColor: "transparent", mt: 2, }}>
              <Item
                style={{
                  backgroundColor: "white",
                  fontWeight: "bold",
                  display: "flex",
                  flexFlow: "column",
                  p: 2
                }}
              >
                <div style={{ padding: "20px", textAlign: "right" }}>
                  <CustomButton selectedMode={barDataBy} displayString={"Last Week"} customFunction={() => { switchGraphData("Last Week") }} />
                  <CustomButton selectedMode={barDataBy} displayString={"Last Month"} customFunction={() => { switchGraphData("Last Month") }} />
                  <CustomButton selectedMode={barDataBy} displayString={"Last Year"} customFunction={() => { switchGraphData("Last Year") }} />
                  <span style={{ float: "right", marginTop: "0px" }}>
                    <CSVLink
                      filename={fileNames["Most Traded Symbols"]}
                      data={barChartValues || []}
                    >
                      <SystemUpdateAltIcon sx={{ color: "black" }} />
                    </CSVLink>
                  </span>
                </div>
                <Grid item xs={12} p={3.75}>
                  {txnData && (<CustomBarChart data={barChartValues} />)}
                </Grid>
              </Item>
            </Paper>
          </Grid>

          <Grid item xs={6}>
            <Paper sx={{ height: "auto", backgroundColor: "transparent", mt: 2 }}>
              <Item
                style={{
                  backgroundColor: "white",
                  fontWeight: "bold",
                  display: "flex",
                  flexFlow: "column",
                  p: 2
                }}
              >

                <div style={{ padding: "20px", textAlign: "right", display: "flex", justifyContent: "space-between" }}>
                  <Grid item lg={6} xl={6} md={2} sm={6} xs={6} sx={{ backgroundColor: "transparent", textAlign: "left" }}>
                    <FormControl sx={{ width: "30%", height: "20px !important" }}>
                      <Autocomplete
                        disablePortal
                        size="small"
                        defaultValue={defaultValueSymbolFilter}
                        onChange={(e, option) => {
                          setDefaultValueSymbolFilter(option)
                          if (option == "All") {
                            setAddedSymbolArray([])
                          }
                          else if (!addedSymbolArray.includes(option)) {
                            addedSymbolArray.push(option)
                            // let temp = []
                            // temp.push(option)
                            // setAddedSymbolArray(temp)
                          }
                        }}
                        options={["All"].concat(symbolArray)}
                        renderInput={(params) => (
                          <TextField {...params} label="Symbol" />
                        )}
                      />
                    </FormControl>
                    {"   "}
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#ffd700",
                        color: "black",
                        height: "32px",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setDialogOpen(true)
                      }}
                    >
                      {"Show Added Symbols"}
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        height: "32px",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setApplySymbolFilter(!applySymbolFilter)
                      }}
                    >
                      {"Apply"}
                    </Button>
                  </Grid>
                  <Grid>
                    <CustomButton selectedMode={LineDataByForSymbol} displayString={"Weekly"} customFunction={() => { switchLineDataForsymbol("Weekly") }} />
                    <CustomButton selectedMode={LineDataByForSymbol} displayString={"Monthly"} customFunction={() => { switchLineDataForsymbol("Monthly") }} />
                    <CustomButton selectedMode={LineDataByForSymbol} displayString={"Quarterly"} customFunction={() => { switchLineDataForsymbol("Quarterly") }} />
                    <CustomButton selectedMode={LineDataByForSymbol} displayString={"Yearly"} customFunction={() => { switchLineDataForsymbol("Yearly") }} />
                  </Grid>

                </div>
                <span style={{ float: "right", marginRight: "24px", textAlign: "right" }}>
                  <CSVLink
                    filename={fileNames["Filtered Traded Symbols"]}
                    data={lineChartValuesForSymbol || []}
                  >
                    <SystemUpdateAltIcon sx={{ color: "black" }} />
                  </CSVLink>
                </span>
                <Grid item xs={12} p={2}>
                  {txnData && (<CustomLineChart data={lineChartValuesForSymbol} />)}
                </Grid>
              </Item>
            </Paper>
          </Grid>
        </Grid>}

      </> : <NoDataElement />}
    </div >
  );
}

function CustomButton(props) {
  const { selectedMode, customFunction, displayString } = props;
  return <Button
    variant="contained"
    style={{
      backgroundColor: selectedMode == displayString ? "#ffd700" : "black",
      color: selectedMode == displayString ? "black" : "white",
      height: "32px",
      marginRight: "10px",
    }}
    onClick={() => {
      customFunction()
    }}
  >
    {displayString}
  </Button>
}
function NoDataElement() {
  return (
    <>
      <br />
      <br />
      <br />
      <h2 style={{ color: "goldenrod" }}>No Data Available</h2>
    </>
  );
}

function InfoPopover(props) {
  const { content, color = "black" } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton aria-describedby={id} size="small" onClick={handleClick}>
        <InfoOutlinedIcon size="small" sx={{ color }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>{content}</Typography>
      </Popover>
    </>
  );
}
