import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/plots";
const moment = require("moment");
const BarChartComponent2 = (props) => {
  let { data, xField, yField } = props;


  let sortedDataByDate = data.sort((a, b) =>new moment(a.time).diff(new moment(b.time)))


  const config = {
    data: data,
    xField: xField,
    yField: yField,
    color: "gold",

    columnWidthRatio: 0.5,
    label: {
      formatter: (value) => {
        value.avgRevenue = Math.round(value.avgRevenue * 100) / 100;
      },
      formatter: ({ avgRevenue }) => {
        return Math.round(avgRevenue).toLocaleString() + "";
      },
      position: "top",
      style: { opacity: 1, fill: "black" },
    },
    yAxis: {
      grid: { line: { style: 0 } },
      title: {
        text: "Revenue",
      },
      label: {
        formatter: (label) => {
          return (label = (label / 1000).toLocaleString() + "K");
        },
      },
      count: false,
    },
    content: function content(item) {
      return "".concat(item.y.toFixed(2), "%");
    },
    xAxis: {
      label: {
        formatter: (label) => {
          return (label = moment(label).format("MMMM-YYYY"));
        },
        autoRotate: false,
      },
    },
    interactions: [
      {
        type: "element-highlight",
      },
    ],
    scrollbar: {
      type: "horizontal",
    },
    /*
    tooltip: {
    
      title:(value)=>new moment(value).format("MMMM YYYY"),

customItems: (originalItems) => {
  // process originalItems,
  originalItems[0].name = "Deposits";
  originalItems[0].value = Math.round(originalItems[0].value / 1000).toLocaleString() + "K"
  originalItems[1].name = "Deposit Count";
  return originalItems;
},
     
     
    }
    */
   tooltip:{
    title:(value)=>new moment(value).format("MMMM YYYY"),
    customItems:(originalItems)=>{
      originalItems[0].name = "Average Revenue";
      originalItems[0].value = Math.round(originalItems[0].value * 100) / 100;
      return originalItems;
    }
  }
  };
  return <Column {...config} />;
};

export default BarChartComponent2;
