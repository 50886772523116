import React, { useState, useRef, useEffect, useContext } from "react";
import { Space, Tooltip } from "antd";

import shortid from "shortid";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export default function SymbolColumn(props) {
  const { symbol, sr_no, setErrors, duplicateSymbolObject } = props;
  const [isError, setIsError] = useState(false);
  const [errorTitle, setErrorTitle] = useState();
  const [elementId, setElementId] = useState(symbol + ""+ sr_no + "_symbol");
  useEffect(() => {
    try {
      if (typeof symbol == "object") {
        setIsError(true);
        setErrorTitle("Symbol can't be empty");
      } else if (symbol.toString().trim().length === 0) {
        setIsError(true);
        setErrorTitle("Symbol can't be empty");
      }
      if (duplicateSymbolObject[sr_no - 1] == true) {
        setIsError(true);
        setErrorTitle("Duplicate symbol");
      }
    } catch (e) {
      // setErrors(old => [...old, elementId]);
      setIsError(true);
    }
  }, []);

  return (
    <>
      {isError && (
        <Tooltip title={errorTitle}>
          <div id={elementId} style={{ paddingLeft: 4 }}>
            <Space>
              <span style={{ color: "red" }}>{symbol}</span>
              <ReportProblemIcon fontSize="small" style={{ color: "gold" }} />
            </Space>
          </div>
        </Tooltip>
      )}
      {!isError && (
        <div id={elementId} style={{ paddingLeft: 4 }}>
          <span style={{ color: "black" }}>{symbol}</span>
        </div>
      )}
    </>
  );
}
