import React, { useState, useRef, useContext, useEffect } from "react";
import { Box, Grid, CircularProgress } from "@mui/material";

import {
  List,
  message,
  Skeleton,
  Avatar,
  Space,
  Divider,
  Empty,
} from "antd";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import ImportResult from "./ImportResult/index.js";
import CSVReader from "react-csv-reader";
import TableSkeleton from "./tableSkeleton";
import {
  FETCH_PROFILE_MASTER,
  GET_UPLOADED_PROFILE_MASTERS,
  UPLOAD_PROFILE_MASTER,
} from "../../../helpers/adminApiStrings";
import axios from "axios";
import moment from "moment";
import { usePapaParse } from "react-papaparse";
import UploadHistory from "./uploadHistory.js";

export default function ProfileMasterUpload(props) {
  const [messageApi, contextHolder] = message.useMessage();
  const { readRemoteFile } = usePapaParse();

  const [importedRows, setImportedRows] = useState([]);
  const [filename, setFilename] = useState("");
  const [isImporting, setIsImporting] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [lastUploadedFile, setLastUploadedFile] = useState(null);
  const [scrollHeight, setScrollHeight] = useState(400);
  const [errors, setErrors] = useState({
    tempErrors: [],
    duplicateSymbolObject: {},
  });
  const [calledFrom, setCalledFrom] = useState("uploadFile");
  const [changes, setChanges] = useState({}); //changes made in new while compared to last one
  const errorIndex = useRef(0);
  const [resultType, setResultType] = useState(null); // whether file is imported and opened from History
  const [uploadedFiles, setUploadedFiles] = useState([]);
  function checkTodaysFileAndMissingfiles() {
    axios
      .get(GET_UPLOADED_PROFILE_MASTERS)
      .then((res) => {
        if (res.data.length > 0) {
          let missingDate = "";
          var startDate = moment().toDate();
          var endDate = moment().toDate();
          let upladedFilesDateArray = [];
          setUploadedFiles(res.data);
          setLastUploadedFile(res.data[0]);
          if (true) {
            for (let i = 0; i < res.data.length; i++) {
              if (!res.data[i].originalName.includes("_")) {
                // alert(`${res.data[i].originalName} File name format is incorrect`);
              } else {
                let dateStartIndex =
                  res.data[i].originalName.lastIndexOf("_") + 1;
                let fileName = res.data[i].originalName;
                let nameWithoutExtension = fileName.split(".")[0];
                let fileDateString = nameWithoutExtension.substring(
                  dateStartIndex,
                  nameWithoutExtension.length
                );
                var fileDate = moment(fileDateString, "DD-MM-YYYY").toDate();
                upladedFilesDateArray.push(
                  moment(fileDate).format("DD MM YYYY")
                );
                if (i == res.data.length - 1) {
                  startDate = fileDate;
                }
              }
            }
          }
          while (
            moment(startDate).format("DD MM YYYY") <
            moment(endDate).format("DD MM YYYY")
          ) {
            var newDate = startDate.setDate(startDate.getDate() + 1);
            startDate = moment(newDate).toDate();
            if (
              !upladedFilesDateArray.includes(
                startDate.getDate().toString().padStart(2, "0") +
                " " +
                (startDate.getMonth() + 1).toString().padStart(2, "0") +
                " " +
                startDate.getFullYear()
              )
            ) {
              if (startDate.getDay() != 6) {
                missingDate =
                  missingDate +
                  "Profile master File is Pending for (" +
                  moment(startDate).format("DD MM YYYY") +
                  ")\n\n";
              }
            }
          }
          if (missingDate != "") {
            // alert(missingDate);
          }
        } else {
          console.log("No data uploaded to profile master");
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }
  function onImport(data, fileInfo) {
    try {
      if (lastUploadedFile == null) {
        //the file in upload is null
        setIsImporting(true);
        setErrors(getErrors(data));
        setImportedRows(data);
        setFilename(fileInfo.name);
        setResultType("imported");
        setIsImporting(false);
      } else {
        readRemoteFile(
          FETCH_PROFILE_MASTER + "/" + lastUploadedFile.uploadedName,
          {
            complete: (results) => {
              let oldData = results.data;
              let temp = {};
              let tempChanges = {};

              // creating JSON map for change comparison

              for (let i = 0; i < oldData.length; i++) {
                temp[oldData[i].symbol] = oldData[i];
              }

              // creating JSON map for change comparison
              for (let i = 0; i < data.length; i++) {
                let key = data[i].symbol;

                if (temp.hasOwnProperty(key)) {
                  tempChanges[key] = { old: {}, current: {} };

                  if (temp[key].group !== data[i].group) {
                    tempChanges[key].old["group"] = temp[key].group;
                    tempChanges[key].current["group"] = data[i].group;
                  }

                  if (temp[key].lp !== data[i].lp) {
                    tempChanges[key].old["lp"] = temp[key].lp;
                    tempChanges[key].current["lp"] = data[i].lp;
                  }

                  if (Number(temp[key].digits) !== Number(data[i].digits)) {
                    tempChanges[key].old["digits"] = Number(temp[key].digits);
                    tempChanges[key].current["digits"] = Number(data[i].digits);
                  }

                  if (
                    Number(temp[key].benchmark) !== Number(data[i].benchmark)
                  ) {
                    tempChanges[key].old["benchmark"] = Number(
                      temp[key].benchmark
                    );
                    tempChanges[key].current["benchmark"] = Number(
                      data[i].benchmark
                    );
                  }

                  if (
                    Number(temp[key].core_spread) !==
                    Number(data[i].core_spread)
                  ) {
                    tempChanges[key].old["core_spread"] = Number(
                      temp[key].core_spread
                    );
                    tempChanges[key].current["core_spread"] = Number(
                      data[i].core_spread
                    );
                  }

                  if (
                    Object.keys(tempChanges[key].old).length == 0 &&
                    Object.keys(tempChanges[key].current).length == 0
                  ) {
                    delete tempChanges[key];
                  }
                } else {
                  tempChanges[key] = {
                    current: data[i],
                    new: true,
                    old: {
                      core_spread: "",
                      benchmark: "",
                      digits: "",
                      lp: "",
                      description: "",
                      symbol: "",
                    },
                  };
                }
              }

              setCalledFrom("uploadFile");
              setIsImporting(true);
              // checking for errors
              setErrors(getErrors(data));
              setChanges(tempChanges);
              setImportedRows(data);
              setFilename(fileInfo.name);
              setIsImporting(false);
            },
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: (header) =>
              header.toLowerCase().replace(/\W/g, "_"),
          }
        );
      }
    } catch (e) {
      console.log("Import Failed: ", e.message);
      messageApi.open({
        type: "error",
        content: "Invalid file. Could not import",
      });
    }
  }

  function uploadFile() {
    setIsUploading(true);
    var formData = new FormData();

    formData.append("profileMaster", fileToUpload);
    formData.append("changes", JSON.stringify(changes));
    formData.append("uploadedBy", localStorage.getItem("username"));
    axios
      .post(UPLOAD_PROFILE_MASTER, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setLastUploadedFile(uploadedFiles.length > 0 ? uploadedFiles[0] : null);
        setUploadedFiles((old) => {
          return [res.data, ...old];
        });
        setImportedRows([]);
        setChanges({});
        setErrors({ tempErrors: [], duplicateSymbolObject: {} });
        errorIndex.current = 0;
        setFileToUpload(null);
        messageApi.open({
          type: "success",
          content: "File uploaded successfully",
        });
        // props.uploadSuccess("Profile Master File uploaded successfully", "");
        setIsUploading(false);
      })
      .catch((e) => {
        setIsUploading(false);
        console.log("Upload error :", e.message);
        messageApi.open({
          type: "error",
          content: "Could not upload file. Something went wrong.",
        });
      });
  }

  const height = {
    lg: "85vh",
    xl: "85vh",
    md: "85vh",
    sm: "auto",
    xs: "auto",
  };

  useEffect(() => {
    document.title = "Files Master Upload";
    props.setDisplayPlatformToggle(false);
    document
      .getElementById("importDocument")
      .addEventListener("change", function (e) {
        setIsImporting(true);
        setFileToUpload(e.target.files[0]);
        setResultType("imported");
        setImportedRows([]);
        setChanges({});
        setErrors({ tempErrors: [], duplicateSymbolObject: {} });
        errorIndex.current = 0;
      });
    checkTodaysFileAndMissingfiles();
  }, []);

  function viewUploadedProfileMaster(
    uploadedName,
    originalName,
    uploadedChanges = {}
  ) {
    setResultType("uploaded");
    setIsImporting(true);
    setFilename(uploadedName);
    setImportedRows([]);
    setChanges(uploadedChanges);
    setErrors({ tempErrors: [], duplicateSymbolObject: {} });
    errorIndex.current = 0;

    readRemoteFile(FETCH_PROFILE_MASTER + "/" + uploadedName, {
      complete: (results) => {
        setIsImporting(false);
        setCalledFrom("viewFile");
        setImportedRows(results.data);
      },
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
    });
  }

  return (
    <Box
      sx={{
        boxSizing: "border-box",
        overflow: "hidden",
        backgroundColor: "white",
        height: "89vh",
        width: "100%"
      }}
    >
      {contextHolder}

      <Box
        sx={{
          borderBottom: "1px solid",
          borderColor: "divider",
          height: "10vh",
          mt: 2,
          mb: 0,
        }}
      >
        {/* <h2>Profile Master Upload</h2> */}
        <Button
          size="large"
          variant="contained"
          onClick={(e) => {
            document.getElementById("importDocument").click();
          }}
        >
          <div hidden>
            <CSVReader
              cssClass="react-csv-input"
              onFileLoaded={onImport}
              onError={(error) => {
                console.log("Import Failed: ", error.message);
                messageApi.open({
                  type: "error",
                  content: "Invalid file. Could not import",
                });
              }}
              parserOptions={{
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
                transformHeader: (header) =>
                  header.toLowerCase().replace(/\W/g, "_"),
              }}
              inputId="importDocument"
            />
          </div>
          Import File
        </Button>
      </Box>

      <Box sx={{ flex: 1 }}>
        <Grid container>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
            <Box sx={{ height: height, position: "relative" }}>
              {!isImporting && importedRows.length === 0 && (
                <Box
                  sx={{
                    textAlign: "center",
                    fontSize: {
                      lg: 56,
                      xl: 56,
                      md: 56,
                      sm: 30,
                      xs: 30,
                    },
                    color: "#dbdbdb",
                    fontWeight: "bold",
                    padding: {
                      lg: 16,
                      xl: 16,
                      md: 16,
                      sm: 4,
                      xs: 4,
                    },
                  }}
                >
                  Import a file to see the results here
                </Box>
              )}
              {isImporting && (
                <TableSkeleton scrollHeight={scrollHeight} resultType={resultType} />
              )}
              {importedRows.length != 0 && (
                <ImportResult
                  importedRows={importedRows}
                  isImporting={isImporting}
                  filename={filename}
                  uploadFile={uploadFile}
                  errors={calledFrom == "viewFile" ? [] : errors.tempErrors}
                  setErrors={setErrors}
                  errorIndex={errorIndex}
                  resultType={resultType}
                  changes={changes}
                  duplicateSymbolObject={errors.duplicateSymbolObject}
                />
              )}
              {isUploading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    backgroundColor: "rgba(256,256,256,0.6)",
                    zIndex: 100,
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 4,
                  }}
                >
                  <CircularProgress disableShrink size={150} />
                  <Box
                    sx={{
                      fontSize: {
                        lg: 56,
                        xl: 56,
                        md: 56,
                        sm: 30,
                        xs: 30,
                      },
                      color: "#dbdbdb",
                    }}
                  >
                    Uploading file...
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <UploadHistory
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              selectedFilename={filename}
              onViewFile={viewUploadedProfileMaster}
              setLastUploadedFile={setLastUploadedFile}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

function getErrors(data) {
  let tempErrors = [];
  let duplicateSymbolObject = {};
  let checkDuplicateSymbolArray = [];
  for (let i = 0; i < data.length; i++) {
    let symbol = data[i].symbol;
    let sr_no = data[i].sr_no;
    // Group Validation

    try {
      if (data[i].group.trim().length === 0) {
        tempErrors.push(`${data[i].group}${sr_no}_group`);
      }
    } catch (e) {
      tempErrors.push(`${data[i].group}${sr_no}_group`);
    }

    // Symbol Validation

    try {
      if (data[i].symbol.trim().length === 0) {
        tempErrors.push(`${symbol}${sr_no}_symbol`);
      }
    } catch (e) {
      tempErrors.push(`${symbol}${sr_no}_symbol`);
    }

    // LP Validation
    try {
      if (data[i].lp.trim().length === 0) {
        tempErrors.push(`${data[i].lp}${sr_no}_lp`);
      }
    } catch (e) {
      tempErrors.push(`${data[i].lp}${sr_no}_lp`);
    }

    //Digits Validation
    try {
      if (!typeof parseInt(data[i].digits) || parseInt(data[i].digits) === 0) {
        tempErrors.push(`${data[i].digits}${sr_no}_digits`);
      } else if (!data[i].digits) {
        tempErrors.push(`${data[i].digits}${sr_no}_digits`);
      }
    } catch (e) {
      tempErrors.push(`${data[i].digits}${sr_no}_digits`);
    }

    // Benchmark Validation
    try {
      if (
        !data[i].benchmark ||
        Number(data[i].core_spread) > Number(data[i].benchmark)
      ) {
        tempErrors.push(`${data[i].benchmark}${sr_no}_benchmark`);
      }
    } catch (e) {
      tempErrors.push(`${data[i].benchmark}${sr_no}_benchmark`);
    }

    // Core Spread Validation
    try {
      if (!data[i].core_spread) {
        tempErrors.push(`${data[i].core_spread}${sr_no}_core_spread`);
      } else {
      }
    } catch (e) {
      tempErrors.push(`${data[i].core_spread}${sr_no}_core_spread`);
    }

    try {
      if (checkDuplicateSymbolArray.includes(symbol)) {
        tempErrors.push(`${symbol}${sr_no}_symbol`);
        duplicateSymbolObject[i] = true;
      } else {
        duplicateSymbolObject[i] = false;
        checkDuplicateSymbolArray.push(symbol);
      }
    } catch (e) {
      tempErrors.push(`${symbol}${sr_no}_symbol`);
    }
  }
  return {
    tempErrors: tempErrors,
    duplicateSymbolObject: duplicateSymbolObject,
  };
}
