import * as React from "react";
import TransactionExecutionReport from "./transactionExecutionHistory";
import TransactionExecutionLogs from "./transactionExecutionLogs";
import { Box } from "@mui/material";

export default function (props) {
  const { platformType , setTxnHistoryLoaded ,  setTxnLogLoaded} = props;
  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <TransactionExecutionReport platformType={platformType} setTxnHistoryLoaded={setTxnHistoryLoaded}/>
        <TransactionExecutionLogs platformType={platformType} setTxnLogLoaded={setTxnLogLoaded}/>
      </Box>
    </>
  );
}
