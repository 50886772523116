import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  IconButton,
  Popover,
  Typography,
  TabPanel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tableCellClasses } from "@mui/material/TableCell";
import axios from "axios";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect, useRef } from "react";
import HeatMapComponent from "../../components/charts/heatmap";
import BarLineChartComponent from "../../components/charts/barlinechart";
import BarLineChartComponent2 from "../../components/charts/barlinechart2";
import AreaChartComponent from "../../components/charts/areachart";
import AreaChartComponentCFC from "../../components/charts/areachartCFC";
import StackedRadialBar from "../../components/charts/stackedRadialChart";
import TreeMapComponent from "../../components/charts/treeMapChart";
import ScatterPlotComponent from "../../components/charts/scatterPlot";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import {
  GET_MGMT_TRANSACTIONS,
  GET_ACTIVE_CLIENTS_LIST_MGMT,
  GET_MGMT_USER_CREDIT_MASTER,
  GET_MGMT_SCATTER_PLOT,
  GET_DISTINCT_CHART_DATA,
  GET_MONTH_WISE_TOP_CLIENTS_AND_SYMBOLS,
  BASE_URL,
} from "../../helpers/adminApiStrings";
import {
  AUTHENTICATE_CLIENT_OPERATIONS,
  CHECK_ALLOWED_USERS,
  GET_PUBLIC_KEY,
} from "../../helpers/apiStrings";
import { Buffer } from "buffer";
import decrypt from "../../helpers/uProDecrypter";
import { config } from "../../helpers/config";
import { PublicClientApplication } from "@azure/msal-browser";
import { JSEncrypt } from "jsencrypt";
import { CSVLink, CSVDownload } from "react-csv";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Autocomplete from "@mui/material/Autocomplete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import BidirectionalChartComponent from "../../components/charts/bidirectionalChart";
import BarChartComponent from "../../components/charts/barchart";
import BarChartComponent2 from "../../components/charts/barchart2";
import LiveAppMetricsComponent from "../../components/app-metrics-component";
import DashboardTooltipItems from "../../helpers/dashboardTooltipItems";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import StatCard from "../../components/global/statCard-component";

import moment from "moment";
import MonthlyTopClientAndSymbolDialog from "./monthlyTopClientAndSymbolDialog";
import DateRangeTimePicker from "../../components/global/dateRangeTimePicker";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#04832a" : "#04832a",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const statCardGridSize = { xl: 2, lg: 3, md: 4, sm: 6, xs: 12 };
const encrypt = new JSEncrypt();

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUri,
    authority: config.authority,
    postLogoutRedirectUri: config.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
});
export default function MgmtHome(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [name, setName] = useState("");
  const [txnData, setTxnData] = useState([]);
  const [heatMapData, setHeatMapData] = useState([]);
  const [statistics, setStatistics] = useState();
  const [sortedUserListByVol, setSortedUserListByVol] = useState();
  const [sortedUserListByTxn, setSortedUserListByTxn] = useState();
  const [sortedUserListByCommission, setSortedUserListByCommission] =
    useState();
  const [value, setValue] = React.useState(0);
  const [monthWiseTxnTotal, setMonthWiseTxnTotal] = useState();
  const [avgMonthWiseTxnTotal, setAvgMonthWiseTxnTotal] = useState();
  const [monthWiseCommissionSpread, setMonthWiseCommissionSpread] = useState();
  const [monthWiseActiveClients, setMonthWiseActiveClients] = useState([]);
  const [totAndActClients, setTotAndActiveClients] = useState([]);
  const [symbolArray, setSymbolArray] = useState([]);
  const [treeMapSymbolArray, setTreeMapSymbolArray] = useState();
  const [user, setUser] = useState();
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [cachedData, setCachedData] = useState({});
  const [clientBehaviourColorField, setClientBehaviourColorField] =
    useState("count");
  const [treeMapLoading, setTreeMapLoading] = useState(false);
  const [heatMapLoading, setHeatMapLoading] = useState(false);
  const filterValues = useRef({
    client: "",
    symbol: "",
    startDate: "",
    endDate: "",
  });

  const [clientList, setClientList] = useState([]);
  const [activeClientList, setActiveClientList] = useState();
  const [showLoader, setShowLoader] = useState("block");
  const [isChartDataGridOpen, setIsChartDataGridOpen] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [gridDataTitle, setGridDataTitle] = useState("");
  const [dataErrorMsg, setDataErrorMsg] = useState(false);
  const [lastUpdatedDate, setLastUpdatedDate] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [fundingTxns, setFundingTxns] = useState([]);
  const uproDataEncrypted =
    searchParams.get("d") ||
    "ZXlKT1lXMWxJam9pUVdocGMyZ2dVbUZxWlhOb0lFRm5jbUYzWVd3aUxDSkZiV0ZwYkNJNkltRm9hWE5vTG1GQVkyVnVkSFZ5ZVM1aFpTSXNJbFZ6WlhKSlJDSTZJams1T1NJc0lrRmpZMjkxYm5Seklqb2lJbjA9cXBhbHpt";
  const [fundingStatistics, setFundingStatistics] = useState([]);
  const [maskingState, setMaskingState] = useState(false);
  const [monthWiseFundingTotal, setMonthWiseFunding] = useState([]);
  const [scatterPlotData, setScatterPlotData] = useState([]);
  const [userCreditMaster, setUserCreditMaster] = useState([]);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [showAppMetrics, setShowAppMetrics] = useState(false);
  const [monthWiseAvgRevenuePerClient, setMonthWiseAvgRevenuePerClient] =
    useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [platformType, setPlatformType] = useState("CFC");
  const [dateRangeString, setDateRangeString] = useState(
    "--/--/----  -  --/--/----"
  );
  const [
    monthlyTopClientAndSymbolSelectedDate,
    setMonthlyTopClientAndSymbolSelectedDate,
  ] = useState("");
  const [
    monthWiseTopClientsAndSymbolsDialogVisible,
    setMonthWiseTopClientsAndSymbolsDialogVisible,
  ] = useState(false);
  const handleChartDataGridClose = () => {
    setIsChartDataGridOpen(false);
    setGridData([]);
  };
  // let paramString = platformType == "TU" ? "TU" : "CFC";
  function getPublicKey() {
    setIsLoading(true);
    axios
      .get(GET_PUBLIC_KEY)
      .then((res) => {
        setIsLoading(false);
        const d = Buffer.from(res.data, "base64").toString("ascii");
        encrypt.setPublicKey(d);

        let accounts = publicClientApplication.getAllAccounts();
        if (accounts.length == 0) {
          console.log("Not Signed in");
          navigate("/admin/login");
        } else {
          checkAuthorization(accounts[0].username, accounts[0].name);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e.message);
      });
  }

  function checkAuthorization(userEmail, name) {
    //CAU = century admin users
    setIsLoading(true);
    const enc = encrypt.encrypt(JSON.stringify({ userType: "CAU", userEmail }));
    axios
      .post(CHECK_ALLOWED_USERS, { payload: enc })
      .then((res) => {
        setName(name);
        setAuthenticated(true);
        setIsLoading(false);
        getTxns();
      })
      .catch((e) => {
        setIsLoading(false);
        setName(name);

        setAuthenticated(false);
      });
  }
  useEffect(() => {
    getPublicKey();
  }, [totalUserCount, platformType]);

  const ToggleAppMetrics = (bool) => {
    setShowAppMetrics(bool);
  };
  const setStateValues = (data) => {
    if (data == null) {
      setTxnData([]);
      setStatistics({
        txnVolume: 0,
        txnCount: 0,
        activeUserCount: 0,
        commissionTotal: 0,
        spreadTotal: 0,
      });
      setMonthWiseCommissionSpread([]);
      setHeatMapData([]);
      setTotAndActiveClients([]);
      setMonthWiseTxnTotal([]);
      setSortedUserListByTxn([]);
      setSortedUserListByVol([]);
      setSortedUserListByCommission([]);
      setTreeMapSymbolArray([]);
      setLastUpdatedDate("");
      setFundingTxns([]);
      setFundingStatistics({});
      setMonthWiseFunding([]);
      setAvgMonthWiseTxnTotal([]);
      return setDataErrorMsg("No data available for selected filter");
    }
    //	data.txnData["Spread"] = Math.round(float(data.txnData["Spread"])*100)/100
    setTxnData(data.txnData);
    setStatistics(data.totalStats);
    setMonthWiseCommissionSpread(data.monthWiseCommissionSpread);
    setHeatMapData(data.heatMapData);
    setMonthWiseTxnTotal(data.monthWiseTxnTotal);
    setAvgMonthWiseTxnTotal(data.avgMonthWiseTxnTotal);
    setLastUpdatedDate(data.lastUpdatedDate);
    setFundingTxns(data.funding[0].txnDump);
    let statisticsOfFunding = {};
    if (data.funding[0].fundingStatistics.length > 0) {
      statisticsOfFunding = {
        deposits: data.funding[0].fundingStatistics[0].deposits,
        withdrawals: data.funding[0].fundingStatistics[0].withdrawals,
        creditIns: data.funding[0].fundingStatistics[0].creditIns,
        creditOuts: data.funding[0].fundingStatistics[0].creditOuts,
      };
    }
    setFundingStatistics(statisticsOfFunding);
    const TACC =
      totalUserCount != 0 &&
      data.monthWiseActiveClients.map((m) => {
        return {
          type: "Passive",
          date: m.date,
          count: totalUserCount - m.count,
          // count: data.clients.length - m.count,
        };
      });
    // console.log("here i am",JSON.stringify(TACC,null,2))
    Array.prototype.push.apply(TACC, data.monthWiseActiveClients);
    setTotAndActiveClients(TACC);

    setSortedUserListByTxn(data.sortedUserListByTxn);
    setSortedUserListByVol(data.sortedUserListByVol);
    setSortedUserListByCommission(data.sortedUserListByCommission);
    setSymbolArray(data.symbolArray);
    setTreeMapSymbolArray(data.treeMapSymbolArray);
    setActiveClientList(null);
    setClientList(data.clients);
    setMonthWiseFunding(data.funding[0].monthWiseFundingTxnTotal);

    let tempArr = data.monthWiseActiveClients?.map((k) => {
      let c = 0;
      let s = 0;
      data.monthWiseCommissionSpread?.map((l) => {
        if (l.time == k.date && l.type == "Commission") c = l.value;
        if (l.time == k.date && l.type == "Spread") s = l.value;
      });
      let tempObj = { time: k.date, avgRevenue: (c + s) / k.count };
      return tempObj;
    });
    setMonthWiseAvgRevenuePerClient(tempArr);
  };

  function getDistinctChartData(chartName, filterType) {
    if (chartName === "heatMap") setHeatMapLoading(true);
    else if (chartName === "treeMap") {
      setTreeMapLoading(true);
    }

    axios
      .post(BASE_URL + "/" + platformType + GET_DISTINCT_CHART_DATA, {
        values: filterValues.current,
        uproData: uproDataEncrypted,
        chartName,
        filterType,
      })
      .then((res) => {
        console.log("Distinct chart data", res.data);
        if (chartName === "heatMap") {
          setHeatMapLoading(false);
          if (filterType === "byTxnCount") {
            setClientBehaviourColorField("count");
          } else if (filterType === "byTxnVolume") {
            setClientBehaviourColorField("Volume");
          } else {
            setClientBehaviourColorField("Users");
          }

          setHeatMapData(res.data);
        } else if (chartName === "treeMap") {
          setTreeMapLoading(false);
          setTreeMapSymbolArray(res.data);
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  }
  const getTxns = () => {
    const cacheKey = JSON.stringify(filterValues.current);

    setShowLoader("block");

    axios
      .post(BASE_URL + "/" + platformType + GET_MGMT_USER_CREDIT_MASTER, {})
      .then((res2) => {
        // console.log(JSON.stringify(res2.data.creditMaster, null, 2));
        setTotalUserCount(res2.data.clientMasterCount);
        let temparr = res2.data.creditMaster[0].monthlyCreditHistory;
        let mainarr = [];
        temparr = temparr.map((k) => {
          mainarr.push({
            time: k.time,
            type: "Credits Given",
            value: k.totalCreditIn,
          });
          mainarr.push({
            time: k.time,
            type: "Credits Received",
            value: k.totalCreditOut,
          });
        });
        setUserCreditMaster(mainarr);
      })
      .catch((err) => {
        setShowLoader("none");
        if (err.response.status === 503 || err.response.status === 404) {
          setCachedData((oldState) => {
            return { ...oldState, [cacheKey]: null };
          });

          setStateValues(cachedData[cacheKey]);
        }
      });

    axios
      .post(BASE_URL + "/" + platformType + GET_MGMT_TRANSACTIONS, {
        values: filterValues.current,
        uproData: uproDataEncrypted,
      })
      .then((res) => {
        setCachedData((oldState) => {
          return { ...oldState, [cacheKey]: res.data };
        });

        setStateValues(res.data);
        var temp = {};
        temp["name"] = res.data.userName;
        temp["email"] = res.data.userEmail;
        if (!user) setUser(temp);
        axios
          .post(BASE_URL + "/" + platformType + GET_MGMT_SCATTER_PLOT, {})
          .then((res3) => {
            setShowLoader("none");
            setScatterPlotData(res3.data);
          });
      })
      .catch((err) => {
        setShowLoader("none");
        if (err.response.status === 503 || err.response.status === 404) {
          setCachedData((oldState) => {
            return { ...oldState, [cacheKey]: null };
          });

          setStateValues(cachedData[cacheKey]);
        }
      });
  };

  function handleValueChange(e) {
    let old = filterValues.current;
    old[e.target.name] = e.target.value;
    filterValues.current = old;

    if (e.target.name === "client") {
      setSelectedClient(e.target.value);
    } else if (e.target.name === "symbol") {
      setSelectedSymbol(e.target.value);
    } else if (e.target.name === "startDate") {
      setSelectedStartDate(e.target.value);
    } else if (e.target.name === "endDate") {
      setSelectedEndDate(e.target.value);
    }
  }

  function resetFilter() {
    filterValues.current = {
      client: "",
      symbol: "",
      startDate: "",
      endDate: "",
    };
    setSelectedClient("");
    setSelectedSymbol("");
    setSelectedStartDate("");
    setSelectedEndDate("");
    setDateRange(null);
    setDateRangeString("--/--/----  -  --/--/----");
    getTxns();
  }

  function monthlyTotalTxnSummaryPlotClickListener(plot) {
    plot.on("plot:click", (evt) => {
      const { x, y } = evt;

      const tooltipData = plot.chart.getTooltipItems({ x, y });

      setMonthWiseTopClientsAndSymbolsDialogVisible(true);
      setMonthlyTopClientAndSymbolSelectedDate(tooltipData[0].title);
    });
  }

  const togglePlatformType = () => {
    filterValues.current.startDate = "";
    filterValues.current.endDate = "";
    filterValues.current.client = "";
    filterValues.current.symbol = "";
    if (platformType == "TU") {
      setCachedData({});
      setPlatformType("CFC");
    } else {
      setCachedData({});
      setPlatformType("TU");
    }
  };
  function getFormattedStatValue(value) {
    let val = null;

    if (value < 1000) {
      val = value;
    } else if (value >= 1000 && value < 1000000) {
      val = Math.round(value / 1000).toLocaleString() + "K";
    } else if (value >= 1000000) {
      val = Math.round(value / 1000000).toLocaleString() + "M";
    }
    return val;
  }
  function logout() {
    try {
      localStorage.clear();
      sessionStorage.clear();
      (function () {
        var cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
          var d = window.location.hostname.split(".");
          while (d.length > 0) {
            var cookieBase =
              encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
              "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
              d.join(".") +
              " ;path=";
            var p = window.location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
              document.cookie = cookieBase + p.join("/");
              p.pop();
            }
            d.shift();
          }
        }
      })();
      //window.location = "https://upro.century.ae";
      window.location.reload();
    } catch (e) {
      console.log(e.message);
    }
  }
  return (
    <div className="container">
      <div
        style={{
          height: "120vh",
          width: "100vw",
          position: "fixed",
          zIndex: "5",
          backgroundColor: "#fffffffa",
          backdropFilter: "blur(5px)",
          display: showLoader,
        }}
      >
        <h1>LOADING...</h1>
      </div>

      <Dialog open={dataErrorMsg}>
        <DialogContent>{dataErrorMsg}</DialogContent>
        <DialogActions>
          <Button onClick={() => setDataErrorMsg(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showAppMetrics} fullWidth={true} maxWidth={"xxl"}>
        <LiveAppMetricsComponent
          server={platformType}
          ToggleAppMetrics={ToggleAppMetrics}
        />
      </Dialog>

      <Dialog
        fullWidth
        open={isChartDataGridOpen}
        onClose={handleChartDataGridClose}
      >
        <DialogTitle>{gridDataTitle}</DialogTitle>
        <DialogContent>
          {gridData.length === 0 && <h3>Loading data...</h3>}
          {gridData.length > 0 && (
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                getRowId={(row) => row.loginId}
                rows={gridData}
                components={{
                  Toolbar: GridToolbar,
                }}
                columns={[
                  {
                    field: "Login Id",
                    renderCell: (params) => {
                      return params.row.loginId;
                    },
                    flex: 1,
                    minWidth: 50,
                  },
                  {
                    field: "Txn Volume",
                    renderCell: (params) => {
                      return (
                        <>
                          USD{" "}
                          {Math.round(
                            params.row.txnVolume / 1000
                          ).toLocaleString() + "K"}
                        </>
                      );
                    },
                    flex: 1,
                    minWidth: 50,
                  },
                  {
                    field: "Status",
                    renderCell: (params) => {
                      return params.row.status;
                    },
                    flex: 1,
                    minWidth: 50,
                  },
                ]}
                experimentalFeatures={{ newEditingApi: true }}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChartDataGridClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                m="auto"
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 1,
                    width: "90%",
                    minHeight: "50px",
                    padding: "0 5%",
                  },
                }}
              >
                <Paper sx={{ height: "auto", backgroundColor: "gold" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <h1 align="left">
                        {platformType == "TU" ? "Tradeultra " : "Century "} -
                        Management Dashboard{" "}
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "black" }}
                          onClick={() => {
                            setShowAppMetrics(true);
                          }}
                        >
                          View App Metrics
                        </Button>
                      </h1>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        align="right"
                        style={{
                          float: "right",
                          height: "90px",
                          lineHeight: "90px",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <h4
                          style={{
                            margin: "0",
                            marginRight: "10px",
                            float: "left",
                          }}
                        >
                          Welcome {user && user.name}
                        </h4>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "black",
                            height: "32px",
                            margin: "auto",
                          }}
                          onClick={() => {
                            logout();
                          }}
                        >
                          Logout
                        </Button>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          pl={"15px"}
                        >
                          <Typography style={{ fontWeight: 700 }}>
                            CENTURY
                          </Typography>
                          <AntSwitch
                            checked={platformType == "TU" ? true : false}
                            onChange={(checked) => {
                              togglePlatformType();
                            }}
                            inputProps={{ "aria-label": "ant design" }}
                          />
                          <Typography style={{ fontWeight: 700 }}>
                            TU
                          </Typography>
                        </Stack>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>

                <Paper
                  sx={{
                    padding: "0 5%",
                    display: "flex",
                    height: "auto",
                    width: "100%",
                    backgroundColor: "#fafafa",
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item lg={3} xl={3} md={12} sm={12} xs={12}>
                      <Box
                        pt={2}
                        pb={2}
                        sx={{
                          boxSizing: "border-box",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "16px",
                            marginTop: "0",
                            textAlign: "left",
                          }}
                        >
                          <b>Please note:</b> We are constantly making updates
                          to the dashboard hence the data represented may have
                          minor differences.
                        </span>
                      </Box>
                    </Grid>
                    <Grid item lg={9} xl={9} md={12} sm={12} xs={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          width: "100%",
                        }}
                      >
                        <Box
                          pt={2}
                          pb={2}
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item md={12} sm={12} xs={12} lg={0} xl={0}>
                              <Box
                                sx={{
                                  display: {
                                    lg: "none",
                                    xl: "none",
                                    md: "flex",
                                    sm: "flex",
                                    xs: "flex",
                                  },
                                  alignItems: "center",
                                  gap: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    height: "1px",
                                    backgroundColor: "divider",
                                    flex: 1,
                                  }}
                                ></Box>
                                <Box>Filters</Box>
                                <Box
                                  sx={{
                                    height: "1px",
                                    backgroundColor: "divider",
                                    flex: 1,
                                  }}
                                ></Box>
                              </Box>
                            </Grid>
                            <Grid item lg={2} xl={2} md={2} sm={6} xs={12}>
                              <FormControl sx={{ width: "100%" }}>
                                {/* <InputLabel id="demo-simple-select-label">
                          Client
                        </InputLabel> */}
                                <Autocomplete
                                  disablePortal
                                  size="small"
                                  defaultValue={"All"}
                                  onChange={(e, option) => {
                                    handleValueChange({
                                      target: { name: "client", value: option },
                                    });
                                  }}
                                  options={["All"].concat(clientList)}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Client" />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item lg={2} xl={2} md={2} sm={6} xs={12}>
                              <FormControl sx={{ width: "100%" }}>
                                <Autocomplete
                                  disablePortal
                                  size="small"
                                  defaultValue={"All"}
                                  onChange={(e, option) => {
                                    handleValueChange({
                                      target: { name: "symbol", value: option },
                                    });
                                  }}
                                  options={["All"].concat(symbolArray)}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Symbol" />
                                  )}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item lg={6} xl={4} md={4} sm={12} xs={12}>
                              <DateRangeTimePicker
                                state={dateRange}
                                setState={setDateRange}
                                filterValues={filterValues}
                              />
                            </Grid>

                            <Grid item lg={1} xl={1} md={3} sm={6} xs={12}>
                              <Button
                                fullWidth
                                variant="contained"
                                style={{ backgroundColor: "black" }}
                                onClick={getTxns}
                              >
                                Submit
                              </Button>
                            </Grid>
                            <Grid item lg={1} xl={1} md={2} sm={6} xs={12}>
                              <Button
                                fullWidth
                                variant="outlined"
                                onClick={resetFilter}
                              >
                                Clear
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
          </Grid>

          <p>
            <span style={{ background: "gold", padding: "0.5% 1%" }}>
              <b>
                Dashboard has been updated with trading data from{" "}
                {platformType == "TU" ? "2022-09-01" : "2023-10-27"} to{" "}
                {lastUpdatedDate.split("T")[0]}
              </b>
            </span>{" "}
            <span
              style={{
                float: "right",
                marginRight: "16px",
                background: "gold",
                padding: "0.5% 1%",
              }}
            >
              <b>Timezone: GMT</b>
            </span>
          </p>

          {/*Stat Grid*/}
          <Grid container spacing={2} p={1}>
            <Grid item {...statCardGridSize}>
              <StatCard
                value={totalUserCount}
                heading={"Total Clients"}
                infoKey={"totalClients"}
              />
            </Grid>
            <Grid item {...statCardGridSize}>
              <StatCard
                value={statistics && statistics.activeUserCount}
                heading={"Total Active Clients"}
                infoKey={"totalActiveClients"}
              />
            </Grid>
            <Grid item {...statCardGridSize}>
              <StatCard
                value={
                  <>
                    USD{" "}
                    {statistics && getFormattedStatValue(statistics.txnVolume)}
                  </>
                }
                heading={"Total Trade Volume"}
                infoKey={"totalTradeVolume"}
              />
            </Grid>
            <Grid item {...statCardGridSize}>
              <StatCard
                value={treeMapSymbolArray && treeMapSymbolArray.length}
                heading={"Total Intruments Traded"}
                infoKey={"totalInstrumentsTraded"}
              />
            </Grid>
            <Grid item {...statCardGridSize}>
              <StatCard
                value={
                  <>
                    USD{" "}
                    {fundingStatistics &&
                      Math.round(fundingStatistics.deposits).toLocaleString()}
                  </>
                }
                heading={"Total Deposits"}
                infoKey={"totalDeposits"}
              />
            </Grid>
            <Grid item {...statCardGridSize}>
              <StatCard
                value={
                  <>
                    {" "}
                    USD{" "}
                    {fundingStatistics &&
                      Math.round(
                        fundingStatistics.withdrawals
                      ).toLocaleString()}
                  </>
                }
                heading={"Total Withdrawals"}
                infoKey={"totalWithdrawals"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 1,
                    width: "100%",
                  },
                }}
              >
                <Paper sx={{ height: "auto", backgroundColor: "#d0cece" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h4 style={{ margin: "5px" }}>Transaction Metrics</h4>
                    </Grid>
                  </Grid>{" "}
                </Paper>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} p={1}>
            <Grid item lg={12} xl={6} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    width: "100%",
                    minHeight: "400px",
                    padding: "0 5%",
                  },
                }}
              >
                <Paper sx={{ height: "300px" }}>
                  <MonthlyTopClientAndSymbolDialog
                    open={monthWiseTopClientsAndSymbolsDialogVisible}
                    uproData={uproDataEncrypted}
                    selectedDate={monthlyTopClientAndSymbolSelectedDate}
                    platformType={platformType}
                    onClose={() =>
                      setMonthWiseTopClientsAndSymbolsDialogVisible(false)
                    }
                    filterValues={filterValues}
                  />
                  <span style={{ float: "right", marginTop: "10px" }}>
                    <CSVLink
                      filename="Monthly Total Transaction Summary"
                      data={monthWiseTxnTotal || []}
                    >
                      <SystemUpdateAltIcon sx={{ color: "black" }} />
                    </CSVLink>
                  </span>
                  <h2>
                    Monthly Total Transaction Summary{" "}
                    <InfoPopover
                      content={DashboardTooltipItems["monthlyTotalTxnSummary"]}
                    />
                  </h2>
                  <br></br>
                  {monthWiseTxnTotal && monthWiseTxnTotal.length > 0 ? (
                    <BarLineChartComponent
                      onPlotClickListener={
                        monthlyTotalTxnSummaryPlotClickListener
                      }
                      data={monthWiseTxnTotal}
                      xField={"time"}
                      yFieldLine={"txnCount"}
                      yFieldBar={"txnVolume"}
                    />
                  ) : (
                    <NoDataElement />
                  )}
                </Paper>
              </Box>
            </Grid>
            <Grid item lg={5} xl={3} md={6} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    width: "100%",
                    minHeight: "400px",
                  },
                }}
              >
                <Paper sx={{ height: "300px" }}>
                  <h2>
                    Monthly Average Transaction Summary{" "}
                    <InfoPopover
                      content={DashboardTooltipItems["monthlyAvgTxnSummary"]}
                    />
                  </h2>
                  <div style={{ overflow: "auto", height: "250px" }}>
                    <Table stickyHeader style={{ tableLayout: "fixed" }}>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Month</StyledTableCell>
                          <StyledTableCell>Avg Txn Volume</StyledTableCell>
                          <StyledTableCell>Total Txn Count</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {avgMonthWiseTxnTotal &&
                          avgMonthWiseTxnTotal.length > 0 ? (
                          Object.keys(avgMonthWiseTxnTotal).map((x) => {
                            if (x) {
                              return (
                                <StyledTableRow>
                                  <StyledTableCell>
                                    {avgMonthWiseTxnTotal[x].time}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    USD{" "}
                                    {(
                                      Math.round(
                                        avgMonthWiseTxnTotal[x].AvgTxnVolume *
                                        100
                                      ) / 100
                                    ).toLocaleString()}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {avgMonthWiseTxnTotal[x].AvgTxnCount}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            }
                          })
                        ) : (
                          <NoDataElement />
                        )}
                      </TableBody>
                    </Table>
                  </div>
                  {/* {avgMonthWiseTxnTotal && avgMonthWiseTxnTotal.length > 0 ? (
                    <DataGrid
                      style={{ height: "300px" }}
                      getRowId={(row) => row.time}
                      columns={[
                        { field: "time", flex: 1 },
                        { field: "AvgTxnVolume", flex: 1 },
                        { field: "AvgTxnCount", flex: 1 },
                      ]}
                      rows={avgMonthWiseTxnTotal}
                      //   components={{
                      //     Toolbar: GridToolbar,
                      //   }}
                    />
                  ) : (
                    <NoDataElement />
                  )} */}
                </Paper>
              </Box>
            </Grid>
            <Grid item lg={7} xl={3} md={6} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    width: "100%",
                    minHeight: "400px",
                  },
                }}
              >
                <Paper sx={{ height: "300px", position: "relative" }}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 46,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box>
                      <label>Filter by </label>
                      <select
                        defaultValue={"byTxnCount"}
                        style={{ fontSize: 14 }}
                        onChange={(e) => {
                          getDistinctChartData("treeMap", e.target.value);
                        }}
                      >
                        <option value={"byTxnCount"}>Txn Count</option>
                        <option value={"byTxnVolume"}>Txn Volume</option>
                        <option value={"byDistinctUsers"}>
                          Distinct Users
                        </option>
                      </select>
                    </Box>
                  </Box>
                  <span
                    style={{
                      float: "right",
                      marginTop: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <CSVLink
                      filename="Symbol Wise Transaction Count"
                      data={treeMapSymbolArray || []}
                    >
                      <SystemUpdateAltIcon sx={{ color: "black" }} />
                    </CSVLink>
                  </span>
                  <h3>
                    Symbol wise Transactions{" "}
                    <InfoPopover
                      content={DashboardTooltipItems["symbolWiseTxns"]}
                    />
                  </h3>
                  {treeMapSymbolArray && treeMapSymbolArray.length > 0 ? (
                    <TreeMapComponent
                      data={treeMapSymbolArray}
                      isLoading={treeMapLoading}
                      colorField={"name"}
                    />
                  ) : (
                    <NoDataElement />
                  )}
                </Paper>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 1,
                    width: "100%",
                  },
                }}
              >
                <Paper sx={{ height: "auto", backgroundColor: "#d0cece" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h4 style={{ margin: "5px" }}>
                        Customer, Funding & Credit Metrics
                      </h4>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2} p={1}>
            <Grid item lg={8} xl={6} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    width: "100%",
                    minHeight: "400px",
                    padding: "0 5%",
                  },
                }}
              >
                <Paper sx={{ height: "300px" }}>
                  <h2>
                    Active clients vs Passive clients{" "}
                    <InfoPopover
                      content={DashboardTooltipItems["actVsPassClients"]}
                    />
                  </h2>
                  {totAndActClients && totAndActClients.length > 0 ? (
                    platformType == "TU" ? (
                      <AreaChartComponent
                        data={totAndActClients}
                        encryptedData={uproDataEncrypted}
                        setGridDataTitle={setGridDataTitle}
                        setDialogDataGrid={setGridData}
                        platformType={"TU"}
                        isAdmin={true}
                        onOpenDataGrid={() => {
                          setIsChartDataGridOpen(true);
                        }}
                        xField={"date"}
                        yField={"count"}
                        series={"type"}
                      />
                    ) : (
                      <AreaChartComponentCFC
                        data={totAndActClients}
                        isAdmin={true}
                        encryptedData={uproDataEncrypted}
                        setGridDataTitle={setGridDataTitle}
                        setDialogDataGrid={setGridData}
                        platformType={"CFC"}
                        onOpenDataGrid={() => {
                          setIsChartDataGridOpen(true);
                        }}
                        xField={"date"}
                        yField={"count"}
                        series={"type"}
                      />
                    )
                  ) : (
                    <NoDataElement />
                  )}
                </Paper>
              </Box>
            </Grid>
            <Grid item lg={4} xl={3} md={6} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    width: "100%",
                    minHeight: "400px",
                  },
                }}
              >
                <Paper sx={{ height: "300px", position: "relative" }}>
                  <h2>
                    Client Behaviour{" "}
                    <InfoPopover
                      content={DashboardTooltipItems["clientBehaviour"]}
                    />
                  </h2>
                  <Box sx={{ marginTop: -2, marginBottom: 1 }}>
                    <label>Filter by </label>
                    <select
                      defaultValue={"byTxnCount"}
                      style={{ fontSize: 14 }}
                      onChange={(e) => {
                        getDistinctChartData("heatMap", e.target.value);
                      }}
                    >
                      <option value={"byTxnCount"}>Txn Count</option>
                      <option value={"byTxnVolume"}>Txn Volume</option>
                      <option value={"byDistinctUsers"}>Distinct Users</option>
                    </select>
                  </Box>
                  {heatMapData && heatMapData.length > 0 ? (
                    <HeatMapComponent
                      isLoading={heatMapLoading}
                      colorField={clientBehaviourColorField}
                      data={heatMapData}
                    />
                  ) : (
                    <NoDataElement />
                  )}
                </Paper>
              </Box>
            </Grid>
            <Grid item lg={4} xl={3} md={6} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    width: "100%",
                    minHeight: "400px",
                    padding: "0 5%",
                  },
                }}
              >
                <Paper sx={{ height: "300px" }}>
                  {value == 0 && (
                    <span
                      style={{
                        float: "right",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <CSVLink
                        filename="Top Users - Transaction Volume"
                        data={sortedUserListByVol || []}
                      >
                        <SystemUpdateAltIcon sx={{ color: "black" }} />
                      </CSVLink>
                    </span>
                  )}
                  {value == 1 && (
                    <span
                      style={{
                        float: "right",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <CSVLink
                        filename="Top Users - Transaction Count"
                        data={sortedUserListByTxn || []}
                      >
                        <SystemUpdateAltIcon sx={{ color: "black" }} />
                      </CSVLink>
                    </span>
                  )}
                  {value == 2 && (
                    <span
                      style={{
                        float: "right",
                        marginTop: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <CSVLink
                        filename="Top Users - Commissions & Spreads"
                        data={sortedUserListByCommission || []}
                      >
                        <SystemUpdateAltIcon sx={{ color: "black" }} />
                      </CSVLink>
                    </span>
                  )}
                  <h2>
                    Top Clients{" "}
                    <InfoPopover
                      content={DashboardTooltipItems["topClients"]}
                    />
                  </h2>
                  <Tabs
                    value={value}
                    onChange={(e, value) => {
                      setValue(value);
                    }}
                  >
                    <Tab style={{ width: "33%" }} label="By Volume" />
                    <Tab style={{ width: "33%" }} label="By Txn Count" />
                    {!maskingState && (
                      <Tab style={{ width: "33%" }} label="By Revenue" />
                    )}
                  </Tabs>
                  {value === 0 && (
                    <div
                      style={{
                        overflow: "auto",
                        height: "250px",
                      }}
                    >
                      <Table
                        stickyHeader
                        style={{
                          tableLayout: "fixed",
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Client</StyledTableCell>
                            <StyledTableCell>Txn Volume</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sortedUserListByVol &&
                            Object.keys(sortedUserListByVol).map((x) => {
                              return (
                                <StyledTableRow>
                                  <StyledTableCell>
                                    {sortedUserListByVol[x].loginId}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    USD{" "}
                                    {(
                                      Math.round(
                                        sortedUserListByVol[x].txnVolume * 100
                                      ) / 100
                                    ).toLocaleString()}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  {value === 1 && (
                    <div
                      style={{
                        overflow: "auto",
                        height: "250px",
                      }}
                    >
                      <Table
                        stickyHeader
                        style={{
                          tableLayout: "fixed",
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Client</StyledTableCell>
                            <StyledTableCell>Txn Count</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sortedUserListByTxn &&
                            Object.keys(sortedUserListByTxn).map((x) => {
                              return (
                                <StyledTableRow>
                                  <StyledTableCell>
                                    {sortedUserListByTxn[x].loginId}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {(
                                      Math.round(
                                        sortedUserListByTxn[x].txnCount * 100
                                      ) / 100
                                    ).toLocaleString()}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  {value === 2 && !maskingState && (
                    <div
                      style={{
                        overflow: "auto",
                        height: "250px",
                      }}
                    >
                      <Table
                        stickyHeader
                        style={{
                          tableLayout: "fixed",
                        }}
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Client</StyledTableCell>
                            <StyledTableCell>Revenue</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        {!maskingState && (
                          <TableBody>
                            {sortedUserListByCommission &&
                              Object.keys(sortedUserListByCommission).map(
                                (x) => {
                                  return (
                                    <StyledTableRow>
                                      <StyledTableCell>
                                        {sortedUserListByCommission[x].loginId}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        USD{" "}
                                        {(
                                          Math.round(
                                            (sortedUserListByCommission[x]
                                              .commission +
                                              sortedUserListByCommission[x]
                                                .spread) *
                                            100
                                          ) / 100
                                        ).toLocaleString()}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        )}
                        {maskingState && (
                          <TableBody>
                            {sortedUserListByCommission &&
                              Object.keys(sortedUserListByCommission).map(
                                (x) => {
                                  return (
                                    <StyledTableRow>
                                      <StyledTableCell>
                                        {sortedUserListByCommission[x].loginId}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        USD{" "}
                                        {(
                                          Math.round(
                                            sortedUserListByCommission[x]
                                              .commission * 100
                                          ) / 100
                                        ).toLocaleString()}
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        )}
                      </Table>{" "}
                    </div>
                  )}
                </Paper>
              </Box>
            </Grid>
            <Grid item lg={8} xl={6} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    width: "100%",
                    minHeight: "400px",
                    padding: "0 5%",
                  },
                }}
              >
                <Paper sx={{ height: "300px" }}>
                  <h2>
                    Monthly Deposits and Withdrawals{" "}
                    <InfoPopover
                      content={
                        DashboardTooltipItems["monthlyDepositsAndWithdrawals"]
                      }
                    />
                  </h2>
                  {monthWiseFundingTotal && monthWiseFundingTotal.length > 0 ? (
                    <BidirectionalChartComponent
                      data={monthWiseFundingTotal}
                      xField={"time"}
                      yField2={"deposits"}
                      yField1={"withdrawals"}
                    />
                  ) : (
                    <NoDataElement />
                  )}
                </Paper>
              </Box>
            </Grid>
            <Grid item lg={12} xl={6} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    width: "100%",
                    minHeight: "400px",
                    padding: "0 5%",
                  },
                }}
              >
                <Paper sx={{ height: "300px" }}>
                  <h2>
                    Monthly Deposits & Count metrics{" "}
                    <InfoPopover
                      content={DashboardTooltipItems["monthlyDepositsAndCount"]}
                    />
                  </h2>
                  {monthWiseFundingTotal && monthWiseFundingTotal.length > 0 ? (
                    <BarLineChartComponent2
                      data={monthWiseFundingTotal}
                      xField={"time"}
                      yFieldLine={"depositCount"}
                      yFieldBar={"deposits"}
                    />
                  ) : (
                    <NoDataElement />
                  )}
                </Paper>
              </Box>
            </Grid>
            <Grid item lg={6} xl={4} md={6} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    width: "100%",
                    minHeight: "500px",
                    padding: "0 5%",
                  },
                }}
              >
                <Paper sx={{ minHeight: "500px" }}>
                  <span style={{ float: "right", marginTop: "10px" }}></span>
                  <h2>
                    Credit Summary{" "}
                    <InfoPopover
                      content={DashboardTooltipItems["creditSummary"]}
                    />
                  </h2>

                  <br></br>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Paper
                        style={{
                          padding: "5%",
                          backgroundColor: "#111",
                          color: "gold",
                        }}
                      >
                        <p>Total Credits Given</p>
                        <h2 style={{ color: "white" }}>
                          USD{" "}
                          {fundingStatistics &&
                            Math.round(
                              fundingStatistics.creditIns
                            ).toLocaleString()}
                        </h2>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper
                        style={{
                          padding: "5%",
                          backgroundColor: "#111",
                          color: "gold",
                        }}
                      >
                        <p>Total Credits Received</p>
                        <h2 style={{ color: "white" }}>
                          USD{" "}
                          {fundingStatistics &&
                            Math.round(
                              fundingStatistics.creditOuts
                            ).toLocaleString()}
                        </h2>
                      </Paper>
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Paper
                        style={{
                          padding: "5%",
                          backgroundColor: "#111",
                          color: "gold",
                        }}
                      >
                        <p>Net Credit Receivable</p>
                        <h2 style={{ color: "white" }}>
                          USD{" "}
                          {fundingStatistics &&
                            Math.round(
                              fundingStatistics.creditIns -
                              fundingStatistics.creditOuts
                            ).toLocaleString()}
                        </h2>
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Paper
                        style={{
                          padding: "5%",
                          backgroundColor: "#111",
                          color: "gold",
                        }}
                      >
                        <p>Average Credit Value</p>
                        <h2 style={{ color: "white" }}>
                          USD{" "}
                          {fundingStatistics &&
                            Math.round(
                              fundingStatistics.creditAvg
                            ).toLocaleString()}
                        </h2>
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
            <Grid item lg={12} xl={8} md={12} sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    width: "100%",
                    minHeight: "500px",
                    padding: "0 5%",
                  },
                }}
              >
                <Paper sx={{ minHeight: "500px" }}>
                  <span style={{ float: "right", marginTop: "10px" }}>
                    <CSVLink filename="Funding Transaction" data={[]}>
                      <SystemUpdateAltIcon sx={{ color: "black" }} />
                    </CSVLink>
                  </span>

                  <h2>
                    Month Wise Credit History{" "}
                    <InfoPopover
                      content={DashboardTooltipItems["monthWiseCreditHistory"]}
                    />
                  </h2>
                  <br />
                  <BarChartComponent
                    data={userCreditMaster}
                    xField={"time"}
                    yField={"value"}
                    seriesField={"type"}
                  />
                  <br></br>
                </Paper>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 1,
                    width: "100%",
                  },
                }}
              >
                <Paper sx={{ height: "auto", backgroundColor: "#d0cece" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <h4
                        style={{
                          margin: "5px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        Revenue Metrics{" "}
                        <div style={{ marginLeft: "10px", cursor: "pointer" }}>
                          {maskingState ? (
                            <VisibilityOffIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          ) : (
                            <VisibilityIcon
                              onClick={() => {
                                setMaskingState(!maskingState);
                              }}
                            />
                          )}
                        </div>
                      </h4>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 1,
                    width: "90%",
                    minHeight: "400px",
                    padding: "0 5%",
                  },
                }}
              >
                <Paper sx={{ height: "300px" }}>
                  <span style={{ float: "right", marginTop: "10px" }}>
                    <CSVLink
                      filename="Monthly Commissions & Spreads"
                      data={monthWiseCommissionSpread || []}
                    >
                      <SystemUpdateAltIcon sx={{ color: "black" }} />
                    </CSVLink>
                  </span>
                  <h3>
                    Commissions & Spreads Monthly{" "}
                    <InfoPopover
                      content={DashboardTooltipItems["comsAndSpreadsMonthly"]}
                    />
                  </h3>
                  {!maskingState &&
                    monthWiseCommissionSpread &&
                    monthWiseCommissionSpread.length > 0 ? (
                    <StackedRadialBar
                      data={monthWiseCommissionSpread}
                      xField={"time"}
                      yField={"value"}
                      colorField={"type"}
                    />
                  ) : (
                    <NoDataElement />
                  )}
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 1,
                    width: "90%",
                    minHeight: "400px",
                    padding: "0 5%",
                  },
                }}
              >
                <Paper sx={{ height: "300px" }}>
                  <h2>
                    Monthly Average Revenue per Client{" "}
                    <InfoPopover
                      content={
                        DashboardTooltipItems["monthlyAvgRevenuePerClient"]
                      }
                    />
                  </h2>
                  {!maskingState &&
                    monthWiseAvgRevenuePerClient &&
                    monthWiseAvgRevenuePerClient.length > 0 ? (
                    <BarChartComponent2
                      data={monthWiseAvgRevenuePerClient}
                      xField={"time"}
                      yField={"avgRevenue"}
                    />
                  ) : (
                    <NoDataElement />
                  )}
                </Paper>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  "& > :not(style)": {
                    m: 1,
                    width: "90%",
                    minHeight: "700px",
                    padding: "0 5%",
                  },
                }}
              >
                <Paper sx={{ height: "600px" }}>
                  <h2>
                    Revenue as a measure of user life cycle, deposits and count
                    of trades
                    <InfoPopover
                      content={DashboardTooltipItems["revenueAsMeasure"]}
                    />
                  </h2>

                  {!maskingState && scatterPlotData != [] ? (
                    <ScatterPlotComponent
                      data={scatterPlotData}
                      xField={"totalDeposit"}
                      yField={"countOfTransaction"}
                      colorField={"lifeString"}
                      sizeField={"revenue"}
                    />
                  ) : (
                    <NoDataElement />
                  )}
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* <div>
                <BarChartComponent data={txnData} xField={"time"} yField={"value"} />
                <StackedBarLineChartComponent data={txnData} xField={"time"} yFieldLine={"count"} yFieldBar={"value"} stackField={"type"} />
            </div>
            <div>
                <AreaChartComponent data={txnData} xField={"date"} yField={"commission"} seriesField={"loginId"} />
            </div> */}
    </div>
  );
}

function NoDataElement() {
  return (
    <>
      <br />
      <br />
      <br />
      <h2 style={{ color: "goldenrod" }}>No Data Available</h2>
    </>
  );
}

function InfoPopover(props) {
  const { content, color = "black" } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton aria-describedby={id} size="small" onClick={handleClick}>
        <InfoOutlinedIcon size="small" sx={{ color }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>{content}</Typography>
      </Popover>
    </>
  );
}
