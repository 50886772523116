import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CirclePacking } from "@ant-design/plots";

const BubbleChartComponent2 = (props) => {
  let { data, xField, yField } = props;
  data = { name: "Total Searches", children: data };
  data.children = data.children.map((k) => {
    return { name: k?.Product, value: k?.Hits };
  });

  // const asyncFetch = () => {
  //   fetch("https://gw.alipayobjects.com/os/antvdemo/assets/data/flare.json")
  //     .then((response) => response.json())
  //     .then((json) => setData(json))
  //     .catch((error) => {
  //       console.log("fetch data failed", error);
  //     });
  // };
  const config = {
    autoFit: true,
    padding: 0,
    data,
    // sizeField: "r",
    // color: ["#fff", "#ffd700", "#ffdf33", "#ffe766", "#ededed", "#aaa"],
    color: "white-gold-yellow-#ededed",
    label: false,
    legend: false,
    // drilldown: {
    //   enabled: true,
    //   breadCrumb: {
    //     position: "top-left",
    //   },
    // },
    tooltip: false,
    label: {
      formatter: ({ name, value }) => {
        return name !== "Total Searches" ? name : "";
      },
      // 偏移
      offsetY: 8,
      style: {
        fontSize: 12,
        textAlign: "center",
        fill: "rgba(0,0,0,0.65)",
      },
    },
  };

  return <CirclePacking {...config} />;
};

export default BubbleChartComponent2;
