import React, { useState, useRef, useEffect, useContext } from "react";
import { Space, Tooltip } from "antd";

import shortid from "shortid";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export default function ProfileColumn(props) {
  const { profile, sr_no, setErrors } = props;
  const [isError, setIsError] = useState(false);
  const [errorTitle, setErrorTitle] = useState("");
  const [elementId, setElementId] = useState(profile + "" + sr_no + "_profile");

  useEffect(() => {
    try {
      if (typeof profile == "object") {
        setIsError(true);
        setErrorTitle("Profile can't be empty");
      } else if (profile.length === 0) {
        setIsError(true);
        setErrorTitle("Profile can't be empty");
      } else if (!profile.includes("P")) {
        setIsError(true);
        setErrorTitle("Wrong profile value format");
      }
    } catch (e) {
      // setErrors(old => [...old, elementId]);
      setIsError(true);
    }
  }, []);

  return (
    <>
      {isError && (
        <Tooltip title={errorTitle}>
          <div id={elementId} style={{ paddingLeft: 4 }}>
            <Space>
              <span style={{ color: "red" }}>{profile}</span>
              <ReportProblemIcon fontSize="small" style={{ color: "gold" }} />
            </Space>
          </div>
        </Tooltip>
      )}
      {!isError && (
        <div id={elementId} style={{ paddingLeft: 4 }}>
          <span style={{ color: "black" }}>{profile}</span>
        </div>
      )}
    </>
  );
}
