import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Tooltip } from "@mui/material";
import moment from "moment";
import { Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  GET_LEAN_PAYMENT_MASTER,
  GET_CLIENT_MATCHED_MASTER,
  CHECK_ALLOWED_USERS,
  GET_PUBLIC_KEY,
} from "../../helpers/apiStrings";
import { config } from "../../helpers/config";
import { JSEncrypt } from "jsencrypt";
import { Buffer } from "buffer";
import { PublicClientApplication } from "@azure/msal-browser";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "black",
  display: "flex",
}));

const encrypt = new JSEncrypt();

const publicClientApplication = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUri,
    authority: config.authority,
    postLogoutRedirectUri: config.postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
});
export default function AccountsHomeComponent(props) {
  const [authenticated, setAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [paymentDataArray, setPaymentDataArray] = useState([]);
  const [customerMatchedData, setCustomerMatchedData] = useState({});
  const [selectedRow, setSelectedRow] = useState(0); //can be -1 one but first data is selected by default so it is 0
  const [pageSize, setPageSize] = useState(10);
  const [selectedCustomerData, setSelectedCustomerData] = useState(null);
  const [userSelectionMsg, setUserSelectionMsg] = useState(
    "Please select a transaction to view client details."
  );
  const navigate = useNavigate();
  const { source } = props;
  const columns = useMemo(() => [
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => moment(params.row.date).format("DD-MM-YYYY"),
    },
    {
      field: "metaAccountId",
      headerName: "Meta Account Id",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      // type: 'singleSelect',
      // valueOptions: ['basic', 'editor', 'admin'],
      // editable: true,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "txnStatus",
      headerName: "Txn Status",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return params.value == "ACCEPTED_BY_BANK" ? (
          <p
            style={{
              color: "green",
            }}
          >
            SUCCESS
          </p>
        ) : params.value == "Txn Pending" ? (
          <p
            style={{
              color: "gray",
            }}
          >
            CANCELLED BY CLIENT
          </p>
        ) : params.value == "PENDING_WITH_BANK" ? (
          <Tooltip title="Please contact Ahish for this">
            <p
              style={{
                color: "rgb(191 164 4)",
              }}
            >
              PENDING (BANK)
            </p>
          </Tooltip>
        ) : (
          <p
            style={{
              color: "red",
            }}
          >
            FAILED
          </p>
        );
      },
    },
    {
      field: "bankRef",
      headerName: "Bank Ref",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "clientTransactionData",
      headerName: "Match Count",
      flex: 1,
      minWidth: 100,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        let count = "0";
        if (typeof params.value[0] == "object") {
          if (params.value[0]["result"]["name"]["result"] == "Matched") {
            count++;
          }
          if (params.value[0]["result"]["email"]["result"] == "Matched") {
            count++;
          }
          if (params.value[0]["result"]["dob"]["result"] == "Matched") {
            count++;
          }
          if (params.value[0]["result"]["phone"]["result"] == "Matched") {
            count++;
          }
          if (params.value[0]["result"]["gender"]["result"] == "Matched") {
            count++;
          }
          if (params.value[0]["result"]["address"]["result"] == "Matched") {
            count++;
          }
        }

        return <p style={{ color: "" }}>{count} / 6</p>;
      },
    },
  ]);

  const handleSelectRow = (rowIndex) => {
    setSelectedRow(rowIndex);
  };

  function selectedCustomer(customerId) {
    if (customerMatchedData[customerId] == null) {
      setSelectedCustomerData(null);
      setUserSelectionMsg("Client data not available for this transaction.");
    } else {
      setSelectedCustomerData(customerMatchedData[customerId].result);
    }
  }

  function getPublicKey() {
    setIsLoading(true);
    axios
      .get(GET_PUBLIC_KEY)
      .then((res) => {
        setIsLoading(false);
        const d = Buffer.from(res.data, "base64").toString("ascii");
        encrypt.setPublicKey(d);

        let accounts = publicClientApplication.getAllAccounts();
        if (accounts.length == 0) {
          console.log("Not Signed in");
          navigate("/account");
        } else {
          checkAuthorization(accounts[0].username, accounts[0].name);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e.message);
      });
  }

  function checkAuthorization(userEmail, name) {
    //CAU = century admin users
    setIsLoading(true);
    const enc = encrypt.encrypt(JSON.stringify({ userType: "ACU", userEmail }));
    axios
      .post(CHECK_ALLOWED_USERS, { payload: enc })
      .then((res) => {
        setName(name);
        setAuthenticated(true);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setName(name);

        setAuthenticated(false);
      });
  }
  useEffect(() => {
    getPublicKey();
    getData();
  }, []);

  const getData = () => {
    axios
      .post(GET_LEAN_PAYMENT_MASTER, {
        // authorization: localStorage.getItem("bearerToken"),
        // source: source,
      })
      .then((resp) => {
        return resp.data;
      })
      .then((data) => {
        setPaymentDataArray(data);
        axios
          .post(GET_CLIENT_MATCHED_MASTER, {
            // authorization: localStorage.getItem("bearerToken"),
            // source: source,
          })
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            setCustomerMatchedData(data[0].masters);
            //Put code for Loading
          })
          .catch((error) => {
            console.log(error.message);
          });
      })
      .catch((error) => {
        console.log(error.message);
        if (error.response.status == 401) {
          logout();
        }
      });
  };
  function logout() {
    try {
      localStorage.clear();
      sessionStorage.clear();
      (function () {
        var cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
          var d = window.location.hostname.split(".");
          while (d.length > 0) {
            var cookieBase =
              encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
              "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
              d.join(".") +
              " ;path=";
            var p = window.location.pathname.split("/");
            document.cookie = cookieBase + "/";
            while (p.length > 0) {
              document.cookie = cookieBase + p.join("/");
              p.pop();
            }
            d.shift();
          }
        }
      })();
      window.location.reload();
      //   window.location.reload();
    } catch (e) {
      console.log(e.message);
    }
  }
  return (
    <Grid
      container
      // mt={2}
      style={{ backgroundColor: "transparent", width: "100%" }}
    >
      {props.header ? (
        <Grid
          container
          style={{
            backgroundColor: "#ffd700",
            boxShadow: "0 1px 2px 0 gray",
          }}
        >
          <Grid item xs={9} mt={1} p={1}>
            <Item
              style={{
                fontWeight: "bold",
                borderStyle: "none",
                boxShadow: "none",
                marginLeft: 10,
              }}
            >
              Lean Payment Master
            </Item>
          </Grid>
          <Grid item xs={3} mt={1} p={1}>
            <Item
              style={{
                fontWeight: "bold",
                borderStyle: "none",
                boxShadow: "none",
                flexDirection: "row-reverse",
              }}
            >
              <div>
                <Button
                  onClick={logout}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    float: "right",
                    marginRight: "50px",
                  }}
                  variant="contained"
                >
                  Logout
                </Button>
              </div>
            </Item>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid
        item
        xs={8}
        mt={props.topMargin ? -Number(props.topMargin) : 0}
        p={1}
      >
        <Item sx={{ background: "white" }}>
          <Box sx={{ height: 700, width: "100%" }}>
            <DataGrid
              sx={{
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "#d6d6d6",
                },
                ".MuiDataGrid-columnSeparator": {
                  display: "none",
                },
                "& .MuiDataGrid-cell:hover": {
                  // color: '#ffd700',
                  cursor: "pointer",
                },
                "& .MuiDataGrid-cell:hover": {
                  // color: '#ffd700',
                  cursor: "pointer",
                },
                "& .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                  width: "0.7em",
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
                  background: "#ffffff",
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
                  backgroundColor: "black",
                  borderRadius: 2,
                },
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover":
                {
                  background: "#ffd700",
                },
              }}
              columns={columns}
              rows={paymentDataArray}
              getRowId={(row) => row._id}
              rowsPerPageOptions={[10, 25, 50, 100]}
              pageSize={pageSize}
              components={{
                Toolbar: GridToolbar,
              }}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              // onRowClick={(data) => {selectedTransactionData({ customerId: data.row.customerId, metaAccId: data.row.metaAccountId, paymentId: data.row.paymentId, amount: data.row.amount, currency: data.row.currency, date: data.row.date, txnStatus: data.row.txnStatus, bankRef: data.row.bankRef }, 1)}}
              onRowClick={(data) => {
                selectedCustomer(data.row.customerId);
              }}
            />
          </Box>
        </Item>
      </Grid>
      <Grid
        item
        xs={4}
        mt={props.topMargin ? -Number(props.topMargin) : 0}
        p={1}
      >
        <Item
          style={{
            backgroundColor: "white",
            // borderStyle: "none",
            // boxShadow: "none",
            fontWeight: "bold",
            display: "flex",
            flexFlow: "column",
          }}
        >
          <Box sx={{ width: "100%", height: 700 }}>
            <Grid item xs={12}>
              <h2
                style={{
                  fontWeight: "bold",
                  borderStyle: "none",
                  boxShadow: "none",
                  justifyContent: "center",
                }}
              >
                Selected Client Data Comparison
              </h2>
            </Grid>
            <Grid
              sx={{
                maxHeight: "610px",
                padding: "10px",
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  width: "0.5em",
                },
                "&::-webkit-scrollbar-track": {
                  "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "black",
                  borderRadius: "5px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  backgroundColor: "#ffd700",
                },
              }}
            >
              {selectedCustomerData ? (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      border: "1px solid black",
                      boxShadow: "none",
                      borderRadius: "5px",
                    }}
                  >
                    <h2
                      style={{
                        fontWeight: "bold",
                        borderStyle: "none",
                        boxShadow: "none",
                        justifyContent: "center",
                      }}
                    >
                      Unmatched Details
                    </h2>
                    <Item
                      style={{
                        fontWeight: "bold",
                        borderStyle: "none",
                        boxShadow: "none",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {selectedCustomerData?.name?.result === "Not Matched" ? (
                        <ClientDetailBlock
                          title={"Name"}
                          fields={selectedCustomerData?.name}
                        ></ClientDetailBlock>
                      ) : (
                        <></>
                      )}
                      {selectedCustomerData?.gender?.result ===
                        "Not Matched" ? (
                        <ClientDetailBlock
                          title={"Gender"}
                          fields={selectedCustomerData?.gender}
                        ></ClientDetailBlock>
                      ) : (
                        <></>
                      )}
                      {selectedCustomerData?.dob?.result === "Not Matched" ? (
                        <ClientDetailBlock
                          title={"Date of Birth"}
                          fields={selectedCustomerData?.dob}
                        ></ClientDetailBlock>
                      ) : (
                        <></>
                      )}
                      {selectedCustomerData?.email?.result === "Not Matched" ? (
                        <ClientDetailBlock
                          title={"Email"}
                          fields={selectedCustomerData?.email}
                        ></ClientDetailBlock>
                      ) : (
                        <></>
                      )}
                      {selectedCustomerData?.phone?.result === "Not Matched" ? (
                        <ClientDetailBlock
                          title={"Mobile"}
                          fields={selectedCustomerData?.phone}
                        ></ClientDetailBlock>
                      ) : (
                        <></>
                      )}
                      {selectedCustomerData?.address?.result ===
                        "Not Matched" ? (
                        <ClientDetailBlock
                          title={"Address"}
                          fields={selectedCustomerData?.address}
                        ></ClientDetailBlock>
                      ) : (
                        <></>
                      )}
                    </Item>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      border: "1px solid black",
                      boxShadow: "none",
                      borderRadius: "5px",
                      marginTop: "5px",
                    }}
                  >
                    <h2
                      style={{
                        fontWeight: "bold",
                        borderStyle: "none",
                        boxShadow: "none",
                        justifyContent: "center",
                      }}
                    >
                      Matched Details
                    </h2>
                    <Item
                      style={{
                        fontWeight: "bold",
                        borderStyle: "none",
                        boxShadow: "none",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {selectedCustomerData?.name?.result === "Matched" ? (
                        <ClientDetailBlock
                          title={"Name"}
                          fields={selectedCustomerData?.name}
                        ></ClientDetailBlock>
                      ) : (
                        <></>
                      )}
                      {selectedCustomerData?.gender?.result === "Matched" ? (
                        <ClientDetailBlock
                          title={"Gender"}
                          fields={selectedCustomerData?.gender}
                        ></ClientDetailBlock>
                      ) : (
                        <></>
                      )}
                      {selectedCustomerData?.dob?.result === "Matched" ? (
                        <ClientDetailBlock
                          title={"Date of Birth"}
                          fields={selectedCustomerData?.dob}
                        ></ClientDetailBlock>
                      ) : (
                        <></>
                      )}
                      {selectedCustomerData?.email?.result === "Matched" ? (
                        <ClientDetailBlock
                          title={"Email"}
                          fields={selectedCustomerData?.email}
                        ></ClientDetailBlock>
                      ) : (
                        <></>
                      )}
                      {selectedCustomerData?.address?.result === "Matched" ? (
                        <ClientDetailBlock
                          title={"Address"}
                          fields={selectedCustomerData?.address}
                        ></ClientDetailBlock>
                      ) : (
                        <></>
                      )}
                      {selectedCustomerData?.phone?.result === "Matched" ? (
                        <ClientDetailBlock
                          title={"Mobile"}
                          fields={selectedCustomerData?.phone}
                        ></ClientDetailBlock>
                      ) : (
                        <></>
                      )}
                    </Item>
                  </Grid>
                </>
              ) : (
                <h3>{userSelectionMsg}</h3>
              )}
            </Grid>
          </Box>
        </Item>
      </Grid>
    </Grid>
  );
  // if (localStorage.getItem("bearerToken") || source == "clientOperations") {

  // } else {
  //   logout();
  // }
}

const ClientDetailBlock = (props) => {
  const { fields } = props;
  return (
    <Grid item xs={12} mt={1}>
      <Item
        style={{
          borderStyle: "none",
          boxShadow: "none",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <Grid item xs={1}></Grid>
        <Grid
          item
          xs={3}
          sx={{
            border: "0px solid black",
            padding: "10px",
          }}
        >
          <p style={{ textAlign: "left", margin: 0 }}>{props.title}</p>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            border: "0px solid black",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              border: "0px solid black",
              padding: "10px",
              fontWeight: "normal",
            }}
          >
            <p style={{ textAlign: "left", margin: 0 }}>
              <b>Century:</b> {props.fields?.century}
            </p>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              border: "0px solid black",
              padding: "10px",
              fontWeight: "normal",
            }}
          >
            <p style={{ textAlign: "left", margin: 0 }}>
              {" "}
              <b>Bank:</b> {props.fields?.bank}
            </p>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          sx={{
            border: "0px solid black",
            padding: "10px",
            fontWeight: "normal",
          }}
        >
          Result:{" "}
          <span
            style={{
              color: props.fields?.result == "Matched" ? "green" : "red",
            }}
          >
            {props.fields?.result}
          </span>
        </Grid>
        <Grid item xs={1}></Grid>
      </Item>
    </Grid>
  );
};
