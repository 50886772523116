import React, { useState } from "react";
import { Heatmap } from '@ant-design/plots';
import { CircularProgress, Box } from "@mui/material";

const HeatMapComponent = (props) => {
    const { data, colorField = "count", isLoading = false } = props;

    /*const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let txnData = data
    let txnCounterHM = {}
    function returnTime(hours) {
        if (hours == 0) {
            return "Midnight"
        }
        else if (hours < 12) {
            return hours + "am";
        }
        else if (hours == 12) {
            return "Midday"
        }
        else return (hours - 12) + "pm";
    }
    txnData.map((obj) => {
        obj.weekday = weekday[(new Date(obj.time)).getDay()]
        obj.hour = returnTime((new Date(obj.time)).getHours());
        if (obj.weekday in txnCounterHM) {
            if (obj.hour in txnCounterHM[obj.weekday]) {
                txnCounterHM[obj.weekday][obj.hour] += 1
            }
            else {
                txnCounterHM[obj.weekday][obj.hour] = 1
            }
        }
        else {
            txnCounterHM[obj.weekday] = {}
            txnCounterHM[obj.weekday][obj.hour] = 1
        }
    })
    let txnDataHMArray = []
    Object.keys(txnCounterHM).forEach(weekday => {
        Object.keys(txnCounterHM[weekday]).forEach(hour => {
            let temp = {}
            temp["weekday"] = weekday
            temp["hour"] = hour
            temp["count"] = txnCounterHM[weekday][hour]
            txnDataHMArray.push(temp)
        })
    })

    console.log("Heatmap array",JSON.stringify(txnDataHMArray));

    */

    const config = {
        data: data,
        onReady: (plot) => {
            plot.on('element:click', (...args) => {
                console.log(args);
            })
        },
        xField: 'hour',
        yField: 'weekday',
        colorField: colorField,
        legend: true,
        // color: '#BAE7FF-#1890FF-#1028ff',
        // color:'#8674a7-#451968-#2d094b',
        color: '#ededed-#333333-#111111',
        coordinate: {
            type: 'polar',
            cfg: {
                innerRadius: 0.2,
            },
        },
        HeatmapStyle: {
            stroke: '#f5f5f5',
            opacity: 0.8,
        },

        xAxis: {
            line: null,
            grid: null,
            tickLine: null,
            label: {
                offset: 12,
                style: {
                    fill: '#666',
                    fontSize: 12,
                    textBaseline: 'top',
                },
            },
        },
        yAxis: {
            top: true,
            line: null,
            grid: null,
            tickLine: null,
            label: {
                offset: 0,
                style: {
                    fill: '#0066a1',
                    textAlign: 'center',
                    shadowBlur: 0,
                    shadowColor: 'rgba(0, 0, 0, .45)',
                },
            },
        },
       
        
  tooltip: {
  
    title:(value)=>value,

customItems: (originalItems) => {
// process originalItems,

let val = null;

if(originalItems[0].value<1000){
  val = originalItems[0].value
}else if(originalItems[0].value>=1000 && originalItems[0].value<1000000){
  val = Math.round(originalItems[0].value / 1000).toLocaleString() + "K";
}else if(originalItems[0].value>=1000000){
  val = Math.round(originalItems[0].value / 1000000).toLocaleString() + "M";

}

originalItems[0].value = val;


return originalItems;
}
},


        interactions: [
            {
                type: 'element-active',
            },
        ],
    };

    return isLoading ? <Box sx={{ display: 'flex', height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress style={{ color: 'gold' }} disableShrink />
    </Box> : <Heatmap {...config} />;
}

export default HeatMapComponent;


